import React, {useMemo, useState, useEffect} from 'react'
import {Calendar, momentLocalizer,} from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {collectAppointmentData, eventStyleGetter, getNewDefaultDate, makeComboAppointments} from './scheduleUtils';
import PropTypes from 'prop-types'
import moment from 'moment-mini';
import {slots_shape} from "../../../model/dataModels";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import {getCalendarConfig, getMaxMin} from "../preferences/fullcalendar/fCalUtils";
import {resultsRenderer} from "../preferences/fullcalendar/EventRendering";
import "./Immutables.css"
import {toTimeString} from "../preferences/models/translateUtils";


const BigCalendar = Calendar;


// pass in events or slots but not both
function  ImmutableSchedule({slots, events, minTime, maxTime, renderer}) {
    //duration,slot_id,slot_rules,start_time
    const types = new Set();
    // let maxTime = getNewDefaultDate();
    // let minTime = getNewDefaultDate();
    // minTime.setHours(0, 0, 0, 0);
    // maxTime.setHours(23, 0, 0, 0);
    let sMinTime = toTimeString(minTime);
    let sMaxTime = toTimeString(maxTime);
    let lminTime = new Date(minTime);
    let lmaxTime = new Date(maxTime);

    console.log("ImmutableSchedule: events:", events);
    if (events === undefined) {
        const apptMap = collectAppointmentData(slots, lminTime, lmaxTime, types);
        events = makeComboAppointments(apptMap, types);
    }
    // const handleToolTip = (event) => {
    //     return event.toolTip
    // };
    //
    // const getTitle = (event) => {
    //     return event.title;
    // };

    const fullCalendar = React.createRef();
    // const [_events, setEvents] = useState(events);
    // const config = getCalendarConfig(events, true);
    // minTime.setHours(0, 0, 0, 0);
    // maxTime.setHours(23, 0, 0, 0);
    //
    //let maxMin = getMaxMin(minTime, maxTime);
    //
    // let scrollTime = getNewDefaultDate();
    // scrollTime.setHours(8, 0, 0, 0);
    // scrollTime = scrollTime.toTimeString();


    useEffect(() => {
        if (fullCalendar.hasOwnProperty("renderEvents")){
            let sMinTime = toTimeString(minTime);
            let sMaxTime = toTimeString(maxTime);
            // let lminTime = minTime;
            // let lmaxTime = maxTime;
            // const apptMap = collectAppointmentData(slots, lminTime, lmaxTime, types);
            // const events = makeComboAppointments(apptMap, types);
            // fullCalendar.events = events;
            fullCalendar.minTime = sMinTime;
            fullCalendar.maxTime = sMaxTime;
            fullCalendar.rerenderEvents();
        }
    }, [minTime, maxTime]);

    return (
        <div className={'immutable-schedule'}>
            <FullCalendar
                key={`eod-selector-calendar-results`}
                ref={fullCalendar}
                defaultDate={getNewDefaultDate()}
                scrollTime={sMinTime}
                slotEventOverlap={false}
                slotDuration={'00:05:00'}
                columnHeader={null}
                allDayText={''}
                minTime={sMinTime}
                maxTime={sMaxTime}
                label={null}
                defaultView="timeGridDay"
                header={null}
                title={null}
                allday={false}
                plugins={[timeGridPlugin]}
                events={events}
                editable={false}
                eventRender={renderer}
            />
        </div>

    )

}

export default ImmutableSchedule;

ImmutableSchedule.propTypes = {
    slots: PropTypes.arrayOf(slots_shape).isRequired
};
