import "../styles.compliance.css"

class PatientComplianceRecord {

    rawItem;

    constructor(rawItem, currentClinic) {
        this.rawItem = rawItem;
        this.currentClinic = currentClinic;
    }

    get id() {
        return `${this.currentClinic.currentClinic}_${this.rawItem.patient}`
    }

    get patient() {
        return this.rawItem.patient;
    }

    get appointmentTime() {
        return this.rawItem.appointment_time;
    }

    get beacon() {
        return this.rawItem.beaconCode;
    }

    get appointments() {
        return this.rawItem.appointments;
    }

    get events() {
        return this.rawItem.events;
    }

    get areasVisited() {
        return this.rawItem.events ? this.rawItem.events.length : 0;
    }

    get status() {
        return this.areasVisited;
    }

}

export function buildPatientComplianceRecords(rawPatientData, currentClinic) {
    const records = [];
    Object.keys(rawPatientData).forEach(patientId => {
        const patientEntries = rawPatientData[patientId];
        Object.keys(patientEntries).forEach(beaconCode => {
            const entries = patientEntries[beaconCode];
            const beacon_id = entries[0].beacon_id;
            const appt_time = entries[0].appointment_time;
            records.push({
                patient: patientId,
                appointment_time: appt_time,
                beaconCode: beaconCode,
                beaconId: beacon_id,
                rawPatientData: patientEntries,
                events: entries.filter(entry => entry['interaction']).map(entry => {
                    return {
                        event: entry.start,
                        area: entry['interaction'],
                        appointment: entry['appt_id']
                    }
                })


            })
        })
    });

    return records.map(r => new PatientComplianceRecord(r, currentClinic));
}



