import {Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import LoadingOverlay from 'react-loading-overlay';
import MenuItem from "@material-ui/core/MenuItem";
import './mobile.css';
import {clinicAdminDataService} from "../../service/DataService";
import {useAreas} from "../../utils/Hooks";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {formatUnixTime} from "../../utils/Utils";
import moment from "moment";
import AppBar from "@material-ui/core/AppBar";
import {ahTheme} from "../../styles/AhMuiTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider/ThemeProvider";
import {ClinicPicker} from "ah-auth0";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    bottomAppBar: {
        top: 'auto',
        bottom: 0,
        backgroundColor: '#224668'
    },
    topAppBar: {
        backgroundColor: '#224668'
    },
    root: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'hidden'
    },
    locationTypo: {
        margin: 10
    },
    locationTypoArea: {
        margin: 10,
        marginTop: '20%'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        textAlign: 'center'
    },
}));

function getLastRecordTime(currentBeaconRecord) {
    return currentBeaconRecord.last_record_time ? currentBeaconRecord.last_record_time : currentBeaconRecord.last_event_time;
}

function MobileBadgeLocate({currentClinic}) {
    const [badges, setBadges] = useState(null),
        [currentBeaconMac, setCurrentBeaconMac] = useState(null),
        [currentLocation, setCurrentLocation] = useState(null),
        [showLocation, setShowLocation] = useState(false),
        [loading, setLoading] = useState(true),
        [areasLoaded, areas] = useAreas(currentClinic, clinicAdminDataService),
        classes = useStyles();

    const getData = () => {
        if (currentClinic) {
            clinicAdminDataService.get(`clinicadmin/clinics/${currentClinic}/beacons/stats`).then(response => {
                const badges = response.data['compliance_data']['rows'].reduce((prev, curr) => {
                    if (curr.area_id) {
                        prev[curr.beacon_mac] = curr;
                    }
                    return prev;
                }, {});
                if (Object.keys(badges).length > 0) {
                    setBadges(badges);
                } else {
                    setBadges(null);
                }
                setLoading(false);
            });
        }
    };

    useEffect(() => {
        getData();
    }, [currentClinic]);

    const selectBeacon = (event) => {
        if (event.target.value !== 'none') {
            setCurrentBeaconMac(event.target.value);
        }
    };

    useEffect(() => {
        if (currentBeaconMac) {
            setCurrentLocation(areas[badges[currentBeaconMac].area_id]);
        }
    }, [currentBeaconMac, areas, badges]);

    useEffect(() => {
        if (currentClinic) {
            setLoading(true);
            setCurrentBeaconMac(null);
            setShowLocation(false);
            const interval = setInterval(() => {
                getData();
            }, 15000);
            return () => {
                clearInterval(interval);
            };
        }
    }, [currentClinic]);


    return <ThemeProvider theme={ahTheme}>
        <div className={classes.root}>
            <AppBar position="static" className={classes.topAppBar}>
                <Typography variant="h6" className={classes.title}>
                    Badge Locator
                </Typography>
            </AppBar>
            <LoadingOverlay spinner
                            text='Loading...'
                            active={loading}>
                <div style={{
                    margin: 'auto',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100vh'
                }}>
                    {showLocation ?
                        <div>
                            <Typography variant={"h5"}
                                        className={classes.locationTypo}>Badge {currentBeaconMac}</Typography>
                            <Typography variant={'h3'}
                                        className={classes.locationTypoArea}>{currentLocation.area_display_name ? currentLocation.area_display_name : currentLocation.area_name}</Typography>
                            <Typography className={classes.locationTypo}>Last
                                Updated: {formatUnixTime(getLastRecordTime(badges[currentBeaconMac]))}</Typography>
                            <Button variant={'contained'} style={{width: '250px', marginTop: '50px'}}
                                    onClick={() => setShowLocation(false)}>Select Another
                                Badge</Button>
                        </div>
                        :
                        <FormControl className={classes.formControl}>
                            <InputLabel id="badge-select-label">Select Badge</InputLabel>
                            <Select
                                style={{width: '200px'}}
                                labelId="badge-select-label"
                                id="badge-select"
                                value={currentBeaconMac ? currentBeaconMac : ''}
                                onChange={selectBeacon}
                            >
                                {badges ? Object.values(badges).map(b => {
                                    return <MenuItem
                                        value={b.beacon_mac}>{b.beacon_mac} ({moment.unix(getLastRecordTime(b)).format('MM-DD HH:mm')})</MenuItem>
                                }) : <MenuItem value={'none'}>No Badges Present</MenuItem>}
                            </Select>
                            {currentBeaconMac &&
                            <Button variant={'contained'} style={{width: '200px', marginTop: '50px'}}
                                    onClick={() => setShowLocation(true)}>Show
                                Location</Button>}
                        </FormControl>}
                </div>
                <AppBar position="fixed" className={classes.bottomAppBar}>
                    <ClinicPicker/>
                </AppBar>
            </LoadingOverlay>
        </div>
    </ThemeProvider>;
}

export default MobileBadgeLocate;
