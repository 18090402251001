import React, {useEffect, useState} from 'react';
import {clinicAdminDataService} from "../../../service/DataService";
import {getBody} from "../../../utils/Utils";
import {Typography} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import {globals} from "../../../utils/SharedResources";
import {buildComplianceRecords, customSearch, getCustomSort} from "./BeaconsRecord";
import {getColumns} from "./BadgeAssignmentCols";

function EquipmentBadgeAssignment({currentClinic,}) {

    const [rows, setRows] = useState(null),
        [unassigned, setUnassigned] = useState([]),
        [suggestionData, setSuggestionData] = useState(null),
        [sortStatus, setSortStatus] = useState(null),
        [assignmentDisabled, setAssignmentDisabled] = useState(false);

    useEffect(() => {
        clinicAdminDataService.get(`clinicadmin/clinics/${currentClinic.currentClinic}/beacons/equipment`).then(response => {
            const rawData = getBody(response);
            const map = {};
            rawData.forEach(item => {
                const str = `${item.name}, ${item.equipment_type}`;
                map[str] = item;
            });
            map['UNASSIGNED'] = {
                name: null,
                type: null,
                equipment_id: null
            };
            const s = Object.keys(map).sort();
            setSuggestionData({
                "data": map,
                "display_values" : s,
                "disabled" : []
            });
        });
    }, [currentClinic.currentClinic]);

    const handleResponse = (response) => {
        const bod = getBody(response);

        if (response && response.error && response.error.toLowerCase().contains('duplicate')) {
            globals.setError("This badge is already assigned. Changes will not be applied");
        }
        if (bod) {
            setUnassigned(bod.unassigned);
            const r = bod['compliance_data']['rows'];
            const rws = buildComplianceRecords(r, currentClinic, bod.unassigned, null, 'equipment');
            const key = sortStatus ? sortStatus.key : 'beaconCode';
            const order = sortStatus ? sortStatus.order : 'asc';
            rws.sort((a, b) => {
                a = a.getCompareValue(key);
                b = b.getCompareValue(key);
                if (a === b) return 0;
                return order === 'desc' ? a > b ? -1 : 1 : a < b ? -1 : 1;
            });
            setRows(rws)
        } else {
            setRows(null)
        }
    };

    const handleBeaconChangeRequest = async (dataObj) => {
        setAssignmentDisabled(true);
        const url = `clinicadmin/clinics/${currentClinic.currentClinic}/equipment/${dataObj.equipment_id}/beacons/${dataObj.beacon_id}`;
        const response = await clinicAdminDataService.put(url, dataObj);
        const body = getBody(response);
        if (body && body.hasOwnProperty('error') && body.error !== null) {
            setAssignmentDisabled(false);
            globals.setError(body.error);
            return null;
        } else if (body) {
            setAssignmentDisabled(false);
            handleResponse(response);
            return response;
        }
    };

    const [columns, setColumns] = useState(null);
    useEffect(() => {
        if (!suggestionData){
            return
        }
        setColumns(getColumns([], handleBeaconChangeRequest, suggestionData, unassigned, currentClinic, assignmentDisabled));
    }, [suggestionData, unassigned, currentClinic.currentClinic, assignmentDisabled]);

    const getData = () => {
        if (currentClinic) {
            const cc = currentClinic.currentClinic;
            const beaconUrl = `clinicadmin/clinics/${cc}/beacons/stats`;

            clinicAdminDataService.get(beaconUrl).then(response => {
                handleResponse(response);
            });
        }
    };

    useEffect(() => {
        getData();
    }, [currentClinic]);


    if (!currentClinic || !currentClinic.currentClinic) {
        return <Typography variant={'body1'}>Please select a clinic</Typography>
    }

    return <div style={{display: 'flex', flexDirection: 'column', overflowX: 'hidden'}}>

        <div style={{width: '100%', paddingRight: '10px', left: '20px'}}>
            <Typography style={{paddingLeft: '20px'}} variant={'h2'} color={'primary'}>Badge Assignments</Typography>
            {columns ?
                <MUIDataTable
                    style={{width: '100%'}}
                    data={rows ? rows : []}
                    selectable={false}
                    columns={columns}
                    expandableRows={true}
                    options={{
                        textLabels: {
                            body: {
                                noMatch: rows ? "No badges present" : "Loading..."
                            }
                        },
                        selectableRows: 'none',
                        print: false,
                        sort: true,
                        search: {top: 0, left: 0},
                        customSort: getCustomSort(setSortStatus, columns),
                        customSearch: customSearch(columns),
                        download: false,
                        checkboxInline: false,
                        displayRowCheckbox: false,
                    }}
                /> : <div>Loading...</div>}
        </div>
    </div>
}

export default EquipmentBadgeAssignment;
