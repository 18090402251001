import PropTypes from 'prop-types'
import {v4 as uuidv4} from 'uuid';
import React from "react";
import TemplateRecord from "../../models/TemplateRecord";


export const ADDITIONAL_SLOT_TYPES = {
    900: {key: 'break', label: 'Unscheduled', style: 'break-event'},
    901: {key: 'some', label: 'Who knows', style: 'break-event'},
    902: {key: 'desiredLastSlot', label: 'Desired Departure', style: ['desired-event']},
    903: {key: 'desiredFirstSlot', label: 'Desired Arrival', style: ['desired-event']},
    904: {key: 'averageLastInteraction', label: 'Average Last Interaction', style: ['marker-event']},
    905: {key: 'averageDepartureTime', label: 'Average Departure', style: ['marker-event']},
    906: {key: 'averageArrivalTime', label: 'Average Arrival', style: ['marker-event']},
    907: {key: 'averageFirstInteraction', label: 'Average First Interaction', style: ['marker-event']},
    break: 900,
    whoKnows: 901,
    desiredLastSlot: 902,
    desiredFirstSlot: 903,
    averageLastInteraction: 904,
    averageDepartureTime: 905,
    averageArrivalTime: 906,
    averageFirstInteraction: 907
};


export class Slot {
    capacity = 1;
    duration = 15;
    meta_data = [];
    overbook_capacity = 0;
    slot_id = uuidv4();
    slot_type_id;
    start_time;
    locked = false;
    private = false;

    constructor(data) {
        Object.keys(data).forEach(key => {
            this[key] = data[key];
        });
        this.meta_data = data.meta_data ? JSON.parse(JSON.stringify(data.meta_data)) : []

        if (data && data.slot_type && data.slot_type.slot_type_id && !data.slot_type_id) {
            this.slot_type_id = data.slot_type.slot_type_id
        }
    }
}

export const getSlotTypeFromAllowedVisitTypes = (allowedVisitTypes, allSlotTypes) => {
    console.log("getSlotTypeFromAllowedVisitTypes(): allowedVisitTypes", allowedVisitTypes);
    console.log("getSlotTypeFromAllowedVisitTypes(): allSlotTypes", allSlotTypes);
    let allowedVisitTypesString =  allowedVisitTypes.replace('[','').replace(']','').replace(/\'/g,'').replace(' ','').split(',').sort().join(",");

    for (let i=0; i< allSlotTypes.length; i++) {
        let slotType = allSlotTypes[i];
        if ((slotType !== null) && (slotType !== undefined)) {
            let slotTypeString = slotType.allowed_visit_types.sort().join(',');
            console.log("getSlotTypeFromAllowedVisitTypes(): allowedVisitTypesString", allowedVisitTypesString);
            console.log("getSlotTypeFromAllowedVisitTypes(): slotTypeString", slotTypeString);
            if (allowedVisitTypesString === slotTypeString) {
                console.log("getSlotTypeFromAllowedVisitTypes(): returning", slotType.slot_type_id);
                return slotType.slot_type_id;
            }
        }
    }
    console.log("getSlotTypeFromAllowedVisitTypes(): returning default=2");
    return 2; // no idea what went wrong.  just return any;
};

export class ExtendedProps {
    original;
    revised;
    title;

    constructor(original, revised, slotTypes) {
        this.original = original;
        this.slotTypes = slotTypes ? slotTypes : TemplateRecord.slotTypes;
        this.revised = revised ? revised : new Slot(original);
        if (!this.revised instanceof Slot) {
            this.revised = new Slot(this.revised);
        }
    }

    getTitle = (evt) => {
        const slot = this.revised;
        if (slot.slot_type_id >= 900) {
            return ADDITIONAL_SLOT_TYPES[slot.slot_type_id].label
        }

        const datestr = evt.start.toLocaleTimeString().replace(":00", "");
        this.title = `${datestr} ${this.getSlotTypeName(slot.slot_type_id)}, ${slot.duration}m`;
        return this.title
    };

    getShortTitle = (evt) => {
        const slot = this.revised;
        if (slot.slot_type_id >= 900) {
            return ADDITIONAL_SLOT_TYPES[slot.slot_type_id].label
        }

        //const datestr = evt.start.toLocaleTimeString().replace(":00", "");
        //this.title = `${datestr} ${this.getSlotTypeName(slot.slot_type_id)}, ${slot.duration}m`;
        this.short_title = this.getSlotTypeName(slot.slot_type_id);
        return this.short_title
    };

    getSlotTypeName = (slotId) => {
        let label = "unknown";
        if (!this.slotTypes){
            this.slotTypes = TemplateRecord.slotTypes;
        }

        if (slotId >= 900) {
            label = ADDITIONAL_SLOT_TYPES[slotId].label;
        } else {
            label = this.slotTypes[slotId].slot_type_name;
        }

        return label
    };

    getClassNames = () => {
        const slot = this.revised;
        const ast = ADDITIONAL_SLOT_TYPES;
        let classNames;
        slot.slot_type_id = parseInt(slot.slot_type_id);

        if (slot.slot_type_id >= 900) {
            const value = ast[slot.slot_type_id].style;
            classNames = Array.isArray(value) ? value : (typeof value === 'string') ? value.split(' ') : value;
        } else {
            classNames = slot.slot_type_id === ast.break ? ['break'] : slot.edited ? ['slot', 'edited'] : ['slot'];
        }
        return classNames;

    }
}

ExtendedProps.propTypes = {
    original: PropTypes.instanceOf(Slot),
    revised: PropTypes.instanceOf(Slot)
};

export const makeNewSlot = (start_time, duration = 15, capacity = 1,
                            meta_data = [], overbook_capacity = 0, slot_id = uuidv4(), slot_type_id = -1,
                            locked = false) => {
    return new Slot({
        start_time: start_time,
        duration: duration,
        capacity: capacity,
        meta_data: meta_data,
        overbook_capacity: overbook_capacity,
        slot_id: slot_id,
        slot_type_id: slot_type_id,
        locked: locked
    });

};
