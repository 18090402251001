import Typography from "@material-ui/core/Typography";
import FileUpload from "../../components/FileUpload";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React, {useState} from "react";

export function UploadComponent({
                                    containerStyle = null,
                                    dataService, uploadRequiredHeaderList, uploadOptionalHeaderList, itemName, auth, uploadComplete, includeWarning = true,
                                    additionalUploadNotes, url
                                }) {
    const [uploadError, setUploadError] = useState(null);
    return <div style={containerStyle ? containerStyle : {
        display: 'flex',
        paddingLeft: '25%',
        paddingRight: '25%',
        flexDirection: 'column'
    }}>
        <div style={{
            display: 'flex',
            paddingLeft: '20px',
            paddingRight: '20px',
            flexDirection: 'column',
            border: '2px solid darkgray',
            borderRadius: '25px'
        }}>
            <Typography component={'h3'} style={{margin: 'auto', width: '75%'}}>
                Use this tool to upload and modify {itemName}. Upload a CSV consisting of data with these exact headers:
                <ul>
                    {uploadRequiredHeaderList.map(uh => <li>{uh}</li>)}
                </ul>
                {uploadOptionalHeaderList && uploadOptionalHeaderList.length > 0 &&
                <div>The following headers can also be included but are optional:
                    <ul>
                        {uploadOptionalHeaderList.map(uh => <li>{uh}</li>)}
                    </ul>
                </div>
                }
                {additionalUploadNotes}
            </Typography>
            <FileUpload
                authHeaders={auth.getHeaders()}
                raiseError={setUploadError}
                url={dataService.baseUrl + url}
                onResponseReceived={uploadComplete}/>
        </div>
        <UploadErrorDialog error={uploadError} onClose={() => setUploadError(null)} show={uploadError !== null}/>
    </div>;
}

export function UploadErrorDialog({show, onClose, error}) {
    const getErrorContent = () => {
        if (error.hasOwnProperty('missing_fields')) {
            return <div>
                There was an error during the upload. The following required fields were missing from the file:
                <ul>
                    {error['missing_fields'].map(mi => {
                        return <li>{mi}</li>;
                    })}
                </ul>
            </div>
        } else {
            return <div>
                <p>There was an error during the upload.</p>
                {error.unrecognized_values ? <p>These fields and values were not recognized:
                    {Object.keys(error['unrecognized_values']).map(k => {
                        return <ul>
                            <DialogContentText variant={'h6'}>{k}</DialogContentText>
                            <ul>
                                {error['unrecognized_values'][k].map(mi => {
                                    return <li>{mi === null ? 'No Value' : mi}</li>;
                                })}
                            </ul>
                        </ul>;
                    })}
                </p> : null}
                {error.duplicate_values ? <p>These fields had the following disallowed duplicate values:
                    {Object.keys(error['duplicate_values']).map(k => {
                        return <ul>
                            <DialogContentText variant={'h6'}>{k}</DialogContentText>
                            <ul>
                                {error['duplicate_values'][k].map(mi => {
                                    return <li>{mi}</li>;
                                })}
                            </ul>
                        </ul>;
                    })}
                </p> : null}
                {error.invalid_values ? <p>These fields had the following invalid values:
                    {Object.keys(error['invalid_values']).map(k => {
                        return <ul>
                            <DialogContentText variant={'h6'}>{k}</DialogContentText>
                            <ul>
                                {error['invalid_values'][k].map(mi => {
                                    return <li>{mi}</li>;
                                })}
                            </ul>
                        </ul>;
                    })}
                </p> : null}
            </div>
        }
    };
    return <Dialog
        open={show}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">Error During Upload</DialogTitle>
        <DialogContent>
            {error && <DialogContentText>
                {getErrorContent()}
            </DialogContentText>}
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary" autoFocus>
                Dismiss
            </Button>
        </DialogActions>
    </Dialog>
}
