/* eslint-disable */
import React, {useEffect, useMemo, useState} from 'react';
import {Typography} from "@material-ui/core";
import {clinicAdminDataService} from "../../../../service/DataService";
import DatePicker from "react-datepicker";
import {useStaff} from "../../../../utils/Hooks";
import Checkbox from "@material-ui/core/Checkbox";
import {getBody, hasPermission} from "../../../../utils/Utils";
import {ROLES} from "ah-auth0";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import "../../styles.compliance.css";
import SearchBar from "../../../../components/SearchBar";
import {UploadComponent} from "../../../clinic/Upload";
import FileUpload from "../../../../components/FileUpload";

function StaffSchedulingView({currentClinic, currentUser, auth}) {

    const [teamView, setTeamView] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [showDropZone, setShowDropZone] = useState(false);
    const [staffLoaded, staff] = useStaff(currentClinic.currentClinic, clinicAdminDataService);
    const [staffList, setStaffList] = useState([]);
    const [scheduledStaff, setScheduledStaff] = useState(new Set([]));


    const incrementCurrentDate = (forward) => {

        const date = currentDate;
        const increment = forward ? 1 : -1;
        date.setDate(date.getDate() + increment);
        setCurrentDate(new Date(date.getTime()))

    };

    //todo this is not quite working alphabetically. fix
    const sortByScheduled = (list) => {
        if (!list) list = staffList;
        if (list && list.length > 1) {
            list.sort((a, b) => {
                return b.scheduled - a.scheduled || a.fullName - b.fullName;
            });
        }
    };

    const handleOnsiteStaffChange = (entry, checked) => {
        let osStaff = new Set(Array.from(scheduledStaff));
        entry.scheduled = checked;

        if (checked) {
            osStaff.add(entry.staff_id);
        } else {
            osStaff.delete(entry.staff_id)
        }
        setScheduledStaff(osStaff);
    };

    useMemo(() => {
        if (staffList && staffList.length > 0 && scheduledStaff) {

            const views = staffList.map(entry => {
                const staffId = entry.staff_id;
                entry.scheduled = scheduledStaff.has(parseInt(staffId));
                const name = `${entry.last_name}, ${entry.first_name}`;
                entry.fullName = name;
                const id = `on_staff_${currentDate.getTime()}_list_item_${staffId}`;

                return <div key={`scheduled_div_checkbox_${id}`} style={{flexWrap: 'nowrap'}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={entry.scheduled}
                                key={`scheduled_checkbox_${id}`}
                                id={`scheduled_checkbox_${id}`}
                                onChange={(evt) => handleScheduledChange(evt, entry)}
                                value={entry}
                                inputProps={{'aria-label': name}}
                                color="primary"
                            />
                        }
                        label={name}
                    /></div>
            });
            setTeamView(views);
        }


    }, [staffList, scheduledStaff]);

    const fileUploadComplete = (response, file) => {
        if (response) {
            //setShowDropZone(false);
            getData();
        }
    };

    const onDateSelect = (date) => {
        setCurrentDate(date);
    };

    const getData = () => {
        if (staffLoaded && currentDate) {
            setTeamView(null);
            const path = `clinicadmin/clinics/${currentClinic.currentClinic}/staff/date/${currentDate.getTime()}`;
            clinicAdminDataService.get(path).then(response => {
                const body = getBody(response);
                const arr = body.map(entry => parseInt(entry.staff_id));
                const set = new Set(arr ? arr : []);
                setScheduledStaff(set);
                const sl = Object.values(staff);
                sl.forEach(entry => {
                    entry.scheduled = set.has(parseInt(entry.staff_id))
                });
                sortByScheduled(sl);
                setStaffList(sl);
            });
        }
    };

    useEffect(() => {
        getData()
    }, [staff, staffLoaded, currentDate]);


    const handleScheduledChange = (evt, curStaff) => {

        const checked = evt.target.checked;
        const path = `clinicadmin/clinics/${currentClinic.currentClinic}/staff/${curStaff.staff_id}/onsite/${currentDate.getTime()}`;

        if (checked) {
            clinicAdminDataService.put(path).then(response => {
                handleOnsiteStaffChange(curStaff, checked);

            });
        } else {
            clinicAdminDataService.delete(path).then(response => {
                if (response && response.data === 'success') {
                    handleOnsiteStaffChange(curStaff, checked);

                }
            });
        }
    };

    const updateStaffInView = (list) => {
        sortByScheduled(list);
        setStaffList(list);
    };

    return (
        <div style={{display: 'flex', flexDirection: 'column', left: '20px'}}>
            <div>
                <Typography style={{paddingLeft: '20px'}} variant={'h2'} color={'primary'}>Staff Scheduling</Typography>
            </div>
            <div style={{width: '100%', paddingBottom: '50px', paddingTop: '20px'}}>

                {currentDate &&
                <div
                    style={{
                        zIndex: 100,
                        display: 'flex',
                        flexDirection: 'row',
                        padding: 0,
                        margin: 0,
                        alignItems: 'center'
                    }}>
                    <Button style={{padding: 0, margin: 0}}
                            onClick={() => incrementCurrentDate(false)}>{'<'}</Button>
                    <DatePicker
                        selected={currentDate}
                        onSelect={onDateSelect} //when day is clicked
                        dateFormat={'LLLL dd, yyyy'}
                    />
                    <Button style={{padding: 0, margin: 0}}
                            onClick={() => incrementCurrentDate(true)}>{'>'}</Button>
                </div>
                }
            </div>

            <SearchBar
                style={{paddingLeft: '50px'}}
                entries={Object.values(staff)}
                filterField={'fullName'}
                callback={updateStaffInView}
            />

            {teamView && teamView.length > 0 &&

            <div style={{
                columns: 3,
                flexWrap: 'nowrap',
                paddingTop: '25px',
                paddingBottom: '100px',
                paddingLeft: '100px'
            }}>
                {teamView}
            </div>}
            {<Button style={{width: 200}}
                     onClick={() => setShowDropZone(!showDropZone)}>Schedule Upload</Button>}
            {showDropZone && hasPermission(currentClinic, currentUser, ROLES.CLINIC_ADMIN) &&
            <div style={{
                display: 'flex', flexDirection: 'row',
                paddingTop: 25, paddingBottom: 25,
                borderRadius: '25px', width: '90%', justifyContent: 'space-around', alignItems: 'center'
            }}>
                <div style={{display: 'flex', width: '45%', flexDirection: 'column'}}>
                    <div style={{
                        display: 'flex',
                        paddingLeft: '20px',
                        paddingRight: '20px',
                        flexDirection: 'column',
                        border: '2px solid darkgray',
                        borderRadius: '25px'
                    }}>
                        <Typography component={'h3'}
                                    style={{margin: 'auto', width: '75%', marginTop: 100, marginBottom: 100}}>
                            Upload unstructured scheduling data for later processing.
                        </Typography>
                        <FileUpload
                            authHeaders={auth.getHeaders()}
                            url={clinicAdminDataService.baseUrl + `clinicadmin/clinics/${currentClinic.currentClinic}/staff/schedules?unstructured=true`}
                            onResponseReceived={fileUploadComplete}/>
                    </div>
                </div>
                <Typography component={'h1'}>
                    OR
                </Typography>
                <UploadComponent url={`clinicadmin/clinics/${currentClinic.currentClinic}/staff/schedules`}
                                 uploadRequiredHeaderList={['STAFF', 'DATES']}
                                 itemName={'staff schedules'}
                                 auth={auth}
                                 containerStyle={{display: 'flex', width: '45%', flexDirection: 'column'}}
                                 dataService={clinicAdminDataService}
                                 additionalUploadNotes={"Staff should be in the format 'last name,first name'. Dates can be a single date or a comma separated list of dates formatted as YYYY-MM-DD."}
                                 uploadComplete={fileUploadComplete}
                                 includeWarning={false}/>
            </div>}
        </div>
    )
}

export default StaffSchedulingView;
