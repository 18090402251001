import 'react-app-polyfill/ie11';
import 'es6-shim';
import 'core-js/es6/array';
import 'core-js/es6/object';
import 'core-js/es6/string';
import 'core-js/es7/array';
import 'core-js/es7/object';
import 'core-js/es7/string';
import 'filepond-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import {history} from "./utils/SharedResources"
import {AuthRouting} from "ah-auth0";
import Home from "./Home";
import MobileBadgeLocate from "./views/mobile/MobileBadgeLocate";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
  
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.01,
  });

window.env = {MINIMUM_DATE: '2017-01-01'};
if (process.env.REACT_APP_IS_DEMO) {
    window.env = {...window.env, AC_IS_DEMO: true, HARD_CODED_TODAY: '2019-06-12', EXCLUDE_ROOMS_VISITED: true, HARD_CODED_START : '2019-04-01', HARD_CODED_END : '2019-04-15'};
}
if (process.env.REACT_APP_CUSTOMER === 'bmc') {
    window.env = {
        EXCLUDE_ROOMS_VISITED: true,
        HIDE_DATE_RANGE_PICKER: true,
        AC_IS_DEMO: false,
        SHOW_MONTHLY_DATA: true,
        EXCLUDE_ERROR_BARS: true,
        HIDE_BMC_VIEWS: true,
        MINIMUM_DATE: '2020-01-07'
    };
}
if (process.env.REACT_APP_CUSTOMER.toLowerCase() === 'duke') {
    window.env = {
        CUSTOMER: 'DUKE'
    };
}
const routes = [{path: '/badgeLocate', component: MobileBadgeLocate}, {path: '/', component: Home}];

ReactDOM.render(<AuthRouting history={history} routes={routes} env={process.env}
                             defaultComponent={Home}/>, document.getElementById('root'));

