import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React, {useEffect, useState} from "react";
import {Fade} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Area from "./AreaModel";
import Tooltip from "@material-ui/core/Tooltip";
import LoadingOverlay from 'react-loading-overlay';
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade direction="down" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1)
        },
    },
    chip: {
        margin: 4,
    },
    externalIdHolder: {
        border: '1px solid rgba(224, 224, 224, 1)',
        margin: 5,
        maxHeight: 80,
        overflowY: 'scroll'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    externalIdLabel: {
        color: 'rgba(0, 0, 0, 0.54)',
        padding: 0,
        fontSize: '.75rem',
        fontWeight: 400,
        lineHeight: 1,
        marginLeft: 7
    }
}));

const isComplete = (area) => {
    return area.areaName && (area.areaTypeId !== undefined && area.areaTypeId !== null);
};


export default function AreaEditor({saving, isNew, open, onCancel, onSubmit, onDelete, inputArea, inputAreaTypes}) {
    const [area, setArea] = useState(inputArea),
        [submitDisabled, setSubmitDisabled] = useState(!isComplete(area)),
        classes = useStyles();

    const getOnChange = field => event => {
        event.persist();
        setArea(area => {
            if (event.target.id === 'deactivatedCheckbox' || event.target.id === 'hiddenCheckbox') {
                area[field] = event.target.checked;
            }
            else {
                area[field] = event.target.value;
            }
            return new Area(area.toJson());
        });
    };

    useEffect(() => {
        setSubmitDisabled(!isComplete(area));
    }, [area]);

    return <Dialog fullWidth={true}
                   open={open}
                   maxWidth={'sm'}
                   TransitionComponent={Transition}
                   onClose={onCancel}
                   style={{zIndex: 1150}}
    ><LoadingOverlay
        active={saving}
        spinner
        text={'Saving...'}>
        <DialogTitle>{area.areaDisplayName === '' && isNew ? 'New Space' : area.areaDisplayName || area.areaName}</DialogTitle>
        <DialogContent>
            <form className={classes.root} noValidate autoComplete="off">
                <div style={{display: 'flex', flexDirection: 'column'}}>

                    <TextField id="area-id" label="ID"
                               disabled
                               value={isNew ? 'New Space' : area.areaId}/>
                    <TextField id="area-name" label="Name" onChange={getOnChange('areaName')}
                               required
                               value={area.areaName ? area.areaName : ''}/>
                    <TextField id="area-display-name" label="Display Name"
                               onChange={getOnChange('areaDisplayName')}
                               helperText={'This value will be shown as the area name in all other views.'}
                               value={area.areaDisplayName ? area.areaDisplayName : ''}/>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="area-type-label">Space Type</InputLabel>
                        <Select
                            labelId="area-type-label"
                            id="area-type"
                            required
                            value={area.areaTypeId}
                            onChange={getOnChange('areaTypeId')}
                        >
                            {inputAreaTypes.map(at => {
                                return <MenuItem value={at.area_type_id}>{at.area_type_display_name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    {area.externalName && area.externalName.length > 0 &&
                    <React.Fragment><Typography className={classes.externalIdLabel}>Mapped Zebra Zones</Typography>
                        <div className={classes.externalIdHolder}>
                            {area.externalName && area.externalName.map((name) => {
                                return <Chip key={name} label={name}
                                             className={classes.chip}/>
                            })}
                        </div>
                    </React.Fragment>}
                    <FormControlLabel
                        control={ <Checkbox checked={area.deactivated} onChange={ getOnChange('deactivated')} id="deactivatedCheckbox" name="deactivatedToggle" />}
                        label="Deactivated"
                    />
                    <FormControlLabel
                        control={ <Checkbox checked={area.hidden} onChange={ getOnChange('hidden')} id="hiddenCheckbox" name="hiddenToggle" />}
                        label="Hidden"
                    />
                </div>
            </form>

        </DialogContent>
        <DialogActions>
            <Tooltip title={submitDisabled ? 'Please enter area name and area type' : null}>
                <span>
                    <Button onClick={() => {
                        onSubmit(area)
                    }} disabled={submitDisabled} color="secondary">Submit</Button>
                </span>
            </Tooltip>
            <Button onClick={onCancel} color="primary">Cancel</Button>
            <Button onClick={() => {
                onDelete(area)
            }} style={{color: "#F86041"}} startIcon={<DeleteIcon/>}>Delete</Button>
        </DialogActions>
    </LoadingOverlay>
    </Dialog>;

}
