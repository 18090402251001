import React, {Component} from 'react';
import {ahTheme} from "./styles/AhMuiTheme";
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import {AppBar} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import logo from './assets/img/ah_icon.png'
import Grid from '@material-ui/core/Grid';
import {history} from './utils/SharedResources';
import Button from "@material-ui/core/Button";
import Slide from "@material-ui/core/Slide";
import './assets/css/simple.css';
import ViewFinder from "./config/ViewFinder";
import ErrorBar from "./components/ErrorBar";
import {ClinicPicker} from "ah-auth0";
import {withRouter} from "react-router";
import getTabLayout, {MenuItem} from "./config/Layout";
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import axios from 'axios';
import Tooltip from '@material-ui/core/Tooltip';

import appInfo from '../package.json';

const emailHelpBody = 'Dear Apprentice Health Support!%0A%0A\n' +
    'I am experiencing an issue with the Apprentice Health Application.%0A%0A' +
    'Application Component (RTLS, Analytics, Admin, etc):' +
    '%0A%0A'+
    'Description of Issue: %0A%0A' +
    'Time of the Issue:%0A%0A'+
    'Severity (Critical, High, Low):%0A%0A'+
    '-----%0A%0A' +
    'Be mindful to never share PHI/PII data!%0A%0A';

const emailHelpSubject = 'Apprentice Health Application Help';


function buildMenu(menu) {
    let menus = [];
    Object.keys(menu.children).forEach(k => {
        const subMenu = menu.children[k];
        if (subMenu.children) {
            menus.push(new MenuItem(subMenu.label, k, buildMenu(subMenu)));
        } else {
            menus.push(new MenuItem(subMenu.label, k));
        }
    });
    return menus;
}

function getMenusMap(user, currentClinic) {
    let tabLayout = getTabLayout(user, currentClinic);
    return buildMenu(tabLayout);
}

function findMenu(menus, key) {
    let m = menus.find(mi => mi.value === key);
    return m === undefined ? menus[0] : m;
}

const getStateFromProps = (props) => {
    let menus = getMenusMap(props.user, props.currentClinic);
    let currentMenu = menus[0];
    let currentView = currentMenu.value;

    let currentSubMenu = menus[0].hasSubMenu() ? menus[0].menuItems[0] : null;
    if (currentSubMenu) {
        currentView = currentSubMenu.value;
    }

    let currentBottomMenu = null;
    let paths = props.location.pathname.split('/');
    if (paths.length > 1) {
        currentMenu = findMenu(menus, paths[1]);
    }
    if (paths.length > 2) {
        currentSubMenu = findMenu(currentMenu.menuItems, paths[2]);
        currentView = currentSubMenu.value;
    }
    if (paths.length > 3) {
        currentBottomMenu = findMenu(currentSubMenu.menuItems, paths[3]);
        currentView = '&' + currentMenu.value + '&' + currentBottomMenu.value;
    }
    return {
        currentView: currentView,
        pathname: props.location.pathname,
        currentClinic: props.currentClinic,
        menu: menus,
        currentMenu: currentMenu,
        currentSubMenu: currentSubMenu,
        currentBottomMenu: currentBottomMenu,
        showHelpDialog:false
    };
};

class Home extends Component {
    subMenuTimeout;
    menuTimeout;
    enterMenuState;
    enterMenuTimeout;

    constructor(props) {
        super(props);
        this.state = getStateFromProps(props)
    }

    componentDidMount = () => {
        const retrieveVersion = setInterval( async () => {
            const result = await axios.get('/version.txt?timestamp=' + (new Date().getTime()))
            const data = result.data
    
            if (!this.state.version){
                this.setState({version: data})
            }else if (data != this.state.version){
                window.location.reload();
            }
    
        }, 30000);

        this.setState({retrieveVersion: retrieveVersion})
    }

    componentWillUnmount = () => {
        clearInterval(this.state.retrieveVersion)
    }

    /**
     * Required to handle an update to the currentClinic and user props, which this component (easily) have direct access to
     * as it is a HOC and not a functional component
     */
    static getDerivedStateFromProps(props, state) {
        if (props.currentClinic !== state.currentClinic || props.location.pathname !== state.pathname) {
            return getStateFromProps(props);
        }
        return null;
    }

    getCurrentView = (viewName, currentClinic) => {
        return ViewFinder.getView(viewName, currentClinic, this.props.auth, this.props.user);
    };

    clearTimeouts = () => {
        [this.subMenuTimeout, this.enterMenuTimeout, this.menuTimeout].map(clearTimeout);
    };

    menuEnter = () => {
        this.clearTimeouts();
        if (!this.enterMenuState) {
            //Save current state
            this.enterMenuState = (({currentMenu, currentSubMenu, currentBottomMenu}) => ({
                currentMenu, currentSubMenu, currentBottomMenu
            }))(this.state);
        }
    };

    menuLeave = () => {
        this.enterMenuTimeout = setTimeout(() => this.setState({
            ...this.enterMenuState,
            menuHeld: false,
            subMenuHeld: false
        }, () => this.enterMenuState = null), 450);
    };

    handleClickHelp = () => {
        this.setState ({showHelpDialog:true});
    };

    handleCloseHelp = () => {
        this.setState ({showHelpDialog:false});
    };

    render() {
        const email = process.env.REACT_APP_SUPPORT_EMAIL;
        return (
            <ThemeProvider theme={ahTheme}>
                <Dialog open={this.state.showHelpDialog} maxWidth={"md"} fullWidth={false} onClose={this.handleCloseHelp}>
                    <DialogTitle id="form-dialog-title" style={{textAlign: "center"}}>
                        Submit a Support Ticket
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" style={{display: 'block', fontSize: 14, marginTop: "15px"}}>
                            Click the button below to submit an email ticket to Apprentice Health.
                            ({email}). We will reach out to assist.
                        </DialogContentText>
                        <DialogActions style={{justifyContent:'center'}} >
                            <a href={"mailto:" + email + "?&subject="+emailHelpSubject+"&body="+emailHelpBody}>
                                <Button style={{color: 'white',marginTop: 5, zIndex: 1000}} variant="contained"
                                        color="primary" aria-label="email">Submit Ticket</Button> </a>
                        </DialogActions>
                    </DialogContent>

                </Dialog>

                <div style={{marginRight: -8, width: '100%', zIndex: 1000}}
                     onMouseLeave={this.menuLeave}
                     onMouseEnter={this.menuEnter}>
                    <AppBar component={'nav'} style={{display: 'list-item', backgroundColor: '#224668' , zIndex: 1000}}>
                        <Grid container component={'div'}
                              style={{position: 'fixed', height: '10px', width: '100%', backgroundColor: '#000000'}}/>
                        <Grid container justify='space-between' component={'div'} style={{
                            maxHeight: 40, flexWrap: 'nowrap', position: 'fixed', top: '10px', width: '100%'
                        }}>
                            <Grid item component={'div'}>
                                <Tooltip title={"Clinic Admin : " + appInfo.version + "|Workflow: " + appInfo.dependencies["workflow-core"] +  "|Map View: " + appInfo.dependencies["map-view"]}>
                                <img src={logo} alt={'Apprentice Health'}
                                     style={{
                                         height: '2em',
                                         width: '2em',
                                         paddingTop: '3px',
                                         paddingBottom: '3px',
                                         float: 'left',
                                         verticalAlign: 'middle'
                                     }}/>
                                </Tooltip>
                                <Tabs value={this.state.currentMenu.value}
                                      component={'div'}>
                                    {this.state.menu.map(mi => {
                                        return <Tab className={'top-menu-tabs'} key={mi.value} value={mi.value}
                                                    onMouseEnter={() => {
                                                        if (!this.state.menuHeld) {
                                                            this.clearTimeouts();
                                                            this.menuTimeout = setTimeout(() => {
                                                                this.setState({
                                                                    currentMenu: mi,
                                                                    currentSubMenu: (mi.menuItems ? mi.menuItems[0] : null),
                                                                    showSubMenu: true
                                                                })
                                                            }, 450);
                                                        }
                                                    }}
                                                    onClick={() => {
                                                        this.setState({
                                                            currentMenu: mi,
                                                            menuHeld: true,
                                                            subMenuHeld: false,
                                                            currentSubMenu: (mi.menuItems ? mi.menuItems[0] : null)
                                                        });
                                                    }}
                                                    label={mi.label}/>;
                                    })}
                                </Tabs>
                            </Grid>
                            <Grid container item component={'div'} style={{height: 40, alignItems: 'center'}}>
                                <ClinicPicker/>

                                {/* <Button onClick={() => window.location.replace('/documentation/docs-home.html')} className={'assist-buttons'}>Documentation</Button> */}
                                <Button className={'assist-buttons'} aria-label="Logout"  onClick={this.handleClickHelp}>Help</Button>
                                <Button onClick={() => history.push('/logout')} className={'assist-buttons'}>Logout</Button>

                            </Grid>
                        </Grid>
                    </AppBar>
                    <Slide in={this.state.currentMenu.hasSubMenu()} timeout={0} direction={'down'}
                           className='sub-menu'>
                        <div className='sub-menu-container' style={{display: 'flex', flexDirection: 'row', position: 'fixed', zIndex: 1000}}>
                            <div className='menu-container-spacer'/>
                            {this.state.currentMenu.hasSubMenu() &&
                                <Tabs className={"ah_sub_nav"} value={this.state.currentSubMenu.value}
                                  component={'nav'}>
                                {this.state.currentMenu.menuItems.map(mi => {
                                    return <Tab key={mi.value} value={mi.value}
                                                onMouseEnter={() => {
                                                    this.clearTimeouts();
                                                    if (!this.state.subMenuHeld) {
                                                        this.subMenuTimeout = setTimeout(() => {
                                                            const newState = {currentSubMenu: mi};
                                                            if (mi.hasSubMenu()) {
                                                                newState['currentBottomMenu'] = mi.menuItems[0];
                                                            }
                                                            this.setState(newState);
                                                        }, 450);
                                                    }
                                                }}
                                                onClick={() => {
                                                    if (!mi.hasSubMenu() && this.enterMenuState) {
                                                        this.enterMenuState.currentSubMenu = mi;
                                                        this.enterMenuState.currentMenu = mi.parent;
                                                        this.setState({currentView: mi.value});
                                                        history.push(mi.getPath());
                                                    } else {
                                                        this.setState({
                                                            currentSubMenu: mi,
                                                            currentMenu: mi.parent,
                                                            subMenuHeld: true,
                                                            menuHeld: true
                                                        });
                                                    }
                                                }}
                                                label={mi.label} style={{minHeight: '20px'}}/>
                                })}
                            </Tabs>}

                        </div>
                    </Slide>
                    <div className='bottom-menu-container' style={{
                        position: 'fixed',
                        display: (this.state.currentSubMenu && this.state.currentSubMenu.hasSubMenu()) ? 'flex' : 'none',
                        flexDirection: 'row',
                        zIndex: 1000
                    }}>
                        <div className='menu-container-spacer'/>


                        {(this.state.currentSubMenu && this.state.currentSubMenu.hasSubMenu()) &&
                        <Tabs className={"ah_sub_nav"}
                              value={this.state.currentBottomMenu ? this.state.currentBottomMenu.value : ''}
                              component={'nav'}>
                            {this.state.currentSubMenu.menuItems.map(mi => {
                                return <Tab key={mi.value} value={mi.value}
                                            onMouseEnter={() => {
                                                this.clearTimeouts();
                                                this.setState({currentBottomMenu: mi});
                                            }}
                                            onClick={() => {
                                                this.enterMenuState.currentBottomMenu = mi;
                                                this.enterMenuState.currentSubMenu = mi.parent;
                                                this.enterMenuState.currentMenu = mi.parent.parent;
                                                history.push(mi.getPath());
                                                this.setState({currentView: '&' + this.state.currentMenu.value + '&' + mi.value});
                                            }}
                                            label={mi.label} style={{minHeight: '20px'}}/>
                            })}
                        </Tabs>}

                    </div>
                </div>
                <Grid item
                      style={{position: 'relative', top: '140px', width: '100%'}}
                      component={'section'}>
                    {this.getCurrentView(this.state.currentView, this.state.currentClinic)}
                </Grid>

                <ErrorBar/>

            </ThemeProvider>

        );
    }
}

export default withRouter(props => <Home {...props}/>);

