import React, {useEffect, useState} from 'react';
import ReactPlayer from 'react-player'
import Typography from '@material-ui/core/Typography';

import {schedulingDataService, analyticsDataService} from "../../service/DataService";


function ComponentDisabled(props) {
    const {currentClinic, component} = props;
    const [url, setUrl] = useState(null);
    const [componentName, setComponentName] = useState(null);

    useEffect(() => {
        if (url == null){
            if (component === 'scheduling'){
                schedulingDataService.get(`scheduling/assets/clinics/${currentClinic.currentClinic}/scheduling`).then(response => {
                    setUrl(response.data)
                });
                setComponentName("Scheduling");
            }else if (component === 'analytics'){
                analyticsDataService.get(`analytics/assets/clinics/${currentClinic.currentClinic}/analytics_pro`).then(response => {
                    setUrl(response.data)
                })
                setComponentName("Analytics Pro")
            }
        }
    }, [currentClinic, component])

    return  <div style={{textAlign: "center"}}>
        <Typography variant="h2" gutterBottom>
            {componentName} will be activated after we collect the necessary data
        </Typography>
        <ReactPlayer url={url} controls={true} style={{marginLeft: "auto", marginRight: "auto"}} config={
            {    
                file: {
                    attributes: { controlsList: 'nodownload' }
                }
            }
        }/>
        </div>
}

export default ComponentDisabled;
