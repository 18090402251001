import React, {useState} from 'react';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import {Fade, FormLabel} from "@material-ui/core";
import {humanize} from "../utils/Utils";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {editTypes, rowModel} from "../model/dataModels";

export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade direction="down" ref={ref} {...props} />;
});


export const localStyle = {

    container: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        padding: '10px',
        border: 'none'
    },
    textField: {
        width: '100%',
    },
};


export default function RowEditor(props) {

    let {model, callback, additionalButtons, label} = props;

    const textState = {};
    const selectState = {};
    model.forEach(entry => {
        entry.value = typeof entry.value === "boolean" ? entry.value : !entry.value ? "" : entry.value;
        entry.newValue = entry.value;
        if (!entry.enumData) {
            textState[entry.id] = entry.newValue;
        } else {
            selectState[entry.id] = entry.newValue;
        }
    });

    const [open, setOpen] = useState(true);
    const [textValues, setTextValues] = useState(textState);
    const [selectValues, setSelectValues] = useState(selectState);
    let count = 0;

    const handleTextChange = name => event => {
        setTextValues({...textValues, [name]: event.target.value});
    };

    const handleSelectChange = name => event => {
        setSelectValues({...selectValues, [name]: event.target.value});
    };

    function getSelectComponent(entry) {
        const num = ++count;
        const id = entry.id + "_select_" + num;
        return <DialogContent key={"dialog_" + num}>
            <FormLabel htmlFor={entry.id}>{entry.label}</FormLabel>
            <Select
                key={entry.id + "_row_editor_select" + num}
                value={selectValues[entry.id].toString()}
                onChange={handleSelectChange(entry.id)}
                inputProps={{
                    id: id
                }}
                displayEmpty
            >
                {
                    entry.enumData.map((item) => {
                        return <MenuItem value={item} col={item}
                                         key={item + "_menu_item_" + num}>{humanize(item)}</MenuItem>
                    })
                }
            </Select>
        </DialogContent>;
    }

    function close() {
        additionalButtons = null;
        model = null;
        callback();
        setOpen(false);
    }

    function submit() {
        const changes = {};
        Object.keys(textValues).forEach(key => {
            const value = textValues[key];
            if (value || value === "") {
                changes[key] = textValues[key];
            }

        });
        Object.keys(selectValues).forEach(key => {
            const value = selectValues[key];
            if (value || value === "") {
                changes[key] = selectValues[key];
            }
        });
        callback(changes, model);
        close();
    }

    function getAdditionalButtons() {
        return additionalButtons ? additionalButtons.map(item => {
            return item;
        }) : null;
    }

    function getEditableTextField(entry) {

        return <TextField
            key={entry.id}
            id={entry.id + "_add_field" + (++count)}
            label={entry.label}
            style={localStyle.textField}
            value={textValues[entry.id]}
            onChange={handleTextChange(entry.id)}
            margin="normal"
        />

    }

    const view = model.map(entry => {
        if (!entry.readonly) {
            if (entry.enumData) {
                return getSelectComponent(entry);
            } else {
                const type = entry.dataType;
                if (type.startsWith("char") || type.startsWith("text")) {
                    return getEditableTextField(entry);
                } else {
                    //todo check for numbers
                    return getEditableTextField(entry)
                }
            }
        }
        return null;
    });


    return (<Dialog fullWidth={true}
                    open={open}
                    maxWidth={'sm'}
                    TransitionComponent={Transition}
                    onClose={close}
                    style={{zIndex: 0}}
    >
        <DialogTitle>{label}</DialogTitle>
        <DialogContent>
            {view}

        </DialogContent>
        <DialogActions>
            <Button onClick={() => submit(model)} color="secondary">Submit</Button>
            <Button onClick={() => close()} color="primary">Cancel</Button>
            {getAdditionalButtons()}
        </DialogActions>
    </Dialog>);
}

RowEditor.propTypes = {

    type: editTypes,
    model: rowModel,
    label: PropTypes.string,
    callback: PropTypes.func.isRequired,
    additionalButtons: PropTypes.arrayOf(
        PropTypes.element
    )
};
