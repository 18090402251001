import React, {useEffect, useMemo, useState} from 'react';
import {clinicAdminDataService} from "../../../service/DataService";
import {humanize} from "../../../utils/Utils";
import {ListItem, ListItemText, Typography} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import {useStaff} from "../../../utils/Hooks";
import List from "@material-ui/core/List";


function StaffListByDateView({currentClinic, scheduled}) {

    const [onSiteStaff, setOnSiteStaff] = useState(null);
    const [enter, setEnter] = useState(false);
    const [staffLoaded, staff] = useStaff(currentClinic.currentClinic, clinicAdminDataService);


    useEffect(() => {

        if (staff && Object.keys(staff).length > 0) {

            if (scheduled && scheduled.length > 0) {
                const list = scheduled.map(onSite => {
                    const data = staff[onSite.staff_id];
                    return <ListItem style={{color: 'rgba(78,82,101,0.84)', height: '25px'}}
                                     key={`${currentClinic.currentClinic}_staff_list_by_date${onSite.staff_id}`}>
                        <ListItemText primary={`${humanize(data.last_name)}, ${humanize(data.first_name)}`}/>
                    </ListItem>
                });
                setOnSiteStaff(list)
            } else {
                setOnSiteStaff([])
            }
        }
    }, [staff, scheduled]);

    useMemo(() => {
        if (onSiteStaff && onSiteStaff.length > 0) {
            setEnter(true);
        }
    }, [onSiteStaff]);


    return (
        <div style={{height: '100%'}}>
            <Slide in={enter} direction={'left'}>

                <List style={{display: 'flex', flexDirection: 'column', dense: true}}>
                    <Typography variant={'subtitle1'} color={'primary'}>{`Scheduled for this date`}</Typography>
                    {onSiteStaff}
                </List>
            </Slide>
        </div>
    )
}

export default StaffListByDateView;
