import BeaconSelector from "./BeaconSelector";
import React from "react";

const batteryColors = ['#abdc9c', '#dcd679', '#dc3120'];

export const defaultBadgeAssignmentFilterList = [[], [], [], [false]];

export const getColumns = (filterList, handleBeaconChangeRequest, suggestionData, unassigned, currentClinic, assignmentDisabled) => {

    return [

        {
            label: 'Badge Code',
            name: 'beaconCode',
            key: 'beaconCode', //using this for custom sort
            options: {
                filterList: filterList[0],
            }
        },
        {
            label: 'MAC Address',
            name: 'beaconMac',
            key: 'beaconMac',
            options: {
                filterList: filterList[1],
                customBodyRender: (value) => {
                    return value ? value.toUpperCase() : '';
                }
            }
        },
        {
            label: 'Assignment',
            name: 'curItem',
            key: 'assignmentString',
            options: {
                filter: false,
                filterList: filterList[2],
                customBodyRender: (beaconRecord) => {
                    const id = `badge_selector_${beaconRecord.key}`;
                    return <BeaconSelector key={id} callback={handleBeaconChangeRequest} currentClinic={currentClinic}
                                           id={id}
                                           assignmentDisabled={assignmentDisabled}
                                           suggestionData={suggestionData}
                                           currentAssignment={beaconRecord.assignmentString}
                                           beaconRecord={beaconRecord}
                                           unassigned={unassigned}/>
                }
            }

        },
        {
            name: 'deactivated',
            label: 'Deactivated',
            key: 'deactivated',
            options: {
                display: 'false',
                filterList: filterList[3],
                filter: true,
                customFilterListOptions: {
                    render: v => v ? 'Deactivated: true' : 'Deactivated: false'
                }
            }

        }
    ]
};
