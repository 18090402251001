import React, {Fragment, useEffect, useState} from "react";
import MUIDataTable from "mui-datatables";
import {clinicAdminDataService} from "../../service/DataService";
import Grid from "@material-ui/core/Grid";
import {convertToObj, hasPermission} from "../../utils/Utils";
import {ROLES} from "ah-auth0";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';
import {UploadComponent} from "./Upload";
import {useClinic} from "../../utils/Hooks";
import {Dialog} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import ConfirmDelete from "../../components/ConfirmDelete";
import {ADMIN_MODES} from "../../utils/GlobalProps";



function isConflictResponse(resp) {
    return resp && resp.response && resp.response.status === 409 && resp.response.data.error === 'DuplicateEntryViolation';
}

function isFKDeleteResponse(resp) {
    return resp && resp.response && resp.response.status === 409 && resp.response.data.error === 'ForeignKeyViolation';
}

function ResourceView({
                          currentClinic, itemPluralName, auth, uploadOptionalList, conflictMessageGenerator,
                          refreshTrigger, resourceCtor, getColumns, defaultFilterList, itemName, user, itemDisplayNamePlural,
                          itemDisplayName, Editor, additionalUploadNotes, uploadHeaderList, additionalButtons,
                          setFilterChipProps,mode=ADMIN_MODES.CLINIC,canUpload=true
                      }) {
    const
        [rows, setRows] = useState(null),
        [editDialogOpen, setEditDialogOpen] = useState(false),
        [conflictMessage, setConflictMessage] = useState(null),
        [hasAdminPermission, setHasAdminPermission] = useState(false),
        [saving, setSaving] = useState(false),
        [pluralDisplayName, setPluralDisplayName] = useState(null),
        [newResourceOpen, setNewResourceOpen] = useState(false),
        [currentRowIdx, setCurrentRowIdx] = useState(null),
        [showDropZone, setShowDropZone] = useState(false),
        [deleteDialogOpen, setDeleteDialogOpen] = useState(false),
        [clinicLoaded, clinicObj] =  useClinic(currentClinic, clinicAdminDataService),
        [columns, setColumns] = useState(getColumns(defaultFilterList)),
        [stateFilterList, setStateFilterList] = useState(defaultFilterList);

    itemPluralName = itemPluralName || itemName + 's';


    let baseApiUrl=`clinicadmin/clinics/${currentClinic}/${itemPluralName}`
    if (mode == ADMIN_MODES.REALM) {
        baseApiUrl =`clinicadmin/realms/${clinicObj.realm_id}/${itemPluralName}`
    }

    let getData = () => {
        setEditDialogOpen(false);
        setRows(null);

        if (clinicLoaded) {

            clinicAdminDataService.get(baseApiUrl).then(response => {
                if (response && response.data) {

                    const objs = convertToObj(response.data.columns, response.data.rows);

                    //fixme this is a temporary hotfix to hide 'hidden' areas.
                    // In realms update, this will be dealt with by not even including these areas in the site
                    const filtered_hidden_objs = objs.filter(o=>!o.is_hidden)

                    setRows(filtered_hidden_objs.map(d => resourceCtor(d)))
                }
            })}
};

    useEffect(() => {
        setPluralDisplayName(itemDisplayNamePlural ? itemDisplayNamePlural : (itemPluralName[0].toUpperCase() + itemPluralName.substring(1)));
    }, [itemDisplayNamePlural, itemPluralName]);

    useEffect(
        getData,
        [clinicLoaded, refreshTrigger, currentClinic]);

    let onRowClick = (rowData, tableMeta) => {
        setCurrentRowIdx(tableMeta.dataIndex);
        setEditDialogOpen(true);
    };

    useEffect(() => {
        setColumns(getColumns(stateFilterList));
    }, [stateFilterList]);

    const handleResponse = (resp, openCallback) => {
        if (isConflictResponse(resp)) {
            setConflictMessage(conflictMessageGenerator(resp.response.data.data));
        }
        else if (isFKDeleteResponse(resp)) {
            setConflictMessage(resp.response.data.msg);
        }
        else {
            openCallback(false);
            getData();
        }
        setSaving(false);
    };

    useEffect(() => {
        setHasAdminPermission(hasPermission(currentClinic, user, ROLES.CLINIC_ADMIN));
    }, [currentClinic, user]);

    let onUpdateRow = (updatedResource) => {
        if (updatedResource) {

            setSaving(true);

            clinicAdminDataService.patch(`${baseApiUrl}/${updatedResource.id}`, updatedResource.toJson())
                .then((resp) => {
                handleResponse(resp, setEditDialogOpen);
            })
        }
    };

    const onCreateRow = (newResource) => {
        if (newResource) {
            setSaving(true);

            clinicAdminDataService.post(baseApiUrl, newResource.toJson())
                .then((resp) => {
                    handleResponse(resp, setNewResourceOpen);
                })
        }
    };

    const onDeleteRow = (resource) => {
        if (resource) {
            setSaving(true);
            clinicAdminDataService.delete(`${baseApiUrl}/${resource.id}`)
                .then((resp) => {
                    handleResponse(resp, setNewResourceOpen);
                })
        }
    };

    const fileUploadComplete = (response) => {
        if (response) {
            setShowDropZone(false);
            getData();
        }
    };

    const onCancelEdit = () => {
        setEditDialogOpen(false);
        setNewResourceOpen(false);
    };

    useEffect(() => {
    }, [deleteDialogOpen]);
    return <Fragment>
        <Grid component={"div"}>
            {editDialogOpen &&
            <Editor saving={saving} open={editDialogOpen} inputResource={rows[currentRowIdx]}
                    currentClinic={currentClinic}
                    onCancel={onCancelEdit} onSubmit={onUpdateRow} onDelete={() => {setDeleteDialogOpen(true)}}/>}
            {editDialogOpen && deleteDialogOpen && <ConfirmDelete open={deleteDialogOpen} close={() => setDeleteDialogOpen(false)} model={rows[currentRowIdx]}
                           label={itemDisplayName} deleteCallback={() => {onDeleteRow(rows[currentRowIdx]) ; onCancelEdit()}}/>}
            {newResourceOpen &&
            <Editor saving={saving} open={newResourceOpen} inputResource={resourceCtor({})} isNew
                    currentClinic={currentClinic}
                    onCancel={onCancelEdit} onSubmit={onCreateRow}  onDelete={() => {setDeleteDialogOpen(true)}}/>}


            <Typography style={{'padding-left': '20px'}} component="h2" variant="h2" color={"primary"} gutterBottom>
                {pluralDisplayName}
            </Typography>
            {hasAdminPermission && Editor &&
            <Button disabled={!Editor} variant="contained" onClick={() => setNewResourceOpen(true)}>
                Add New {itemDisplayName ? itemDisplayName : (itemName[0].toUpperCase() + itemName.substring(1))}</Button>}
            {additionalButtons}
            {<MUIDataTable
                data={rows ? rows : []}
                columns={columns}
                options={{
                    selectableRows: 'none',
                    textLabels: {
                        body: {
                            noMatch: rows ? `No ${pluralDisplayName}` : "Loading..."
                        }
                    },
                    onRowClick: Editor? onRowClick: null ,
                    filter: true,
                    print: false,
                    search: {top: 0, left: 0},
                    rowsPerPage: 20,
                    rowsPerPageOptions: [10, 20, 50],
                    download: false,
                    checkboxInline: false,
                    displayRowCheckbox: false,
                    onFilterChange: (changedColumn, filterList) => {
                        setStateFilterList(filterList)
                    },
                    sort: true,
                    setFilterChipProps: setFilterChipProps ? setFilterChipProps:null,
                    selectedRows: {
                        text: "row(s) selected",
                        delete: "Delete",
                        deleteAria: "Delete Selected Rows",
                    },
                }}
            />}

            {canUpload && hasAdminPermission && clinicLoaded && (!clinicObj.options || !clinicObj.options.setupComplete) &&
            <Button variant="contained"
                    onClick={() => setShowDropZone(!showDropZone)}>{itemDisplayName ? itemDisplayName : (itemName[0].toUpperCase() + itemName.substring(1))} Upload</Button>}
        </Grid>
        {showDropZone &&
        <UploadComponent
            url={baseApiUrl}

                         uploadRequiredHeaderList={uploadHeaderList} uploadOptionalHeaderList={uploadOptionalList}
                         itemName={itemDisplayNamePlural ? itemDisplayNamePlural.toLowerCase() : itemPluralName}
                         auth={auth}
                         dataService={clinicAdminDataService}
                         uploadComplete={fileUploadComplete}
                         includeWarning={true}
                         additionalUploadNotes={additionalUploadNotes}/>}
        <Dialog open={conflictMessage} onClose={() => setConflictMessage(null)}>
            <DialogTitle>Conflict</DialogTitle>
            <DialogContent>
                <Typography>
                    {conflictMessage}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setConflictMessage(null)} color="primary">Dismiss</Button>
            </DialogActions>
        </Dialog>
    </Fragment>;
}

ResourceView.propTypes = {
    currentClinic: PropTypes.string.isRequired,
    auth: PropTypes.object.isRequired,
    resourceCtor: PropTypes.func.isRequired,
    getColumns:PropTypes.func.isRequired,
    itemName: PropTypes.string.isRequired,
    conflictMessageGenerator: PropTypes.func.isRequired,
    Editor: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    additionalUploadNotes: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
    uploadHeaderList: PropTypes.array.isRequired,
    additionalButtons: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

export default ResourceView;
