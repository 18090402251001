import moment from "moment";
import {ADDITIONAL_SLOT_TYPES as ast, Slot} from "../fullcalendar/model/ExtendedProps";


export const getDateForSeconds = (seconds) => {

    const value = moment('2020-01-01');
    value.add(seconds, 'seconds');
    return value.toDate()

};

export default class PreferenceRecord {

    constructor(templateId, staffId, currentClinic, startingStatus, statusStates) {
        this._templateId = templateId;
        this._staffId = staffId;
        this.clinicId = currentClinic.currentClinic;
        this.key = `${currentClinic.currentClinic}_${staffId}_${templateId}`;
        this.scorePref = null;
        this.templatePref = null;
        this.slotPref = null;
        this.bookerClass = null;
        this.desiredLastSlot = null;
        this.desiredFirstSlot = null;
        this.status = startingStatus;
        this.statusStates = statusStates;
    }

    get templateId() {
        return this._templateId
    }

    get staffId() {
        return this._staffId
    }

    applySavedPreferences = (dynamoPrefNode) => {
        if (dynamoPrefNode && dynamoPrefNode.preferences) {
            this.scorePref = dynamoPrefNode.preferences.scorePref
            this.templatePref = dynamoPrefNode.preferences.templatePref
            this.slotPref = dynamoPrefNode.preferences.slotPref
            this.bookerClass = dynamoPrefNode.booker_class
            this.desiredFirstSlot = dynamoPrefNode.preferences.templatePref.desiredFirstSlot
            this.desiredLastSlot = dynamoPrefNode.preferences.templatePref.desiredLastSlot
        }
        console.log("dynamo node", dynamoPrefNode);
        console.log("after apply saved preferences from dynamo node", this);
    }

    applyEvents = (evts) => {
        const first = this.getSlotsByType(evts, ast.desiredFirstSlot, true);
        const last = this.getSlotsByType(evts, ast.desiredLastSlot, true);

        if (this.scorePref && this.scorePref.breaks) {
            this.scorePref.breaks = this.getBreaks(evts);
        }
        if (this.slotPref) {
            this.slotPref.fullSchedule = this.getScheduleSlots(evts);
        }
        if (this.templatePref) {
            this.templatePref.startTime = first ? first.start_time : this.desiredFirstSlot.start_time
            this.templatePref.endTime = last ? last.start_time : this.desiredLastSlot.start_time
            this.templatePref.requiredSlots = this.getRequiredSlotIDs(evts);
            this.templatePref.requiredGaps = this.getRequiredGaps(evts);
        }
        console.log(this.templatePref);
    }

    //TODO think about removing this completely and just reading from the dynamo table
    applyDefaultAverages = (avgFirstInteraction, avgLastInteraction, avgArrivalTime, avgDepartureTime) => {

        //only change the desired slots if they do not already exist

        if (!this.desiredLastSlot && avgDepartureTime) {
            const dateForSecs = getDateForSeconds(avgDepartureTime);
            const start_time = `${dateForSecs.getHours()}:${dateForSecs.getHours()}:00`;
            this.desiredLastSlot = new Slot(
                {
                    start_time: start_time,
                    slot_type_id: ast.desiredLastSlot,
                    duration: 5,
                    slot_id: `${this.templateId}-${ast[ast.desiredLastSlot].key}`

                });

        }
        if (!this.desiredFirstSlot && avgArrivalTime) {
            const dateForSecs = getDateForSeconds(avgArrivalTime);
            const start_time = `${dateForSecs.getHours()}:${dateForSecs.getHours()}:00`;
            this.desiredFirstSlot = new Slot(
                {
                    start_time: start_time,
                    slot_type_id: ast.desiredFirstSlot,
                    duration: 5,
                    slot_id: `${this.templateId}-${ast[ast.desiredFirstSlot].key}`
                })
        }
    };

    getRequiredSlotIDs = (events) => {

        return events.map(evt => evt.extendedProps.revised)
            .filter(t => (!(t.slot_type_id.toString() in ast)) && (t.locked))
            .filter(t =>(!(this.deletedIds.includes(t.slot_id))))
            .map(t=>t.slot_id);
    }


    getScheduleSlots = (events) => {
        function annotateSlotType(e) {
            let slot = e.extendedProps.revised;
            slot.slot_type = e.extendedProps.slotTypes[slot.slot_type_id]
            return slot
        }

        return events.map(evt => annotateSlotType(evt))
            .filter(t => (!(t.slot_type_id.toString() in ast)))
            .filter(t =>(!(this.deletedIds.includes(t.slot_id))));

    }
    getSlotsByType(events, typeId, onlyOne = false) {
        const r = events.map(evt => evt.extendedProps.revised).filter(t => t.slot_type_id === typeId);
        return onlyOne ? r[0] : r;
    }

    getBreaks = (events) => {
        return events.map(evt => evt.extendedProps.revised).filter(t => t.slot_type_id === ast.break);
    };


    toPostBody(events) {
        //update the preference nodes
        this.applyEvents(events);
        return {
            preferences: {
                slotPref: this.slotPref,
                templatePref: this.templatePref,
                scorePref: this.scorePref,
            },
            booker_class: this.bookerClass,
            status: this.status,
            view: {
                additionalSlotTypes: ast
            }
        }
    }

}


