import React, {Fragment, useEffect, useMemo, useState} from "react";
import MUIDataTable from "mui-datatables";
import {clinicAdminDataService, userAdminDataService} from "../../service/DataService";
import Grid from "@material-ui/core/Grid";
import {hasPermission, humanize} from "../../utils/Utils";
import {ROLES} from "ah-auth0";
import Button from "@material-ui/core/Button";
import LoadingOverlay from 'react-loading-overlay';
import Typography from "@material-ui/core/Typography";
import ClinicRolesDialog from "./ClinicRolesDialog";
import UserEditor from "./UserEditor";
import User from "./UserModel"
import Tooltip from "@material-ui/core/Tooltip";

let count = 0;

const getColumns = (showRolesView) => [
    {
        name: 'userId',
        dataType: 'integer',
        readonly: true,
        label: 'Internal User ID'
    },
    {
        name: 'email',
        dataType: 'text',
        label: 'Email',
    },
    {
        name: 'firstName',
        dataType: 'text',
        label: 'First Name'
    },
    {
        name: 'lastName',
        dataType: 'text',
        label: 'Last Name'
    },
    {
        name: 'isCustomerAdmin',
        enumData: ['true', 'false'],
        options: {customBodyRender: (value) => humanize(value === null ? "" : value.toString())},
        dataType: 'enum',
        label: 'Customer Admin'
    },
    {
        name: 'username',
        dataType: 'text',
        readonly: true,
        label: 'External User ID',
    },
    {
        name: 'clinicRolesHolder',
        dataType: 'clinicRole',
        label: 'Permissions',
        options: {
            customBodyRender: (value, tableMeta) => {
                const getButton = () => {
                    return <Button key={"view_permissions_button" + (++count)} color="primary"
                                   disabled={value.admin}
                                   onClick={(event) => {
                                       event.stopPropagation();
                                       if (!value.admin) {
                                           showRolesView(tableMeta.rowData[0]);
                                       }
                                   }}>
                        View Permissions
                    </Button>;
                };
                return (value.admin ?
                    <Tooltip
                        title={'Customer Admins have full permissions'}><span>{getButton()}</span></Tooltip> : getButton());
            }
        },
    }
];


function UsersView({currentClinic, user}) {
    const [rows, setRows] = useState(null);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [roles, setRoles] = useState(null);
    const [saving, setSaving] = useState(false);
    const [clinics, setClinics] = useState(null);
    const [newUserOpen, setNewUserOpen] = useState(false);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [currentRowIdx, setCurrentRowIdx] = useState(null);
    const [rolesViewOpen, setRolesViewOpen] = useState(false);
    let showRolesView = (userId) => {
        setCurrentUserId(userId);
        setEditDialogOpen(false);
    };
    useEffect(() => {
        if (currentUserId !== null) {
            setCurrentRowIdx(rows.findIndex(u => u.userId === currentUserId));
            setRolesViewOpen(true);
        }
    }, [currentUserId]);
    const columns = useMemo(() => getColumns(showRolesView), []);
    let getData = () => {
        setEditDialogOpen(false);
        userAdminDataService.get('useradmin/users/').then(response => {
            if (response && response.data) {
                const results = response.data.map(d => new User(d));
                setRows(results);
            }
        })
    };

    useEffect(() => {
        clinicAdminDataService.get('clinicadmin/clinics/').then(response => {
            setClinics(response.data);
        })
    }, []);

    useEffect(() => {
        userAdminDataService.get('useradmin/roles/').then(response => {
            setRoles(response.data);
        })
    }, []);

    useEffect(getData, []);
    let onRowClick = (rowData, tableMeta) => {
        setCurrentRowIdx(tableMeta.dataIndex);
        setEditDialogOpen(true);
        setRolesViewOpen(false);
    };

    let updateUser = (updatedUser) => {
        setEditDialogOpen(false);
        if (updatedUser) {
            setSaving(true);
            // noinspection JSUnusedLocalSymbols
            userAdminDataService.put(`useradmin/users/${updatedUser.userId}`, updatedUser.toJson()).then(response => {
                setSaving(false);
                if (response && response.data) {
                    setRows(rows => {
                        rows[currentRowIdx] = new User(response.data);
                        return [...rows]
                    })
                }
            })
        }
    };

    let onDeleteRow = (updatedUser) => {
        setEditDialogOpen(false);
        if (updatedUser) {
            setSaving(true);
            userAdminDataService.delete(`useradmin/users/${updatedUser.userId}`, updatedUser).then(() => {
                setSaving(false);
                setRows(rows => {
                    rows.splice(currentRowIdx, 1);
                    return [...rows]
                })
            })
        }
    };

    const updateRoles = (currentUser) => (changedRoles) => {
        setRolesViewOpen(false);
        setCurrentUserId(null);
        if (changedRoles) {
            setSaving(true);
            userAdminDataService.put(`useradmin/users/${currentUser.userId}`, {
                userId: currentUser.userId,
                clinicRoles: changedRoles
            })
                .then(response => {
                    setSaving(false);
                    if (response && response.data) {
                        setRows(rows => {
                            rows[currentRowIdx] = new User(response.data);
                            return [...rows]
                        })
                    }
                })
        }
    };

    const createUser = (newUser) => {
        setRolesViewOpen(false);
        setNewUserOpen(false);
        if (newUser) {
            setSaving(true);
            userAdminDataService.post(`useradmin/users/`, newUser.toJson())
                .then(response => {
                    setSaving(false);
                    if (response && response.data) {
                        setRows(rows => {
                            rows.push(new User(response.data));
                            return [...rows]
                        })
                    }
                })
        }
    };


    const getPermissionsView = (currentUser) => {
        return <ClinicRolesDialog callback={updateRoles(currentUser)}
                                  clinicArray={clinics}
                                  open
                                  clinicRoles={currentUser.clinicRoles}
                                  userId={currentUser.userId}
                                  roleArray={roles}/>
    };


    return <Grid component={"div"}>
        {editDialogOpen && <UserEditor open={editDialogOpen} clinics={clinics} inputUser={rows[currentRowIdx]}
                                       onCancel={() => setEditDialogOpen(false)} onSubmit={updateUser} roles={roles}/>}
        {newUserOpen && <UserEditor open={newUserOpen} clinics={clinics} inputUser={new User({})} isNew
                                    onCancel={() => setNewUserOpen(false)} onSubmit={createUser} roles={roles}/>}
        <Typography component="h2" variant="h2" color={"primary"} gutterBottom>
            Users
        </Typography>
        <LoadingOverlay spinner
                        text='Saving...'
                        active={saving}>
            <MUIDataTable
                data={rows ? rows : []}
                columns={columns}
                options={{
                    textLabels: {
                        body: {
                            noMatch: rows ? `No users present` : "Loading..."
                        }
                    },
                    selectableRows: 'none',
                    onRowClick: onRowClick,
                    print: false,
                    search: {top: 0, left: 0},
                    rowsPerPage: 20,
                    rowsPerPageOptions: [5, 10, 20, 50],
                    download: false,
                    checkboxInline: false,
                    displayRowCheckbox: false,
                    sort: true,
                    selectedRows: {
                        text: "row(s) selected",
                        delete: "Delete",
                        deleteAria: "Delete Selected Rows",
                    }
                }}
            />

            {rolesViewOpen && (currentRowIdx === 0 || currentRowIdx) && getPermissionsView(rows[currentRowIdx])}
            {hasPermission(currentClinic, user, ROLES.CLINIC_ADMIN) &&
            <Button onClick={() => setNewUserOpen(true)}>Add New User</Button>}
        </LoadingOverlay>
    </Grid>;
}

export default UsersView;
