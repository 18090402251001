import React, {useEffect, useState} from "react";
import {clinicAdminDataService} from "../../../service/DataService";
import {convertToObj, hasPermission, humanize} from "../../../utils/Utils";
import {ROLES} from "ah-auth0";
import Area from './AreaModel';
import AreaTypesDialog from "./AreaTypesDialog";
import ResourceView from "../ResourceView";
import {ADMIN_MODES} from "../../../utils/GlobalProps";
import {Checkbox} from '@material-ui/core';
import CircularProgress from "@material-ui/core/CircularProgress";


export const defaultAreaFilterList = [[], [], [], [], [], [],['No']];

function AreasAssignmentView({currentClinic, auth, user}) {
    const [areaTypes, setAreaTypes] = useState(null),
        [refreshTrigger, setRefreshTrigger] = useState(0),
        [showAreaTypesDialog, setShowAreaTypesDialog] = useState(false),
        [inProgress, setInProgress] = useState([]);

    const getColumns = (filterList) => {
        return [
            {name: 'areaId',
                options: {display:false,
                    searchable:false,
                    print:false,
                    viewColumns:false,
                    sort: false,
                    filter:false}
            },
            {
                name: 'areaName',
                dataType: 'text',
                label: 'Space Name',
                options: {
                    filterList: filterList[0],
                }
            },
            {
                name: 'areaDisplayName',
                dataType: 'text',
                label: 'Space Display Name',
                options: {
                    filterList: filterList[1],
                }
            },
            {
                name: 'areaTypeName',
                dataType: 'text',
                label: 'Space Type Name',
                options: {
                    filterList: filterList[2],
                }
            },
            {
                name: 'isClinical',
                dataType: 'enum',
                options: {
                    filterList: filterList[3],
                    customBodyRender: (value) => humanize(value === null ? "" : value.toString())
                },
                enumData: ['true', 'false'],
                label: 'Is Clinically Relevant?',
            },
            {
                name: 'assignedToSite',
                dataType: 'enum',
                enumData: [true,false],
                options: {
                    filterOptions:{
                        names: ['Yes', 'No'],
                        logic:(assignedToSite, filters) => {
                            if ((filters[0]==='Yes' && assignedToSite) || (filters[0]==='No' && !assignedToSite) ) return false ;
                            return true;
                        },

                    },
                    customFilterListOptions: {
                        render: v => v==='Yes' ? 'Assigned to this clinic' : 'Not assigned to this clinic'
                    },

                    filterList: filterList[5],
                    customBodyRender: (value, tableMeta, updateValue) => {
                            return <Checkbox checked={value} onChange={event => {
                                updateValue(event.target.checked);
                                toggleAreaAssignment(tableMeta.rowData[0],event.target.checked)}}
                            />}

                                    },
                label: 'Assigned To Clinic',
            },
            {
                name: 'deactivated',
                label: 'Deactivated',
                options: {
                    display: 'false',
                    filterList: filterList[6],
                    filter: true,
                    filterOptions:{
                        names: ['Yes', 'No']
                    },
                    customFilterListOptions: {
                        render: v => v==='Yes' ? 'Deactivated Only' : 'Active Only'
                    },
                    customBodyRender: v=>((v === undefined || v === null) ? "Yes" : v ? 'Yes' : 'No')
                }

            },
        ];
    }

    const toggleAreaAssignment = (areaId, state) => {
        console.log('inprogress',inProgress)

        let baseApiUrl =`clinicadmin/clinics/${currentClinic}/areas/${areaId}`

        if (state) {
            console.log('inprogress',inProgress)

            clinicAdminDataService.put(baseApiUrl).then(response => {
                if (response) {
                    console.log('inprogress',inProgress)

                   // setInProgress(inProgress.filter(function(e) { return e !== areaId}));
                }
            })
        } else {
            console.log('inprogress',inProgress)

            clinicAdminDataService.delete(baseApiUrl).then(response => {
                if (response) {
                  //  setInProgress(inProgress.filter(function(e) { return e !== areaId}));
                }
            })
        }

    }

    let getAreaTypes = () => {
        setAreaTypes(null);
        setRefreshTrigger(refreshTrigger => refreshTrigger + 1);
        clinicAdminDataService.get('clinicadmin/areas/types').then(response => {
            if (response.data) {
                setAreaTypes(convertToObj(response.data.columns, response.data.rows));
            }
        })
    };

    useEffect(() => {
        getAreaTypes();
    }, [currentClinic]);


    const updateAreaTypes = () => {
        setShowAreaTypesDialog(false);
        getAreaTypes();
    };

    return <div>
        <ResourceView user={user} auth={auth} refreshTrigger={refreshTrigger}
                              currentClinic={currentClinic}
                              mode={ADMIN_MODES.CLINIC}
                              getColumns={getColumns}
                              defaultFilterList={defaultAreaFilterList}
                              itemDisplayNamePlural={'Spaces'}
                              conflictMessageGenerator={() => 'A space with this name already exists, please enter a different name.'}
                              itemDisplayName={'Space'}
                              itemName={'area'}
                              resourceCtor={(...args) => new Area(...args)}
                              Editor={null}
                              canUpload={false}/>
        {hasPermission(currentClinic, user, ROLES.CUSTOMER_ADMIN) && showAreaTypesDialog &&
        <AreaTypesDialog open={showAreaTypesDialog} onSubmit={updateAreaTypes}
                         onCancel={() => setShowAreaTypesDialog(false)}/>}</div>;

}

export default AreasAssignmentView;
