import React, {useEffect, useState} from 'react';
import {clinicAdminDataService} from "../../../service/DataService";
import {getBody} from "../../../utils/Utils";
import {Typography} from "@material-ui/core";
import "../styles.compliance.css"

import "react-datepicker/dist/react-datepicker.css";
import MUIDataTable from "mui-datatables";
import {buildPatientComplianceRecords} from "./PatientComplianceRecord";
import columns from "./patientCols";
import {DatePickerStepper} from "../DatePickerStepper";
import {processComplianceData} from "../complianceUtils";

function PatientComplianceView({currentClinic}) {


    const [rows, setRows] = useState(null);
    const [currentDate, setCurrentDate] = useState(null),
        [datesLoaded, setDatesLoaded] = useState(false);
    const [resetPaging, setResetPaging] = useState(false);
    const [availableDatesData, setAvailableDatesData] = useState(null);


    const onDateSelect = (date) => {
        setCurrentDate(date);
        setResetPaging(true);
    };

    useEffect(() => {
        if (!window.env.EXCLUDE_ROOMS_VISITED) {
            if (columns.find(c => c.name === 'areasVisited') === undefined) {
                columns.splice(3, 0, {
                    label: '# of Spaces Seen',
                    name: 'areasVisited',
                    key: 'areasVisited'
                });
            }
        }
    }, []);

    useEffect(() => {
        if (datesLoaded) {
            if (currentDate && availableDatesData) {
                const curData = availableDatesData[String(currentDate.getTime())];
                if (curData) {
                    const url = `clinicadmin/clinics/${currentClinic.currentClinic}/patients/beacons/dates/${curData.dateString}`;
                    clinicAdminDataService.get(url).then(response => {
                        const dateMap = getBody(response);
                        if (dateMap) {
                            setRows(buildPatientComplianceRecords(dateMap, currentClinic));
                        } else {
                            setRows([]);
                        }
                    });
                }
            } else {
                setRows([]);
            }
        }
    }, [currentDate, availableDatesData, datesLoaded]);

    useEffect(() => {
        setResetPaging(false);
    }, [resetPaging]);


    let lastClinic = null;
    useEffect(() => {
        if (currentClinic.currentClinic === lastClinic) return;
        lastClinic = currentClinic.currentClinic;
        const url = `clinicadmin/clinics/${currentClinic.currentClinic}/patients/beacons/dates`;
        clinicAdminDataService.get(url).then(response => {
            processComplianceData(response, setAvailableDatesData, setCurrentDate);
            setDatesLoaded(true);
        });
    }, [currentClinic]);

    const customSort = (data, colIndex, order) => {
        const key = columns[colIndex].key;
        return data.sort((a, b) => {
            a = a.data[colIndex];
            b = b.data[colIndex];
            if (key === 'beacon' || key === 'patient') {
                a = parseInt(a);
                b = parseInt(b);
            }
            if (a === b) return 0;
            return order === 'desc' ? a > b ? -1 : 1 : a < b ? -1 : 1;
        });
    };


    return <div style={{display: 'flex', flexDirection: 'column', overflowX: 'hidden'}}>

        <div style={{width: '100%', paddingRight: '10px', left: '20px'}}>
            <Typography style={{paddingLeft: '20px'}} variant={'h2'} color={'primary'}>Patient Badge
                Activity</Typography>
            {availableDatesData && currentDate && <DatePickerStepper
                availableDatesData={availableDatesData}
                currentDate={currentDate}
                onDateSelect={onDateSelect}
            />}
        </div>
        {!resetPaging && <div style={{width: '100%', zIndex: 0}}><MUIDataTable
            style={{width: '100%', zIndex: 0}}
            data={rows ? rows : []}
            columns={columns}
            selectable={false}
            expandableRows={true}
            options={{
                textLabels: {
                    body: {
                        noMatch: rows && datesLoaded ? `No patient visits` : "Loading..."
                    }
                },
                selectableRows: 'none',
                print: false,
                sort: true,
                customSort: customSort,
                search: {top: 0, left: 0},
                download: false,
                checkboxInline: false,
                displayRowCheckbox: false,
            }}
        /></div>}
    </div>
}

export default PatientComplianceView;
