import React, {useEffect, useState} from 'react';
import {clinicAdminDataService,gatewayManagerDataService} from "../../../service/DataService";
import {getBody} from "../../../utils/Utils";
import {Typography} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import {BeaconsRecord, buildComplianceRecords, customSearch, getCustomSort} from "./BeaconsRecord";
import {defaultBeaconFilterList, getColumns} from "./BeaconCols";
import Button from "@material-ui/core/Button";
import {UploadComponent} from "../../clinic/Upload";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {useAreas} from "../../../utils/Hooks";
import LoadingOverlay from 'react-loading-overlay';

const additionalUploadNotes = 'Any beacons with existing beacon codes will be ignored. If any uploaded beacons have MAC ' +
    'addresses that already exist, the existing entry will be updated with the newly uploaded beacon code. Beacons with ' +
    'invalid MAC addresses will not be inserted.';

function BeaconsView({currentClinic, auth}) {
    const [rows, setRows] = useState(null);
    const [beaconData, setBeaconData] = useState(null);
    const [telemetryData, setTelemetryData] = useState(null);
    const [showDropZone, setShowDropZone] = useState(false);
    const [unassigned, setUnassigned] = useState(null);
    const [sortStatus, setSortStatus] = useState(null);
    const [inUse, setInUse] = useState(null);
    const [saving, setSaving] = useState(false);
    const [areasLoaded, areas] = useAreas(currentClinic.currentClinic, clinicAdminDataService);
    const [editableRow, setEditableRow] = useState(null);
    const [stateFilterList, setStateFilterList] = useState(defaultBeaconFilterList)

    const toggleDeactivateBeacon = (row) => {
        setEditableRow(null);
        setSaving(true);
        const beacon = rows[row];
        beacon.deactivated = !beacon.deactivated;
        clinicAdminDataService.put(`clinicadmin/clinics/${currentClinic.currentClinic}/beacons/${beacon.beaconId}`,
            beacon.toJson()).then((resp) => {
            setRows(rows => {
                rows[row] = new BeaconsRecord(resp.data, currentClinic, unassigned, areas);
                return [...rows];
            });
            setStateFilterList(defaultBeaconFilterList)
            setSaving(false);
        });
    }

    const saveChanges = (value, beacon_mac) => {
        setEditableRow(null);
        setSaving(true);

        const beacon = rows.find(r=>r.beaconMac==beacon_mac)
        const row_idx = rows.indexOf(beacon)

        beacon.beaconCode = value;
        clinicAdminDataService.put(`clinicadmin/clinics/${currentClinic.currentClinic}/beacons/${beacon.beaconId}`,
            beacon.toJson()).then((resp) => {
            setRows(rows => {
                rows[row_idx] = new BeaconsRecord(resp.data, currentClinic, unassigned, areas);
                return [...rows];
            });
            setStateFilterList(defaultBeaconFilterList)
            setSaving(false);
        });
    };
    const toggleEditable = (row, editable = true) => {
        if (editable) {
            setEditableRow(row);
        } else {
            setEditableRow(null);
        }
    };

    const [columns, setColumns] = useState(getColumns(stateFilterList, toggleEditable, () => {
    }, () => {
    }));


    useEffect(() => {
        const isRowEditable = (row) => {
            return editableRow === row;
        };
        setColumns(getColumns(stateFilterList, toggleEditable, isRowEditable, saveChanges, toggleDeactivateBeacon, new Set(rows ? rows.map(b => b.beaconCode) : [])));
    }, [editableRow, rows]);

    useEffect(() => {
        setColumns(getColumns(stateFilterList, toggleEditable, () => {
        }, saveChanges, toggleDeactivateBeacon, new Set(rows ? rows.map(b => b.beaconCode) : [])));

    }, [stateFilterList])

    const onBeaconsUploadComplete = (response) => {

        if (response && response.in_use && response.in_use.length > 0) {
            setInUse(response.in_use);
        }
        getData();
    };

    const appendTelemetry = (rows,tlm) => {
        const rowsWithTelemetry=rows.map (r => {
            let beacon = tlm?tlm.find(tlm_r => tlm_r.beacon_mac == r.beacon_mac) : null//if there are no beacons with telemetry for some reason
            if (beacon){
                r.voltage = beacon.voltage
            }
            return r
        }
        )

        return rowsWithTelemetry

    }

    const getData = () => {
        if (currentClinic && areas) {
            setRows(null);

            const beaconUrl = `clinicadmin/clinics/${currentClinic.currentClinic}/beacons/stats`;
            const beaconTLMUrl = `gatewaymanager/beacons/clinics/${ currentClinic.currentClinic}`;

            clinicAdminDataService.get(beaconUrl).then(response => {
                setBeaconData(response);

            }).then( // chanining these for now
                gatewayManagerDataService.get(beaconTLMUrl).then(response => {
                    setTelemetryData(response)
                })
            )

        }
    };

    useEffect(() => {
        getData();
    }, [currentClinic, areas]);

    useEffect(() => {
        if (beaconData && telemetryData) {
            const bod = getBody(beaconData);
            const tlmbod = getBody(telemetryData)
            if (bod) {
                const r = bod['compliance_data']['rows'];
                const rWithTelemetry= appendTelemetry(r,tlmbod)
                setUnassigned(bod.unassigned);
                const rws = buildComplianceRecords(rWithTelemetry, currentClinic, bod.unassigned, areas);
                const key = sortStatus ? sortStatus.key : 'beaconCode';
                const order = sortStatus ? sortStatus.order : 'asc';
                rws.sort((a, b) => {
                    a = a.getCompareValue(key);
                    b = b.getCompareValue(key);
                    if (a === b) return 0;
                    return order === 'desc' ? a > b ? -1 : 1 : a < b ? -1 : 1;
                });
                setRows(rws)
            } else {
                setRows([])
            }
        }

    }, [beaconData, areas]);


    if (!currentClinic || !currentClinic.currentClinic) {
        return <Typography variant={'body1'}>Please select a clinic</Typography>
    }
    return <div style={{display: 'flex', flexDirection: 'column', overflowX: 'hidden'}}>
        <div style={{width: '100%', paddingRight: '10px', left: '20px'}}>
            <Typography style={{paddingLeft: '20px'}} variant={'h2'} color={'primary'}>Badge Codes</Typography>
            <LoadingOverlay
                active={saving}
                spinner
                text={'Saving...'}>
                <MUIDataTable
                    style={{width: '100%'}}
                    data={rows ? rows : []}
                    selectable={false}
                    columns={columns}
                    expandableRows={true}
                    options={{
                        textLabels: {
                            body: {
                                noMatch: rows ? `No badges present` : "Loading..."
                            }
                        },
                        selectableRows: 'none',
                        print: false,
                        sort: true,
                        search: {top: 0, left: 0},
                        onColumnSortChange: () => {
                            setEditableRow(null)
                        },
                        customSearch: customSearch(columns),
                        download: false,
                        checkboxInline: false,
                        displayRowCheckbox: false,
                        onFilterChange: (changedColumn, filterList) => {
                            setEditableRow(null)
                            setStateFilterList(filterList)
                        },
                    }}
                />
            </LoadingOverlay>
        </div>
        <div style={{display: 'flex'}}>
            <Button variant="contained"
                    onClick={() => setShowDropZone(!showDropZone)}>Badge Upload</Button>
        </div>
        {showDropZone && !saving && <UploadComponent url={`clinicadmin/clinics/${currentClinic.currentClinic}/beacons`}
                                                     uploadRequiredHeaderList={['badge_code', 'mac_address']}
                                                     uploadOptionalHeaderList={['customer_id']}
                                                     itemName={'badges'}
                                                     auth={auth}
                                                     dataService={clinicAdminDataService}
                                                     uploadComplete={onBeaconsUploadComplete}
                                                     includeWarning={false}
                                                     additionalUploadNotes={additionalUploadNotes}/>}

        <Dialog
            open={inUse !== null}
            onClose={() => setInUse(null)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Error During Upload</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div>
                        There was an error during the upload. The following beacon codes are already in use and were
                        ignored:
                        <ul>
                            {inUse && inUse.map(mi => {
                                return <li>{mi.beacon_code}</li>;
                            })}
                        </ul>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setInUse(null)} color="primary" autoFocus>
                    Dismiss
                </Button>
            </DialogActions>
        </Dialog>
    </div>
}

export default BeaconsView;
