import React from 'react';
import moment from 'moment';
import Chart from "react-google-charts";
import {ceilTime, floorTime} from "../../../../utils/Utils";

const palette = ["#072f49", "#1b6596", "#2f97d1", "#d3dee2", "#6695af", "#5e296b", "#8f00aa", "#9bacb6"];

function createBlocks(data) {
    const columns = [
        {type: "string", id: 'Position'},
        {type: "string", id: 'Name'},
        {type: "date", id: "Start"},
        {type: "date", id: "End"},
    ];
    const rows = data.filter(event => event.start_timestamp_tz).map(event => {
        const {event_type, start_timestamp_tz, end_timestamp_tz, area_display_name, role_name} = event;

        let start_timestamp = start_timestamp_tz; // has to have this?
        let end_timestamp = end_timestamp_tz ? end_timestamp_tz : moment().format('YYYY-MM-DDTHH:mm:ss');
        let area_name = area_display_name ? area_display_name : event_type;
        let person = 'PATIENT';
        if (role_name === null && event_type !== 'area') {
            person = 'VISIT_MILESTONES';
        } else if (role_name !== null) {
            person = role_name
        }

        return formatData(person, area_name, start_timestamp, end_timestamp)
    });
    return [columns, rows]
}

const formatData = (title, type, ...args) => {
    const result = [title, type.toUpperCase()];
    if (args && args.length > 0) {
        args.forEach(item => {
            result.push(moment(item, 'YYYY-MM-DD HH:mm:ss').toDate())
        });
    }
    return result;
};

class EventTimeline extends React.Component {
    render() {
        const [columns, rows] = createBlocks(this.props.data);

        rows.forEach(t => {
        });

        if (rows.length > 0) {
            const minValue = floorTime(rows.reduce((earliest, row) => (row[2] < earliest ? row[2] : earliest), rows[0][2]), 10);
            const maxValue = ceilTime(rows.reduce((latest, row) => (row[3] > latest ? row[2] : latest), rows[0][3]), 10);

            return (
                <div style={{marginTop: 10}}>

                    <Chart
                        chartType="Timeline"
                        data={[columns, ...rows]}
                        width="90%"
                        options={{
                            'colors': palette,
                            hAxis: {
                                format: 'HH:mm a',
                                minValue: minValue,
                                maxValue: maxValue,
                            }
                        }}
                    />
                </div>

            );
        } else {
            return (
                <div>
                    <h3> No location data for this badge </h3>
                </div>
            )
        }
    }
}

export default EventTimeline;
