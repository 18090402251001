let example = [{
    "equipment_id": 0,
    "name": "chair1",
    "display_name": "Chair 1",
    "type": "Chair",
    "type_display_name": "Chair",
    "external_id": 1,
}];

export default class Equipment {
    jsonObj;

    constructor(jsonObj) {
        console.log("Creating Equipment", jsonObj)
        this.jsonObj = jsonObj;
    }

    get id() {
        return this.jsonObj.equipment_id;
    }

    get equipmentId() {
        return this.jsonObj.equipment_id;
    }

    get name() {
        return this.jsonObj.name;
    }

    set name(val) {
        this.jsonObj['name'] = val;
    }

    get displayName() {
        return this.jsonObj.display_name;
    }

    set displayName(val) {
        this.jsonObj['display_name'] = val;
    }

    get type() {
        return this.jsonObj.type;
    }

    set type(val) {
        this.jsonObj['type'] = val;
    }

    get displayType() {
        return this.jsonObj.type_display_name;
    }

    set displayType(val) {
        this.jsonObj['type_display_name'] = val;
    }

    get externalId() {
        return this.jsonObj.external_id;
    }

    set externalId(val) {
        this.jsonObj['external_id'] = val;
    }

    toJson() {
        return this.jsonObj;
    }
}
