import React, {useEffect, useState} from 'react';
import 'react-dates/lib/css/_datepicker.css';
import Spinner from 'react-spinkit';
import PropTypes from 'prop-types';
import EventTimeline from './journey/EventTimeline';
import DateFnsUtils from '@date-io/date-fns';
import 'react-dates/initialize';
import moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {analyticsDataService, clinicAdminDataService} from "../../../service/DataService";
import MUIDataTable from "mui-datatables";

const getColumns = (date) => [
    {
        name: 'area_display_name',
        label: 'Space'
    },
    {
        name: 'start_timestamp_tz',
        label: 'Entry Time',
        options: {
            customBodyRender: (value) => {
                if (moment(value).isValid()) {
                    if (moment(value).startOf('day').isBefore(moment(date).startOf('day'))) {
                        return moment(value).format('LTS') + ' (previous day)';
                    }
                    return moment(value).format('LTS');
                }
                return "---";
            }
        }
    },
    {
        name: 'end_timestamp_tz',
        label: 'Exit Time',
        options: {
            customBodyRender: (value) => {
                if (moment(value).isValid()) {
                    if (moment(value).startOf('day').isAfter(moment(date).startOf('day'))) {
                        return moment(value).format('LTS') + ' (following day)';
                    }
                    return moment(value).format('LTS');
                }
                return "---";
            }
        }
    },
];

function BadgeJourney(props) {
    const {currentClinic} = props,
        [loading, setLoading] = useState(true),
        [data, setData] = useState(null),
        [currentBeaconId, setCurrentBeaconId] = useState(null),
        [badges, setBadges] = useState(null),
        [badgesLoading, setBadgesLoading] = useState(true),
        [selectedDate, setSelectedDate] = useState(moment());

    let getBeacons = async (currentClinic) => {
        let response = await clinicAdminDataService.get(`clinicadmin/clinics/${currentClinic}/beacons/stats`);
        setBadgesLoading(true);
        if (response) {
            let result = response.data;
            if (result) {
                const badges = result['compliance_data']['rows'].reduce((prev, curr) => {
                    if (curr.area_id) {
                        prev[curr.beacon_id] = curr;
                    }
                    return prev;
                }, {});
                if (Object.keys(badges).length > 0) {
                    setBadges(badges);
                } else {
                    setBadges(null);
                }
                setBadgesLoading(false);
            }
        }
    };

    const getData = async (beaconId, date) => {
        setLoading(true);
        setData(null);
        let params = {'date': date.format('YYYY-MM-DD')};
        let response = await analyticsDataService.get(`analytics/clinics/${currentClinic}/beacons/${beaconId}/journey`, null, params);
        if (response) {
            let result = response.data;
            if (result) {
                setData(response.data.sort((a, b) => {
                    return moment(b.start_timestamp_tz).unix() - moment(a.start_timestamp_tz).unix();
                }));
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (currentBeaconId && selectedDate) {
            getData(currentBeaconId, selectedDate);
        }
    }, [currentBeaconId, selectedDate]);

    useEffect(() => {
        setCurrentBeaconId(null);
        getBeacons(currentClinic);
    }, [currentClinic]);

    const selectBeacon = (event) => {
        if (event.target.value !== 'none') {
            setCurrentBeaconId(event.target.value);
        }
    };

    const handleDateChange = (date) => {
        setSelectedDate(moment(date));
    };

    return (<div style={{margin: 10}}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Select Date"
                value={selectedDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
            />
        </MuiPickersUtilsProvider>
        <div className={'journey-main-container'}>
            <FormControl>
                <InputLabel id="badge-select-label">Select Badge</InputLabel>
                <Select
                    style={{width: '200px'}}
                    labelId="badge-select-label"
                    id="badge-select"
                    value={currentBeaconId ? currentBeaconId : ''}
                    onChange={selectBeacon}
                >
                    {badges ? Object.values(badges).map(b => {
                        return <MenuItem key={b.beacon_id}
                                         value={b.beacon_id}>{b.beacon_mac}</MenuItem>;
                    }) : <MenuItem value={'none'}>No Badges Present</MenuItem>}
                </Select>
            </FormControl>
            <div>
                {currentBeaconId && (loading ?
                        <Spinner fadeIn="full" style={{position: 'relative', left: 50, top: 50}} color="#072F49"
                                 name="ball-triangle-path"/> :
                        <div>
                            <EventTimeline data={data}/>
                            <MUIDataTable
                                data={data ? data : []}
                                columns={getColumns(selectedDate)}
                                options={{
                                    selectableRows: 'none',
                                    textLabels: {
                                        body: {
                                            noMatch: data ? `No location records` : "Loading..."
                                        }
                                    },
                                    print: false,
                                    rowsPerPageOptions: [50, 100, 200],
                                    rowsPerPage: 200,
                                    download: true,
                                    search: false,
                                    viewColumns: false,
                                    filter: false,
                                    checkboxInline: false,
                                    displayRowCheckbox: false,
                                    sort: true
                                }}
                            />
                        </div>
                )}
            </div>

        </div>
    </div>);

}

export default BadgeJourney;

BadgeJourney.propTypes = {
    currentClinic: PropTypes.number.isRequired
};
