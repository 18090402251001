import React, {Component} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import {Close} from "@material-ui/icons";
import {globals} from "../utils/SharedResources";

class ErrorBar extends Component {

    constructor(props) {
        super(props);
        this.state = globals;
        globals.registerListener(this.onGlobalsChange)
    }

    onGlobalsChange = (value) => {
        if (value === "error") {
            this.setState(globals)
        }
    };


    close = (event, reason) => {
        if (reason !== 'clickaway') {
            this.setState({error: null})
        }
    };

    render() {
        return <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            style={{width: '50%'}}
            open={this.state.error != null}
            onClose={(evt) => this.close(evt, 'duration ended')}
            autoHideDuration={5000}
            message={this.state.error}
            action={[
                <IconButton key="close" color="inherit" onClick={this.close}>
                    <Close/>
                </IconButton>,
            ]}
        />

    }

}


export default ErrorBar;



