import React, {useEffect, useMemo, useState} from 'react';

import PreferenceContentTemplate from "../PreferenceContentTemplate";
import HeatMapCalendar from "./HeatMapCalendar";
import CalendarLegend from "../fullcalendar/CalendarLegend";
import {Slot} from "../fullcalendar/model/ExtendedProps";
import TemplateRecord from "../models/TemplateRecord";
import {HeatMapEvent} from "../fullcalendar/model/HeatMapEvent";

const viewKeys = ['percentBooked', 'waitTime', 'timeToAttending'];
const styleNames = ['heat-map-booking', 'heat-map-tta-wait', 'heat-map-tta-wait'];
const tabText = ['Shown in the heat map are average percent of booked slots.', 'Shown in the heat map are average wait times in minutes.', 'Shown in the heat map are average time to attending in minutes.'];
const categoryLabels = ['Percent Booked', 'Wait Time', 'Time To Attending'];
const numTypes = ['%', 'minutes', 'minutes'];
const legends = {
    percentBooked: [20, 25, 30, 40, 50, 65, 80, 90],
    waitTime: [10, 20, 30, 40, 50, 60, 70],
    timeToAttending: [5, 15, 20, 30, 40, 50, 70]
};


function HeatMapView({currentClinic, record}) {


    const [currentView, setCurrentView] = useState(0);
    const [config, setConfig] = useState(null);
    const [eventMap, setEventMap] = useState(null);


    const refs = [];

    const getIndex = (tiers, value, inverse) => {


        let index = tiers.length - 1;
        while (value < tiers[index] && index > 0) {
            index--;
        }
        return index;


    };


    useMemo(() => {
        if (!eventMap) {
            const eventMap = {};
            viewKeys.forEach((key, idx) => {
                eventMap[`events${idx}`] = record.slots.map(rawSlot => {
                    const slot = new Slot(rawSlot);
                    const inverse = idx === 0;
                    console.log(record.slotAverages[rawSlot.slot_id])
                    const avgs = record.slotAverages[rawSlot.slot_id];

                    const key = viewKeys[idx];
                    const _tiers = legends[key];
                    const tierLevel = getIndex(_tiers, avgs[key], inverse);


                    const clazz = `${styleNames[idx]}-${(tierLevel + 1)}`;
                    const st = TemplateRecord.slotTypes;


                    return new HeatMapEvent(
                        {
                            slot: slot,
                            classNames: [clazz],
                            id: slot.slot_id,
                            revised: null,
                            label: categoryLabels[idx],
                            key: viewKeys[idx],
                            slotTypes: st,
                            clsPrefix: styleNames[idx],
                            averages: record.slotAverages[rawSlot.slot_id],

                        });

                });

            });
            setEventMap(eventMap);
        }
    }, [eventMap]);


    useEffect(() => {
        return () => {
            if (currentView >= 0) {
                if (refs[currentView] && refs[currentView].current) {
                    refs[currentView].current.focus();
                }
            }
        };
    },);


    useMemo(() => {

        if (currentView >= 0 && eventMap) {
            const key = viewKeys[currentView];

            setConfig(
                {
                    key: key,
                    currentView: currentView,
                    categoryLabels: categoryLabels[currentView],
                    tiers: legends[key],
                    styleNamePrefix: styleNames[currentView],
                    currentButton: refs[currentView],
                    events: eventMap[`events${currentView}`]
                })
        }


    }, [currentView, eventMap]);


    const onNavClick = (evt, index) => {

        setCurrentView(index);

    };

    const setFocus = (item, index) => {
        if (item) refs[index] = item;
        if (index === currentView && item) {
            item.focus();
        }

    };


    const getHeatMap = (config) => {
        const index = config.currentView;
        return <div className={'heat-map'}>
            <span className={'heat-map-description'}>{config.categoryLabels}</span>
            <HeatMapCalendar key={`heat-map-calendar-${record.key}-${index}`} events={config.events}
                             recordKey={record.key}/>
            <CalendarLegend key={`${record.key}-legend-${index}`}
                            inverse={config.key.includes('Booked')}
                            suffix={numTypes[index]} style={{paddingTop: '20px'}}
                            styleNamePrefix={config.styleNamePrefix} tiers={config.tiers}/>
        </div>
    };
    if (!config) {
        return <div/>
    }


    return <PreferenceContentTemplate
        key={`heat-map-view-pref-temp${record.key}-${currentView}`}
        title={`Heat Map View`}
        left={getHeatMap(config)}
        right={<div style={{display: 'flex', flexDirection: 'column', maxWidth: '50%'}}>
            <div style={{display: 'flex', flexDirection: 'row', width: '50%'}} id={`tabs-heat-map-${record.key}-0`}>
                {categoryLabels.map((label, index) => {
                    return <button ref={(button) => setFocus(button, index)}
                                   className={'heat-map-tab'}
                                   key={`nav-item-heat-map-${record.key}-${index}`} role={'tab'}
                                   onClick={(evt) => {
                                       onNavClick(evt, index)
                                   }}>{categoryLabels[index]}</button>
                })}
            </div>

            <div style={{padding: 20}}>
                <span className={'heat-map-description'}>{tabText[currentView]}</span>
            </div>
        </div>}
    />


}

export default HeatMapView;
