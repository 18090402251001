import React from "react";
import PreferenceRecord, {getDateForSeconds} from "./PreferenceRecord";
import {SlotEvent} from "../fullcalendar/model/SlotEvent";
import {v4 as uuidv4} from 'uuid';
import {ADDITIONAL_SLOT_TYPES as ast, Slot} from "../fullcalendar/model/ExtendedProps";


export default class TemplateRecord {
    static slotTypes;


    constructor(prefSummary, currentClinic, staffData, slotTypes, startingStatus, statusStates) {
        this.raw = prefSummary;
        this.status = prefSummary.status
        this.staffData = staffData;
        this.baselineSlots = null;
        this.currentClinic = currentClinic;
        this.statusStates = statusStates
        this.slotTypeMenu = [{value: ast.break, label: ast[ast.break].label}];
        this.slotTypes = [];
        slotTypes.forEach(entry => {
            this.slotTypes[entry.slot_type_id] = entry;
            this.slotTypeMenu.push({
                value: parseInt(entry.slot_type_id),
                label: entry.slot_type_name
            });
        });
        TemplateRecord.slotTypes = this.slotTypes ? this.slotTypes : null;


        this._key = `${this.currentClinic.currentClinic}_${prefSummary.staffId}_${prefSummary.templateId}`;
        this.events = [];
        this.preferences = new PreferenceRecord(this.raw.templateId, this.raw.staffId, currentClinic, startingStatus);
        this.ready = false;
        this.hasData = false;
        this.avgLastInteractionTime = null;
        this.avgDepartureTime = null;
        this.avgArrivalTime = null;
        this.avgFirstInteractionTime = null;
    }

    get key() {
        return this._key
    }

    get slots() {
        return this.baselineSlots.slots
    }

    get templateId() {
        return this.raw.templateId;
    }

    get templateName() {
        return this.raw.template_display_name;
    }

    get statusObj() {
        return this;
    }

    get staffId() {
        return this.raw.staffId;
    }

    get staffName() {
        return `${this.staffData.last_name}, ${this.staffData.first_name}`
    }

    get staffNameInformal() {
        return `${this.staffData.first_name} ${this.staffData.last_name}`
    }

    get record() {
        return this;
    }

    getSlotTypeName = (slotId) => {
        if (slotId >= 900) {
            return ast[slotId].label;
        }
        return this.slotTypes[slotId].slot_type_name;
    };

    updateBaselineSlots = (baselineSlots) => {
        this.baselineSlots = baselineSlots;
    }
    breakOutSlots = () => {
        let result = [];
        this.baselineSlots.forEach((slot) => {
            if (!slot.meta_data || Array.isArray(Object.keys(slot.meta_data))) {
                slot.meta = [];
            }
            if (!slot.capacity || slot.capacity <= 1) {
                result.push(slot);
            } else {
                Array(slot.capacity).fill('').map(() => {
                    const newSlot = new Slot(JSON.parse(JSON.stringify(slot)));
                    newSlot.capacity = 1;
                    newSlot.owner_id = slot.slot_id;
                    newSlot.slot_id = uuidv4();
                    newSlot.edited = true;
                    result.push(newSlot);
                    return newSlot;

                });
            }

        });
        return result
    };


    updateStoredPreferences = (body, rawProviderAverages) => {
        if (rawProviderAverages) {
            this.applyTemplateMeasuredMetrics(rawProviderAverages);
        }

        this.preferences.applySavedPreferences(body);

        let brks = this.preferences.scorePref.breaks ? this.preferences.scorePref.breaks : [];

        let slots = this.preferences.slotPref.fullSchedule ? this.preferences.slotPref.fullSchedule : [];
        //TODO requiredslots are just a list of IDs now
        // let reqSlots = this.preferences.templatePref.requiredSlots ? this.preferences.templatePref.requiredSlots : [];
        // slots.push(...reqSlots);

        //ONLY if no slots are present at all, use the baseline template slots (this should not really happen when
        //when things are populating the dynamo table
        let revsd = slots;
        if (!revsd || revsd.length === 0) {
            revsd = this.breakOutSlots();
        }

        const desStart = this.preferences.desiredFirstSlot;
        const desLast = this.preferences.desiredLastSlot;

        const evts = this.compileEvents(revsd, brks, desStart, desLast, this.avgLastInteractionTime,
            this.avgDepartureTime, this.avgArrivalTime, this.avgFirstInteractionTime);

        evts.sort(function (a, b) {
            return a.start - b.start
        });
        this.events = evts;
        this.ready = true;
    };

    compileEvents(slots, breaks, desiredFirst, desiredLast, avgLastInteraction, avgDepartureTime, avgArrivalTime,
                  avgFirstInteraction) {

        const evts = slots.map(slot => {
            return new SlotEvent(new Slot(slot), null, slot.slot_id, new Slot(slot), null, this.slotTypes);
        });
        evts.sort(function (a, b) {
            return a.start - b.start
        });
        const first = evts[0];
        const last = evts[evts.length - 1];


        evts.push(this.getPlaceholderEvent(desiredFirst, ast.desiredFirstSlot, first));
        evts.push(this.getPlaceholderEvent(desiredLast, ast.desiredLastSlot, last));
        if (avgLastInteraction) {
            evts.push(this.getPlaceholderEvent(avgLastInteraction, ast.averageLastInteraction, last));
        }
        if (avgFirstInteraction) {
            evts.push(this.getPlaceholderEvent(avgFirstInteraction, ast.averageFirstInteraction, first));
        }

        return evts;

    };

    getPlaceholderEvent = (slot, slotTypeId, alternate) => {
        if (slot instanceof Date) {
            const start = new Date(slot);
            const start_time = `${start.getHours()}:${start.getHours()}:00`;
            slot = new Slot({start_time: start_time, slot_type_id: slotTypeId});
        }
        slot = slot ? slot : alternate;
        const config = ast[slotTypeId];

        if (slot === alternate) {
            slot = new Slot({start_time: slot.extendedProps.revised.start_time});
        } else if (!slot instanceof Slot) {
            slot = new Slot(slot);
        }
        slot.slot_type_id = slotTypeId;
        slot.locked = false;
        slot.edited = false;
        slot.slot_id = `${this.templateId}-${config.key}`;
        slot.duration = 5;
        this.preferences[config.key] = slot;

        return new SlotEvent(slot, config.style, slot.slot_id, null, config.label, TemplateRecord.slotTypes);
    };


    applySlotMetrics = (averagesEntries) => {
        if (!averagesEntries) return;

        this.slotAverages = {};
        Object.keys(averagesEntries).forEach(slotId => {
            const obj = averagesEntries[slotId];
            this.slotAverages[slotId] = {
                raw: obj,
                timeToAttending: obj.avg_time_to_attending ? Math.round(obj.avg_time_to_attending) : 0,
                waitTime: obj.avg_wait_time ? Math.round(obj.avg_wait_time) : 0,
                percentBooked: Math.round((obj.avg_num_completed / obj.avg_num_openings) * 100)
            }
        });

    };


    applyTemplateMeasuredMetrics = (data) => {

        const item = data ? data[0] : null;

        if (item && Object.keys(item).length > 0) {

            this.hasData = true;
            this.avgLastInteractionTime = getDateForSeconds(item.avg_last_interaction);
            this.avgDepartureTime = getDateForSeconds(item.avg_departure_time);
            this.avgArrivalTime = getDateForSeconds(item.avg_arrival_time);
            this.avgFirstInteractionTime = getDateForSeconds(item.avg_first_interaction);
            this.preferences.applyDefaultAverages(this.avgFirstInteractionTime, this.avgLastInteractionTime, this.avgArrivalTime, this.avgDepartureTime);

        }
    }

}


export function buildTemplateRecords(prefs, slotTypes, currentClinic, staff, statusMap) {
    return prefs.map((templatePref) => {
        return new TemplateRecord(templatePref, currentClinic, staff[parseInt(templatePref.staffId)], slotTypes, templatePref.status, statusMap)
    })
}