import {Typography} from "@material-ui/core";
import {DoubleArrow} from "@material-ui/icons";
import React from "react";
import {batteryBodyRender} from "../complianceUtils";

const columns = [
    {
        label: 'Beacon',
        name: 'beacon',
        key: 'beacon',
        options: {
            customBodyRender: (label) => {
                return <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'baseline'}}>
                    <Typography>{label}</Typography>
                </div>

            }
        }
    },
    {
        label: 'Appointment Time',
        name: 'appointmentTime',
        key: 'appointmentTime'
    },
    {
        label: 'Beacon Status',
        name: 'status',
        key: 'status',
        options: {
            customBodyRender: batteryBodyRender
        }
    },
    {
        label: 'Events',
        name: 'events',
        key: 'events',
        options: {
            filter: false,
            display: 'excluded',
            customBodyRender: (events) => {
                return <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}>
                    {events.map(entry => {
                        return <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}>
                            <div><DoubleArrow color="primary"/></div>
                            <div>{entry.start}</div>
                            <div>{`(${entry.area})`}</div>

                        </div>

                    })}
                </div>
            }
        }
    },
];

export default columns;
