import React, {useContext} from "react";
import UsersView from "../views/users/UsersView";
import WorkflowMain from "../views/WorkflowMain";
import {CurrentClinicContext, UserContext} from "ah-auth0";
import {DataService as AnalyticsDataService, ViewFinder as AnalyticsCoreViewFinder} from 'analytics-core';
import BeaconsView from "../views/compliance/status/BeaconsView";
import PatientComplianceView from "../views/compliance/patients/PatientComplianceView";
import AlertsView from "../views/compliance/AlertsView";
import StaffComplianceView from "../views/compliance/staff/StaffComplianceView";
import StaffSchedulingView from "../views/compliance/staff/schedules/StaffSchedulingView";
import AreasView from "../views/clinic/areas/AreasView";
import AreaAssignmentView from "../views/clinic/areas/AreaAssignmentView";
import StaffView from "../views/clinic/staff/StaffView";
import Typography from "@material-ui/core/Typography";
import ClinicView from "../views/clinic/ClinicView";
import StaffBadgeAssignment from "../views/compliance/status/StaffBadgeAssignment";
import EquipmentBadgeAssignment from "../views/compliance/status/EquipmentBadgeAssignment";
import PreferencesHome from "../views/scheduling/preferences/PreferencesHome";
import ComponentDisabled from "../views/scheduling/ComponentDisabled";
import BadgeJourney from "../views/compliance/status/BadgeJourney";
import {EditableMapView, MapUpload} from "map-view";
import EquipmentView from "../views/clinic/equipment/EquipmentView";



function InsufficientPermissions() {
    return <div>
        <Typography component={'p'}>
            This user does not have sufficient permissions to access the dashboard for this clinic. Please contact your
            system administrator to request a change to your permissions.
        </Typography>
    </div>;
}

/**
 * @return {null}
 */
function WrappedAnalyticsView({viewType, currentClinic, auth}) {
    let dataService = new AnalyticsDataService(process.env.REACT_APP_ANALYTICS_CORE_BACKEND_URL,
        process.env.REACT_APP_ANALYTICS_BACKEND_URL,
        process.env.REACT_APP_BACKEND_CLINICADMIN_URL,
        process.env.REACT_APP_BACKEND_USERADMIN_URL,
        currentClinic, auth);
    const user = useContext(UserContext);
    if (!currentClinic)
        return null;
    return (AnalyticsCoreViewFinder.getView(viewType, dataService, currentClinic, user));
}

function FullContextView(props, Cls, auth) {
    return <CurrentClinicContext.Consumer>
        {currentClinic => (
            <UserContext.Consumer>
                {user => (
                    <Cls currentClinic={currentClinic} currentUser={user} auth={auth} {...props}/>
                )}
            </UserContext.Consumer>
        )}
    </CurrentClinicContext.Consumer>

}

class ViewFinder {

    static getView(key, currentClinic, auth, user) {
        let element;

        if (key.includes('&clinic_admin&')) {
            key = key.replace('&clinic_admin&', '');

        }
        if (key.includes('&analytics_core&')) {
            return <WrappedAnalyticsView viewType={key.replace('&analytics_core&', '')} currentClinic={currentClinic}
                                         auth={auth}/>;
        }
        key = key.toLowerCase();
        switch (key) {
            case "areas":
                element = <AreasView currentClinic={currentClinic} auth={auth} user={user}/>;
                break;
            case "area_assignment":
                element = <AreaAssignmentView currentClinic={currentClinic} auth={auth} user={user}/>;
                break;
            case "staff":
                element = <StaffView currentClinic={currentClinic} auth={auth} user={user}/>;
                break;
            case "equipment":
                element = <EquipmentView currentClinic={currentClinic} auth={auth} user={user}/>;
                break;
            case "users":
                element = <UsersView currentClinic={currentClinic} auth={auth} user={user}/>;
                break;
            case "rooming":
            case "provider":
            case "occupancy":
            case "patient":
            case "staffview":
            case "map":
                element = <WorkflowMain currentView={key} currentClinic={currentClinic} auth={auth}/>;
                break;
            case 'tracking_statistics':
            case 'occupancy_&_utilization':
            case 'pivot_table':
            case 'clinic_level_pivot':
            case 'score_card':
                element = <WrappedAnalyticsView viewType={key.replace('&analytics_core&', '')}
                                                currentClinic={currentClinic} auth={auth}/>;
                break;
            case 'clinic':
                element = <ClinicView currentClinic={currentClinic}/>;
                break;
            case 'patient_history':
                element = new FullContextView({key: `patient_history_compliance_view`}, PatientComplianceView, auth);
                break;
            case 'beacons':
                element = new FullContextView({key: `beacons_compliance_view`}, BeaconsView, auth);
                break;
            case 'badge_assignment':
                element = new FullContextView({key: `staff_badge_assignment_view`}, StaffBadgeAssignment, auth);
                break;
            case 'equipment_badge_assignment':
                element = new FullContextView({key: `equipment_badge_assignment_view`}, EquipmentBadgeAssignment, auth);
                break;
            case 'badge_journey':
                element = <BadgeJourney currentClinic={currentClinic}/>;
                break;
            case 'staff_history':
                element = new FullContextView({key: `staff_history_compliance_view`}, StaffComplianceView, auth);
                break;
            case 'staff_schedules':
                element = new FullContextView({key: `staff_scheduling_compliance_view`}, StaffSchedulingView, auth);
                break;
            case 'alerts':
                element = new FullContextView({key: `badge_alerts_view`}, AlertsView, auth);
                break;
            case 'permissions':
                element = <InsufficientPermissions/>;
                break;
            case 'preferences':
                if (process.env.REACT_APP_DISABLE_SCHEDULING === 'true'){
                    element = new FullContextView({component: 'scheduling'}, ComponentDisabled);
                }else{
                    element = new FullContextView({key: `preferences-${key}`}, PreferencesHome);
                }
                break;
            case 'edit_map':
                element = <EditableMapView currentClinic={currentClinic} env={process.env} auth={auth}/>
                break;
            case 'upload_map':
                element = <MapUpload currentClinic={currentClinic} env={process.env} auth={auth}/>
                break;
            case 'analytics_pro':
                element = new FullContextView({component: 'analytics'}, ComponentDisabled);
                break;
            default:
                element = <span>More to come..</span>; // Fixme, have a better error?
                break;
        }
        return element;

    };


}

export default ViewFinder;

