import "../styles.compliance.css"

class StaffComplianceRecord {

    rawItem;

    constructor(rawItem, currentClinic) {
        this.rawItem = rawItem;
        this.currentClinic = currentClinic;
    }

    get id() {
        return `${this.currentClinic.currentClinic}_${this.rawItem.staff}`
    }

    get staff() {
        return this.rawItem.staff.last_name + (this.rawItem.staff.first_name ? `, ${this.rawItem.staff.first_name}` : '');
    }

    get beacon() {
        return this.rawItem.beaconCode;
    }

    get appointments() {
        return this.rawItem.appointments;
    }

    get scheduled() {
        return this.rawItem.scheduled;
    }

    get events() {
        return this.rawItem.events;
    }

    get areasVisited() {
        return this.rawItem.events ? this.rawItem.events.length : 0;
    }

    get status() {
        return this.areasVisited;
    }
}

/**
 * Post-processes and modifies the list of cases:
 * 1. Removes ones we want to ignore for display purposes
 * 2. Parses into JSON the string-valued json objects
 */
export function buildStaffComplianceRecords(rawStaffData, currentClinic, staff, scheduling) {
    const scheduledSet = new Set(scheduling.map(s => s.staff_id));
    const records = [];
    Object.keys(rawStaffData).forEach(staffId => {
        const staffData = rawStaffData[staffId];
        Object.keys(staffData).forEach(beaconKey => {
            const entries = staffData[beaconKey];
            records.push({
                staff: staff[staffId],
                beaconCode: beaconKey,
                beaconId: beaconKey,
                rawStaffData: staffData,
                scheduled: scheduledSet.has(parseInt(staffId)),
                events: entries.filter(entry => entry['event_type']).map(entry => {
                    return {
                        event: entry.start,
                        area: entry['event_type'],
                    }
                })

            })
        })
    });

    return records.map(r => new StaffComplianceRecord(r, currentClinic));
}



