import React, {useEffect, useMemo, useState} from 'react';
import {clinicAdminDataService} from "../../../service/DataService";
import {getBody} from "../../../utils/Utils";
import {Typography} from "@material-ui/core";
import "../styles.compliance.css"
import "react-datepicker/dist/react-datepicker.css";
import MUIDataTable from "mui-datatables";
import {buildStaffComplianceRecords} from "./StaffComplianceRecord";
import {useStaff} from "../../../utils/Hooks";
import columns from "./staffCols";
import {DatePickerStepper} from "../DatePickerStepper";
import {processComplianceData} from "../complianceUtils";
import StaffListByDateView from "./StaffListByDateView";


function StaffComplianceView({currentClinic}) {

    const [rows, setRows] = useState(null);
    const [rawData, setRawData] = useState(null);
    const [currentDate, setCurrentDate] = useState(null);
    const [resetPaging, setResetPaging] = useState(false);
    const [staffLoaded, staff] = useStaff(currentClinic.currentClinic, clinicAdminDataService);
    const [availableDatesData, setAvailableDatesData] = useState(null);
    const [scheduledStaff, setScheduledStaff] = useState(null);
    const [datesLoaded, setDatesLoaded] = useState(false);


    const onDateSelect = (date) => {
        setCurrentDate(date);
    };

    useEffect(() => {
        if (!window.env.EXCLUDE_ROOMS_VISITED) {
            if (columns.find(c => c.name === 'areasVisited') === undefined) {
                columns.splice(3, 0, {
                    label: '# of Spaces Seen',
                    name: 'areasVisited',
                    key: 'areasVisited'
                });
            }
        }
    }, []);

    useEffect(() => {
        if (currentDate && availableDatesData) {
            const curData = availableDatesData[String(currentDate.getTime())];
            if (curData) {
                const scheduled = `clinicadmin/clinics/${currentClinic.currentClinic}/staff/date/${currentDate.getTime()}`;
                clinicAdminDataService.get(scheduled).then(response => {
                    const body = getBody(response);
                    setScheduledStaff(body);
                });
            }
        }
    }, [currentDate, currentClinic]);

    useEffect(() => {
        if (datesLoaded) {
            if (currentDate && availableDatesData) {
                const curData = availableDatesData[String(currentDate.getTime())];
                if (curData) {
                    const url = `clinicadmin/clinics/${currentClinic.currentClinic}/staff/beacons/dates/${curData.dateString}`;
                    clinicAdminDataService.get(url).then(response => {
                        const dateMap = getBody(response);
                        if (dateMap) {
                            setRawData(dateMap)
                        } else {
                            setRows([]);
                        }
                    });
                }
            } else {
                setRows([]);
            }
        }
    }, [currentDate, availableDatesData, datesLoaded]);


    useEffect(() => {
        if (!currentClinic.currentClinic) return;
        const staffComplianceUrl = `clinicadmin/clinics/${currentClinic.currentClinic}/staff/beacons/dates`;
        clinicAdminDataService.get(staffComplianceUrl).then(response => {
            processComplianceData(response, setAvailableDatesData, setCurrentDate);
            setDatesLoaded(true);
        });
    }, [currentClinic]);


    useEffect(() => {
        setResetPaging(false);
    }, [resetPaging]);

    useMemo(() => {

        if (rawData && staffLoaded && scheduledStaff) {
            setRows(buildStaffComplianceRecords(rawData, currentClinic, staff, scheduledStaff));
        }

    }, [rawData, staff, staffLoaded, scheduledStaff]);

    const customSort = (data, colIndex, order) => {
        return data.sort((a, b) => {
            a = a.data[colIndex];
            b = b.data[colIndex];

            if (a === b) return 0;
            return order === 'desc' ? a > b ? -1 : 1 : a < b ? -1 : 1;
        });
    };
    return <div style={{display: 'flex', flexDirection: 'column', width: '100%', overflowX: 'hidden'}}>
        <div style={{width: '100%', paddingRight: '10px', left: '20px'}}>
            <Typography style={{paddingLeft: '20px'}} variant={'h2'} color={'primary'}>Staff Badge
                Activity</Typography>
            {availableDatesData && currentDate &&
            <DatePickerStepper
                availableDatesData={availableDatesData}
                currentDate={currentDate}
                onDateSelect={onDateSelect}
            />
            }
        </div>

        {!resetPaging &&
        <div style={{width: '100%', zIndex: 0}}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{width: staffLoaded && currentDate ? '60%' : '100%', paddingRight: '150px'}}>
                    <MUIDataTable
                        style={{width: '100%', zIndex: 0}}
                        data={rows ? rows : []}
                        columns={columns}
                        selectable={false}
                        expandableRows={true}

                        options={{
                            textLabels: {
                                body: {
                                    noMatch: rows && datesLoaded ? `No staff present` : "Loading..."
                                }
                            },
                            selectableRows: 'none',
                            rowsPerPageOptions: [15, 25, 50, 100],
                            rowsPerPage: 25,
                            print: false,
                            sort: true,
                            customSort: customSort,
                            search: {top: 0, left: 0},
                            download: false,
                            checkboxInline: false,
                            displayRowCheckbox: false,
                        }}
                    /></div>
                {staffLoaded && currentDate &&
                <StaffListByDateView style={{width: '40%', lineHeight: '.5'}} currentClinic={currentClinic}
                                     currentDate={currentDate}
                                     scheduled={scheduledStaff}
                                     staff={staff}/>}
            </div>

        </div>}
    </div>
}

export default StaffComplianceView;
