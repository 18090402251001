class FilterField {
    roundFn;
    key;
    title;
    dataField;

    constructor(filterFieldObj) {
        this.roundFn = filterFieldObj['roundFn'];
        this.rangeDisplayFn = (min, max) => {
            return {
                start: this.roundFn(min) + ' Minutes',
                end: this.roundFn(max) === '-0.00' ? '0 Minutes' : this.roundFn(max) + ' Minutes'
            }
        };
        this.key = filterFieldObj['dataField'];
        this.title = filterFieldObj['title'];
        this.dataField = filterFieldObj['dataField'];
    }
}

const filterFields = [
    {
        'roundFn': function (val) {
            return val.toFixed(2);
        },
        'title': 'Average Cycle Time',
        'dataField': 'avg_cycle_time'
    },
    {
        'roundFn': function (val) {
            return val.toFixed(2);
        },
        'title': 'Average Length of Day Change',
        'dataField': 'length_of_day_change'
    },
    {
        'roundFn': function (val) {
            return val.toFixed(2);
        },
        'title': 'Average Wait Time Change',
        'dataField': 'avg_wait_time_change'
    }
];

export const ScheduleFilterFields = filterFields.map(f => new FilterField(f));
