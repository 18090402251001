import React, {useEffect, useState} from "react";
import Button from "@material-ui/core/Button";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";

export function DatePickerStepper({currentDate, availableDatesData, onDateSelect}) {

    const [backwardEnabled, setBackwardEnabled] = useState(false),
        [forwardEnabled, setForwardEnabled] = useState(false);


    useEffect(() => {
        if (Object.keys(availableDatesData).length > 0) {
            setBackwardEnabled(true);
        }
    });
    const handleAvailableDates = (date) => {
        return date.getTime() in availableDatesData;
    };

    function dateStep(stepDirection) {
        const dates = Object.keys(availableDatesData);
        const currentIdx = dates.findIndex(e => e === currentDate.getTime().toString());
        if (stepDirection === 'back') {
            const nextDate = dates[currentIdx - 1];
            if (currentIdx - 1 === 0) {
                setBackwardEnabled(false);
            }
            setForwardEnabled(true);
            onDateSelect(availableDatesData[nextDate].date);
        } else {
            const nextDate = dates[currentIdx + 1];
            if (currentIdx + 1 === dates.length - 1) {
                setForwardEnabled(false);
            }
            setBackwardEnabled(true);
            onDateSelect(availableDatesData[nextDate].date);
        }
    }

    return <div
        style={{zIndex: 100, display: 'flex', flexDirection: 'row', padding: 0, margin: 0, alignItems: 'center'}}>
        <Button style={{padding: 0, margin: 0}} disabled={!backwardEnabled}
                onClick={() => dateStep('back')}>{'<'}</Button>
        <DatePicker
            selected={currentDate}
            onSelect={onDateSelect} //when day is clicked
            filterDate={handleAvailableDates}
        />
        <Button style={{padding: 0, margin: 0}} disabled={!forwardEnabled}
                onClick={() => dateStep('forward')}>{'>'}</Button>
    </div>;
}

DatePickerStepper.propTypes = {
    currentDate: PropTypes.object.isRequired,
    availableDatesData: PropTypes.object.isRequired,
    onDateSelect: PropTypes.func.isRequired
};
