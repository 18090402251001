import React from 'react';
import {Slot} from "../fullcalendar/model/ExtendedProps";
import {getDateForSeconds} from "../models/PreferenceRecord";


export const getDate = (str) => {
    if (typeof str === 'number') {
        str = getDateForSeconds(str);
        return str;
    }

    const timeSet = str.split(":").map(t => parseInt(t));
    const start = getNewDefaultDate();
    start.setHours(timeSet[0]);
    start.setMinutes(timeSet[1]);
    return start;
};

export const getEndDate = (start, duration) => {
    const end = new Date(start);
    end.setMinutes(end.getMinutes() + parseInt(duration));

    return end;
};

export const copySlot = (original) => {
    let rev = {};
    Object.keys(original).forEach(key => {
        const value = original[key];
        if (!value) {
            rev[key] = null;
        } else if (typeof value === 'string' || typeof value === "number") {
            rev[key] = value;
        } else {
            rev[key] = JSON.parse(JSON.stringify(value))
        }
    });

    return new Slot(rev);

};


export const updateSlotOnMove = (start, end, slot) => {
    const duration = slot.duration;
    slot.start_time = start.toLocaleTimeString('it-IT');
    if (typeof duration === 'string') {
        slot.duration = parseInt(duration);
    }
};


export const getNewDefaultDate = () => {
    return new Date(2020, 0, 1, 1, 0, 0, 0);
};


export const getRange = (start, end, cushionHours) => {
    const s = new Date(start);
    const e = new Date(end);
    s.setHours(s.getHours() - cushionHours);
    e.setHours(e.getHours() + cushionHours);
    if (e.getDate() !== 1) {
        e.setDate(1);
        e.setHours(23);
        e.setMinutes(59);
    }

    return {
        start: s,
        end: e,
        sTimeString: s.toLocaleTimeString('it-IT'),
        eTimeString: e.toLocaleTimeString('it-IT')
    }
};


