import PropTypes from 'prop-types';

const {shape, number, string, oneOf, array, arrayOf, any, func, bool} = PropTypes;

export const EditType = {
    UPDATE: "update",
    INSERT: "insert"
};

export const editTypes = oneOf([EditType.INSERT, EditType.UPDATE]);

export const rowModel = arrayOf(shape({
    id: string,
    columnIndex: number.isRequired,
    dataType: string,
    label: string.isRequired,
    value: any,
    enumData: array,
    pKey: any
}));

export const preferenceViewDataPropType = shape({
    type: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    average: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    plots: PropTypes.arrayOf(PropTypes.shape({
        x: PropTypes.any.isRequired,
        y: PropTypes.number.isRequired
    })),
    preferences: PropTypes.object.isRequired
});

export const preferenceViewPropTypes = {
    dataSets: PropTypes.arrayOf(preferenceViewDataPropType).isRequired
};

export const elasticResultFilterShape = shape({
    template_id: PropTypes.number.isRequired,
    clinic_id: PropTypes.number.isRequired,
    staff_id: PropTypes.number.isRequired,
    reference_template_id: PropTypes.number.isRequired
});


export const slots_shape = shape({
    start_time: PropTypes.string.isRequired,
    duration: PropTypes.any.isRequired,
    slot_rules: PropTypes.any
});

export const db_template_shape = shape({
    template_id: PropTypes.any.isRequired,
    staff_id: PropTypes.number.isRequired,
    dates: PropTypes.array.isRequired,
    slots: PropTypes.arrayOf(slots_shape).isRequired
}).isRequired;

export const mapModel = {
    areas: rowModel,
    clinicId: any.isRequired,
    areaName: string,
    onSubmit: func.isRequired,
    onDelete: func.isRequired,
};

export const rawBeaconDataShape = shape({
    beacon_id: number.isRequired,
    beacon_code: string.isRequired,
    beacon_mac: string.isRequired,
    staff_id: number,
    patient_id: number,
    workflow: shape({
        area: string,
        visit: shape({
            beacon_mac: string,
            visit_type: string,
            visit_id: number,
            beacon_id: number,
            patient_id: number,
            staff_id: number
        }),
        visit_id: number,
        wait_time: number,
        status: string,
        current_interactions_in_current_room: shape({title: string})
    }),
    prob: number,
    area: string,
    timestamp: number,
    last_event_time: number,
    area_id: string

});




