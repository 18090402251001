import React, {useEffect, useState} from "react";
import {
    ApiService,
    OccupancyView,
    ProviderView,
    RoomingView,
    RosterView,
    StaffView,
    useConfiguration
} from "workflow-core";
import PropTypes from "prop-types";
import Spinner from 'react-spinkit';
import {clinicAdminDataService} from "../service/DataService";
import {useClinics} from "../utils/Hooks";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {history} from '../utils/SharedResources';

import {MapView} from "map-view";
import LocationCity from '@material-ui/icons/LocationCity';

import Typography from '@material-ui/core/Typography';

export const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap'
    },
    spinnerHolderWorkflow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    spinner: {
        margin: 50
    }
}));


export default function WorkflowMain(props) {
    const {currentView, currentClinic} = props, classes = useStyles();
    const [apiService, setApiService] = useState(null),
        [setConfigApi, configLoading, config, saveConfig] = useConfiguration(apiService, 'workflow', {}),
        [clinicsLoaded, clinics] = useClinics(clinicAdminDataService),
        [patientIdleLimit, setPatientIdleLimit] = useState(25),
        [currentComponent, setCurrentComponent] = useState(null),
        [timezone, setTimezone] = useState(null);

    useEffect(() => {
        if (clinicsLoaded) {
            setApiService(new ApiService(props.auth, process.env, clinics, history, false, currentClinic));
        }
        if (clinicsLoaded) {
            const thisClinic = clinics.find(c => c.site_id === currentClinic);
            if (thisClinic) {
                if (thisClinic.options && thisClinic.options.workflow && thisClinic.options.workflow.patient_idle_limit) {
                    setPatientIdleLimit(thisClinic.options.workflow.patient_idle_limit);
                }
                setTimezone(thisClinic.timezone)
            }
        }
    }, [currentClinic, clinicsLoaded]);

    useEffect(() => {
        setConfigApi(apiService);
    }, [apiService]);

    const columnConfigUpdated = (key) => {
        return (tableRef) => {
            const cfg = Object.assign({}, config);

            let columns = tableRef.current.state.columns.filter(c => c.display === 'true').map(c => {
                return c.name;
            });

            cfg[key] = {
                columns: columns,
                filterList: tableRef.current.state.filterList,
                sortOrder: tableRef.current.state.sortOrder,
                rowsPerPage : tableRef.current.state.rowsPerPage
            };
            saveConfig(cfg);
        };
    };

    useEffect(() => {
        const getView = () => {
            if (apiService && apiService.isClinicValid()) {
                switch (currentView) {
                    case 'provider':
                        return (
                            <ProviderView
                                apiService={apiService}
                                currentClinic={currentClinic}
                                config={config.provider}
                                visibleColumns = {config ? config.provider ? config.provider.columns : null : null}
                                filterList = {config ? config.provider ? config.provider.filterList : null : null}
                                sortOrder = {config ? config.provider ? config.provider.sortOrder : null : null}
                                rowsPerPage = {config ? config.provider ? config.provider.rowsPerPage : null : null}
                                patientIdleLimit={patientIdleLimit}
                                updateConfig={columnConfigUpdated('provider')}
                                timezone={timezone}
                                />
                        );
                    case 'rooming':
                        return (
                            <RoomingView
                                currentClinic={currentClinic}
                                apiService={apiService}
                                config={config.provider}
                                visibleColumns={config ? config.rooming ? config.rooming.columns : null : null}
                                sortOrder = {config ? config.rooming ? config.rooming.sortOrder : null : null}
                                rowsPerPage = {config ? config.rooming ? config.rooming.rowsPerPage : null : null}
                                updateConfig={columnConfigUpdated('rooming')}
                                timezone={timezone}
                            />
                        );
                    case 'occupancy':
                        return <OccupancyView
                            apiService={apiService}
                            currentClinic={currentClinic}
                            visibleColumns={config ? config.occupancy ? config.occupancy.columns : null : null}
                            filterList = {config ? config.occupancy ? config.occupancy.filterList : null : null}
                            sortOrder = {config ? config.occupancy ? config.occupancy.sortOrder : null : null}
                            rowsPerPage = {config ? config.occupancy ? config.occupancy.rowsPerPage : null : null}
                            updateConfig={columnConfigUpdated('occupancy')}
                            timezone={timezone}
                            />;
                    case 'patient':
                        return <RosterView
                            apiService={apiService}
                            currentClinic={currentClinic}
                            visibleColumns={config ? config.roster ? config.roster.columns : null : null}
                            filterList = {config ? config.roster ? config.roster.filterList : null : null}
                            sortOrder = {config ? config.roster ? config.roster.sortOrder : null : null}
                            rowsPerPage = {config ? config.roster ? config.roster.rowsPerPage : null : null}
                            updateConfig={columnConfigUpdated('roster')}
                            timezone={timezone}
                            />;
                    case 'staffview':
                        return <StaffView
                            apiService={apiService}
                            currentClinic={currentClinic}
                            visibleColumns={config ? config.staff ? config.staff.columns : null : null}
                            filterList = {config ? config.staff ? config.staff.filterList : null : null}
                            sortOrder = {config ? config.staff ? config.staff.sortOrder : null : null}
                            rowsPerPage = {config ? config.staff ? config.staff.rowsPerPage : null : null}
                            updateConfig={columnConfigUpdated('staff')}
                            timezone={timezone}
                            />;
                    case 'map':
                        return <div>
                            <LocationCity style={{fontSize: 30, marginRight: 4}}/>
                            RTLS
                            <MapView
                                currentClinic={currentClinic}
                                env={process.env}
                                auth={props.auth}
                                history={history}
                                display_time={true}
                            />
                        </div>
                    default:
                        return null;
                }
            } else {
                return null;
            }
        };
        setCurrentComponent(getView());
    }, [currentView, apiService, patientIdleLimit, config]);

    return configLoading || !apiService ?
        <div className={classes.spinnerHolderWorkflow}><Spinner name='circle' className={classes.spinner}/></div>
        : 
        apiService.isClinicValid() ? 
            <div style={{paddingLeft: "20px", paddingRight: "20px"}}>
                {currentComponent}
            </div>
        :
        <div style={{textAlign: "center"}}>
            <Typography variant="h2" gutterBottom>
                Realtime Workflow is not available for this Clinic
            </Typography>
        </div>
    ;
}


WorkflowMain.propTypes = {
    currentView: PropTypes.string.isRequired
};
