import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types"
import {schedulingDataService} from "../../service/DataService";
import {getBody, humanize} from "../../utils/Utils";
import {ScheduleFilterFields} from "../../model/FilterFields";
import {Typography} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import patient_friendly from '../../assets/img/patient_friendly.png'
import recommended from '../../assets/img/recommended.png'
import {IoIosCheckmark} from "react-icons/all";
import Grid from '@material-ui/core/Grid';
import styled from "styled-components";
import Paper from '@material-ui/core/Paper';


const icons = {
    patient_friendly: patient_friendly,
    recommended: recommended,
    conservative: recommended
};

const HoverText = styled.a`
    text-decoration: none
	:hover {
		cursor: hand;
	},
`;

function RecommendedResults({currentClinic, curStaff, curTemplate, selected, setSelected}) {

    const url = `/scheduling/schedules/clinics/${currentClinic}/staff/${curStaff}/reference_template/${curTemplate}/templates/recommended`;
    const [elements, setElements] = useState([]);
    const [body, setBody] = useState(null);

    const cards = {};
    const handleItemClick = (item) => {
        setSelected(item)
    };


    const makeCard = (key, item) => {
        const header = humanize(key);
        const values = ScheduleFilterFields.map(ff => {
            const value = ff.roundFn(item[ff.dataField]);
            return <Typography key={ff.dataField} variant={'caption'} color={'primary'}>{`${ff.title}: ${value}`}</Typography>
        });
        const icon = icons[key];
        return  <Grid item>
                <HoverText key={`hovercard_${key}_${item.template_id}`} href={'#'} itemKey={key} onClick={() => {
                    handleItemClick(item, key)
                }}>
                <Paper style={{height: '100%'}}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Typography gutterBottom variant="h5" component="h2">
                            {header}
                        </Typography>
                        {selected && selected.template_id === item.template_id &&
                        <div style={{width: '20px', height: '100%'}}>
                            <IoIosCheckmark style={{width: '30px', height: '30px', opacity: '.5'}}/>
                        </div>
                        }
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', height: '100%'}}>
                        <img src={icon} style={{width: 50, height: 50}} alt={header}/>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {values}
                        </div>
                    </div>
                </Paper>
                </HoverText>
            </Grid>
    };

    useEffect(() => {
        if (body) makeElements(body);
    }, [body, selected]);


    const makeElements = (body) => {
        const els = [];
        Object.keys(body).forEach(key => {
            const json = JSON.parse(body[key]);
            if (json && json.hits && json.hits.hits.length > 0) {
                const item = json.hits.hits[0]._source;
                const card = makeCard(key, item);
                cards[key] = card;
                els.push(card);
            }
        });
        setElements(els);
    };


    useEffect(() => {

        schedulingDataService.get(url).then(response => {
            const content = getBody(response);
            if (content) {
                setBody(content);
            }
        })

    }, []);
// alignItems="stretch"
    return <Grid container
                 style={{width: '98%', margin: '5px'}}
                 spacing={2}
                 direction={"row"}
                 wrap={"nowrap"}>{elements}</Grid>
}

export default RecommendedResults;

RecommendedResults.propTypes = {
    currentClinic: PropTypes.number.isRequired,
    curStaff: PropTypes.number.isRequired,
    callback: PropTypes.func
};
