import {ROLES} from "ah-auth0";

let example = [{
    "clinicRoles": [],
    "defaultClinicId": 2,
    "firstName": null,
    "isCustomerAdmin": true,
    "isSuper": true,
    "lastName": null,
    "properties": {
        "email": "product@apprenticehealth.com",
        "email_verified": false,
        "name": "product@apprenticehealth.com",
        "nickname": "product",
        "updated_at": "2020-01-07T23:42:54.704Z"
    },
    "userId": 4,
    "username": "auth0|5cb8fd9dd735da1124b54f29"
}];

export default class User {
    jsonObj;

    constructor(jsonObj) {
        this.jsonObj = jsonObj;
    }

    get userId() {
        return this.jsonObj.userId;
    }

    get username() {
        return this.jsonObj.username;
    }

    set username(val) {
        this.jsonObj['username'] = val;
    }

    get clinicRoles() {
        return this.jsonObj.clinicRoles ? this.jsonObj.clinicRoles : [];
    }

    set clinicRoles(val) {
        this.jsonObj.clinicRoles = val;
    }

    get defaultClinicId() {
        return this.jsonObj.defaultClinicId;
    }

    set defaultClinicId(clinicId) {
        this.jsonObj['defaultClinicId'] = clinicId;
    }

    get firstName() {
        return this.jsonObj.firstName;
    }

    set firstName(firstName) {
        this.jsonObj['firstName'] = firstName;
    }

    get lastName() {
        return this.jsonObj.lastName;
    }

    set lastName(lastName) {
        this.jsonObj['lastName'] = lastName;
    }

    get email() {
        return this.jsonObj.email;
    }

    set email(val) {
        if (val) {
            val = val.toLowerCase();
        }
        this.jsonObj.email = val;
    }

    get isCustomerAdmin() {
        return this.jsonObj.isCustomerAdmin;
    }

    set isCustomerAdmin(val) {
        this.jsonObj.isCustomerAdmin = val;
    }

    get clinicRolesHolder() {
        return {admin: this.isCustomerAdmin, clinicRoles: this.clinicRoles}
    }

    get displayName() {
        if (this.firstName && this.lastName) {
            return this.firstName + ' ' + this.lastName
        } else if (this.email) {
            return this.email;
        } else {
            return this.username;
        }
    }

    getClinicRole(clinicId) {
        const role = this.clinicRoles.find(c => c.site_id === clinicId);
        return role ? role : ROLES.NO_PERMISSION;
    }

    hasPermission(clinicId, requiredRole) {
        if (this.jsonObj.isCustomerAdmin || this.jsonObj.isSuper) {
            return true;
        }
        return this.getClinicRole(clinicId) <= requiredRole;
    }

    toJson() {
        return this.jsonObj;
    }
}
