import React, {useState} from 'react';
import {FilePond} from "react-filepond";
import PropTypes from "prop-types"
import 'filepond/dist/filepond.min.css';
import {hasJsonStructure} from "../utils/Utils";

function FileUpload(props) {
    const {files, authHeaders, onUploadComplete, onResponseReceived, url, raiseError} = props;
    const heading = props.heading || '', subHeading = props.subHeading || '';
    const [_files, setFiles] = useState(files);
    const [filePond, setPond] = useState(null);

    const handleInit = () => {

        if (!filePond) return;
        const attr = filePond._pond;
        attr.labelIdle = ` ${heading} ${attr.labelIdle} ${subHeading}`

    };

    const onResponse = (response) => {
        if (onResponseReceived) {
            if (hasJsonStructure(response)) {
                onResponseReceived(JSON.parse(response));
            } else {
                onResponseReceived(response);
            }
        }
        return response;
    };


    const handleUploadComplete = (error, file) => {
        if (onUploadComplete) {
            onUploadComplete(error, file)
        }
    };

    return <FilePond ref={ref => setPond(ref)}
                     files={_files}
                     allowMultiple={false}
                     maxFiles={1}
                     server={{
                         url: url,
                         process: {
                             onerror: (body) => {
                                 if (hasJsonStructure(body)) {
                                     const error = JSON.parse(body);
                                     if (raiseError) {
                                         raiseError(error);
                                     }
                                 }
                             },
                             headers: authHeaders,
                             widthCredentials: true,
                             onload: (response) => onResponse(response)
                         }
                     }}
                     allowRevert={false}
                     onprocessfile={(error, file) => {
                         handleUploadComplete(error, file)
                     }}
                     oninit={(evt) => handleInit()}
                     onupdatefiles={fileItems => {
                         setFiles(fileItems.map(fileItem => fileItem.file));
                     }}
                     {...props}
    >
    </FilePond>
}

export default FileUpload;

FileUpload.propTypes = {
    files: PropTypes.arrayOf(PropTypes.object),
    url: PropTypes.string.isRequired,
    authHeaders: PropTypes.object.isRequired,
    subHeading: PropTypes.string,
    heading: PropTypes.string,
    onUploadComplete: PropTypes.func,
    onResponseReceived: PropTypes.func
};
