import React from 'react';
import {Typography} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import {ArrowForward} from "@material-ui/icons";


function ProgressStatusViewText({record, id}) {

    const steps = record.statusStates.map((item, index) => {
        const completed = index <= record.status;

        if (completed) {
            return <div key={`preference_floater_${id}_${index}`} style={{display: 'flex', flexDirection: 'row'}}>
                <CheckIcon style={{height: '20px', width: '20px', color: '#077d18'}}/>
                <Typography variant={'caption'}>{`  ${item}`}</Typography>
            </div>
        } else {

            return <div style={{alpha: '.5'}} key={`preference_floater_${id}_${index}`}>
                <ArrowForward style={{height: '15px', width: '15px', color: '#a8ada6'}}/>
                <Typography variant={'caption'}>{`  ${item}`}</Typography>
            </div>
        }
    });

    return <div style={{zIndex: '3000'}}>
        <Typography variant={'h6'} color={"primary"}>STATUS</Typography>
        {steps}
    </div>

}

export default ProgressStatusViewText;
