import {getCurrentClinicRole, humanize} from "../utils/Utils";
import {ROLES} from 'ah-auth0';

export class MenuItem {
    constructor(label, key, menuItems = null) {
        this.label = label ? label : humanize(key);
        this.value = key;
        this.menuItems = menuItems;
        if (this.menuItems) {
            this.menuItems.map(mi => mi.setParent(this));
        }
    }

    value;
    label;
    menuItems;
    parent;
    hasSubMenu = () => {
        return this.menuItems !== null;
    };
    setParent = (parent) => {
        this.parent = parent;
    };
    getPath = () => {
        if (!this.parent) {
            return `/${this.value}`;
        }
        return this.parent.getPath() + `/${this.value}`;
    }
}

const LAYOUT = {
    role: ROLES.PATIENT_WORKFLOW,
    children: {
        "analytics_core": {
            label: 'Analytics',
            role: ROLES.STAFF,
            children: {
                "tracking_statistics": {
                    'label': 'KPI Dashboard'
                },
                "global" : {
                    'label' : 'Global',
                    'role': ROLES.CLINIC_ADMIN,
                    'children' : {
                        'occupancy_utilization' : {
                            'label' : "Occupancy/Utilization"
                        },
                        'flow_metrics' : {
                            'label' : "Flow Metrics"
                        },
                        'tdabc_report' : {
                            'label' : "TDABC Report"
                        }
                    }
                },
                "pivot_table": {
                    'label': 'Pivots',
                    'role': ROLES.CLINIC_ADMIN,
                    children: {
                        "flow_metrics_pivot": {
                            'label': 'Flow Metrics Pivot'
                        },
                         "area_pivot": {
                             'label': 'Area Metrics Pivot'
                        }
                    }
                },
                "comparative_analysis": {
                    'role': ROLES.CLINIC_ADMIN,
                    children: {
                        "interaction_time_by_role": {
                            'label': 'Interaction Time by Role'
                        },
                        "interaction_time_by_attending": {
                            'label': 'Interaction Time by Attending'
                        },
                        "cycle_time_by_clinic": {
                            'label': 'Cycle Time by Attending'
                        },
                        "total_wait_time_by_clinic": {
                            'label': 'Wait Time by Attending'
                        }
                    }
                },
                "cycle_times": {
                    'label': 'Flow Metrics',
                    children: {
                        "cycle_time": {
                            'label': 'Cycle Time'
                        },
                        "wait_time": {
                            'label': 'Wait Time'
                        },
                        "total_time_in_exam_room": {
                            'label': 'Total Time in Exam Room'
                        },
                        "check-in_to_room_entry": {
                            'label': 'Check-in to Room Entry'
                        },
                        "time_to_attending": {
                            'label': 'Time to Attending'
                        },
                        "time_to_first_interaction": {
                            'label': 'Time to First Interaction'
                        },
                        "check-in_to_scheduled": {
                            'label' : "Check-in to Scheduled"
                        }
                    }
                },
                "historical_trends": {
                    children: {
                        "historical_cycle_time": {
                            'label': 'Historical Cycle Time'
                        },
                        "historical_wait_time": {
                            'label': 'Historical Wait Time'
                        },
                        "historical_time_in_exam_room": {
                            'label': 'Time in Exam Room'
                        },
                        "historical_time_to_room_entry": {
                            'label' : 'Check-in to Room Entry'
                        },
                        "historical_time_to_attending": {
                            'label' : 'Check-in to Attending'
                        },
                        "historical_time_to_any_interaction" : {
                            'label' : 'Check-in to Interaction'
                        },
                        "historical_check-in_scheduled": {
                            'label': 'Check-in to Scheduled'
                        }
                    }
                },
                "occupancy_&_utilization": {
                    'role': ROLES.CLINIC_ADMIN,
                    children: {
                        "total_room_usage": {
                            'label': 'Total Room Usage'
                        },
                        "hourly_usage": {
                            'label': 'Hourly Usage'
                        },
                        "room_usage_heatmap": {
                            'label': 'Hourly Room Heatmap'
                        }
                    }
                }
            }
        },
        "workflow": {
            role: ROLES.WORKFLOW_ONLY,
            children: {
                "map" : {
                    'label' : "Map"
                },
                "occupancy": {
                    'label': 'Occupancy'
                },
                "provider": {
                    'label': 'Provider'
                },
                "patient": {
                    'label': 'Patient'
                },
                "staffview": {
                    'label': 'Staff'
                },
                "rooming" : {
                    'label' : 'Rooming'
                },
            }
        },
        "scheduling": {
            role: ROLES.STAFF,
            children: {
                "preferences": {
                    label: 'Preferences'
                }
            }
        },
        "analytics_pro" : {
            role: ROLES.CLINIC_ADMIN,
            children: {
                "analytics_pro" : {
                    label: "Analytics"
                }
            }
        },
        "user_admin": {
            role: ROLES.CLINIC_ADMIN,
            children: {
                "users": {
                    label: 'Users'
                }
            }
        },
        "clinic_admin": {
            role: ROLES.CLINIC_ADMIN,
            children: {
                "clinic": {
                    'label': 'Clinic Information'
                },
                "staff": {
                    children: {
                        'staff' : {
                            'label' : "Staff"
                        },
                        'badge_assignment' : {
                            'label' : 'Staff Badge Assignment'
                        }
                    }
                },
                "area_assignment": {
                    'label': 'Spaces'
                },
                "equipment": {
                    children: {
                        "equipment_badge_assignment": {
                            'label': 'Equipment Badge Assignment'
                        },
                        "equipment": {
                            'label' : 'Equipment'
                        }
                    }
                },
                "map" : {
                    children: {
                        "edit_map" : {
                            'label' : 'Edit Map'
                        },
                        "upload_map" : {
                            'label' : 'Upload Map'
                        }
                    }
                }
            }
        }
    }
};

function filterLayout(layout, myRole, parentRole) {
    if ((layout.role && layout.role >= myRole) || (layout.role === undefined && parentRole >= myRole)) {
        if (layout.children) {
            const newChildren = {};
            Object.entries(layout.children).forEach(([k, v]) => {
                const filteredValue = filterLayout(v, myRole, layout.role || parentRole);
                if (filteredValue) {
                    newChildren[k] = filteredValue;
                }
            });
            if (Object.keys(newChildren).length > 0) {
                layout.children = newChildren;
            } else {
                delete layout['children'];
            }
            return layout;
        } else {
            return layout;
        }
    }
}

/***
 * Gets the tab layout for the given user and clinic
 */
export default function getTabLayout(user, currentClinic) {
    let currentClinicRole = getCurrentClinicRole(user, currentClinic),
        layout = JSON.parse(JSON.stringify(LAYOUT));

    if (!user || currentClinic === null || currentClinic === undefined || currentClinicRole.role > ROLES.PATIENT_WORKFLOW) {
        return {
            children: {
                'permissions': {
                    'label': 'Insufficient Permissions'
                }
            }
        };
    }
    filterLayout(layout, currentClinicRole.role, ROLES.STAFF);
    if (window.env.HIDE_BMC_VIEWS) {
        if (layout.children.scheduling)
            delete layout.children["scheduling"].children["preferences"];
        if (layout.children.workflow)
            delete layout.children["workflow"];
        if (layout.children["analytics_core"].children["occupancy_&_utilization"])
            delete layout.children["analytics_core"].children["occupancy_&_utilization"].children["calendar_view"];
    }

    if (window.env.CUSTOMER === 'DUKE') {
        if (layout.children.scheduling)
            delete layout.children["scheduling"]
    }else if (process.env.REACT_APP_CUSTOMER == 'mgbicare' || process.env.REACT_APP_CUSTOMER == 'uthealth'){
        if (layout.children.scheduling){
            delete layout.children["scheduling"]
        }
        if (layout.children.analytics_pro){
            delete layout.children["analytics_pro"]
        }
        if (layout.children.analytics_core.children.pivot_table){
            delete layout.children.analytics_core.children.pivot_table
        }
        if (layout.children.analytics_core.children['occupancy_&_utilization']){
            delete layout.children.analytics_core.children['occupancy_&_utilization']
        }
            
    }
    if (window.env.AC_IS_DEMO) {
        layout.children["analytics_core"].children["occupancy_&_utilization"].children["calendar_view"] = {
            label: 'Calendar View'
        }
    }

    return layout;
}
