import React from 'react';

function AhDiv({style, className, children}) {
    return className ? <div style={style} className={className}>{children}</div> : <div style={style}>{children}</div>
}

const immutableKeys = new Set(['display', 'flexDirection']);


const getBaseRow = () => {
    return {

        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignItems: 'baseline'
    };
};


const getBaseColumn = () => {
    return {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center'
    }
};

const getStyleObj = (column, incoming) => {
    let outgoing = column ? getBaseRow() : getBaseColumn();
    if (incoming) {

        Object.keys(incoming).forEach(key => {
            if (!immutableKeys.has(key)) {
                outgoing[key] = incoming[key];
            }
        });
    }
    return outgoing;
};


export function Rows({style, className, children}) {

    let rowStyle = getStyleObj(true, style);

    return <AhDiv style={rowStyle} className={className}>{children}</AhDiv>
}

export function Columns({style, className, children}) {

    let rowStyle = getStyleObj(false, style);

    return <AhDiv style={rowStyle} className={className}>{children}</AhDiv>
}


