import * as moment from "moment-mini";
import {ROLES} from "ah-auth0";

export const csvToJson = (headers, rows) => {
    const indexes = headers.map((header, index) => index);
    return rows.map(row => {
        const cur = {};
        indexes.forEach(ind => {
            cur[headers[ind]] = row[ind];
        });
        return cur;
    })

};

export const floorTime = (date, minutes) => {
    let interval = 1000 * 60 * minutes;
    return new Date(Math.floor(date / interval) * interval);
};

export const ceilTime = (date, minutes) => {
    let interval = 1000 * 60 * minutes;
    return new Date(Math.ceil(date / interval) * interval);
};
export const formatUnixTime = (value) => {
    return value ? moment.unix(value).format('YYYY-MM-DD hh:mm A') : '';
};

export const convertToObj = (columns, response) => {
    return response.map((at) => {
        return columns.reduce((prev, curr, idx) => {
            if (at[idx] !== undefined && at[idx] !== null)
                return {...prev, [curr]: at[idx]};
            else
                return prev;
        }, {});
    });
};

export function deepCompare() {
    let i, l, leftChain, rightChain;

    function compare2Objects(x, y) {
        let p;

        // remember that NaN === NaN returns false
        // and isNaN(undefined) returns true
        if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
            return true;
        }

        // Compare primitives and functions.
        // Check if both arguments link to the same object.
        // Especially useful on the step where we compare prototypes
        if (x === y) {
            return true;
        }

        // Works in case when functions are created in constructor.
        // Comparing dates is a common scenario. Another built-ins?
        // We can even handle functions passed across iframes
        if ((typeof x === 'function' && typeof y === 'function') ||
            (x instanceof Date && y instanceof Date) ||
            (x instanceof RegExp && y instanceof RegExp) ||
            (x instanceof String && y instanceof String) ||
            (x instanceof Number && y instanceof Number)) {
            return x.toString() === y.toString();
        }

        // At last checking prototypes as good as we can
        if (!(x instanceof Object && y instanceof Object)) {
            return false;
        }

        if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
            return false;
        }

        if (x.constructor !== y.constructor) {
            return false;
        }

        if (x.prototype !== y.prototype) {
            return false;
        }

        // Check for infinitive linking loops
        if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
            return false;
        }

        // Quick checking of one object being a subset of another.
        // todo: cache the structure of arguments[0] for performance
        for (p in y) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                return false;
            } else if (typeof y[p] !== typeof x[p]) {
                return false;
            }
        }

        for (p in x) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                return false;
            } else if (typeof y[p] !== typeof x[p]) {
                return false;
            }

            switch (typeof (x[p])) {
                case 'object':
                case 'function':

                    leftChain.push(x);
                    rightChain.push(y);

                    if (!compare2Objects(x[p], y[p])) {
                        return false;
                    }

                    leftChain.pop();
                    rightChain.pop();
                    break;

                default:
                    if (x[p] !== y[p]) {
                        return false;
                    }
                    break;
            }
        }

        return true;
    }

    if (arguments.length < 1) {
        return true; //Die silently? Don't know how to handle such case, please help...
        // throw "Need two or more arguments to compare";
    }

    for (i = 1, l = arguments.length; i < l; i++) {

        leftChain = []; //Todo: this can be cached
        rightChain = [];

        if (!compare2Objects(arguments[0], arguments[i])) {
            return false;
        }
    }

    return true;
}

export const rolePrettyName = {
    CLINIC_ADMIN: 'Clinic Admin',
    STAFF: 'Physician Staff',
    WORKFLOW_ONLY: 'Support Staff',
    REPORTING_USER: 'Reporting Staff',
    IT_STAFF: 'IT Staff'
};

export function getCurrentClinicRole(user, currentClinic) {
    let role;
    if (user.isSuper || user.isCustomerAdmin) {
        role = user.isSuper ? ROLES.SUPER_USER : ROLES.CUSTOMER_ADMIN;
    } else {
        const cr = user.clinicRoles.filter(cr => cr.site_id === currentClinic);
        if (cr.length > 0) {
            role = cr[0].role.value;
        } else {
            role = ROLES.NO_PERMISSION;
        }
    }
    if (role === ROLES.staff) {
        return {role: role, staffId: user.clinicRoles.filter(cr => cr.site_id === currentClinic)[0].staffId};
    }
    return {role: role};
}

export function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
}


export const arrSum = function (arr) {
    return arr.reduce(function (a, b) {
        return a + b
    }, 0);
};

export const shallowClone = (obj) => {
    const newObj = {};
    Object.keys(obj).forEach(key => {
        newObj[key] = obj[key];

    });
    return obj;
};
export const deepClone = (obj) => {
    const str = JSON.stringify(obj);
    return JSON.parse(str);
};

function getFootprint(x) {
    if (typeof x === 'object') {
        return JSON.stringify(x, null, 0).replace(" ", "");
    } else {
        return x + '';
    }
}

export const set = (arr) => {
    const map = {};
    arr.forEach(item => {
        const key = getFootprint(item);
        map[key] = item;
    });

    return Object.values(map);

};


export const humanize = (str) => {
    let sentence = str.replace(" ", "_");
    let words = sentence.split('_');
    let result = "";

    words.forEach(word => {
        word = word.toLowerCase() === 'avg' ? 'average' : word.toLowerCase();
        word = word.toLowerCase() === 'num' ? 'number' : word.toLowerCase();
        result += word.charAt(0).toUpperCase() + word.slice(1) + " ";
    });


    return result;
};

export const compare = (a, b, delimeter) => {
    if (a[delimeter] < b[delimeter])
        return -1;
    if (a[delimeter] > b[delimeter])
        return 1;
    return 0;
};

export const getBody = (response) => {
    if (response && response.hasOwnProperty("data")) {
        return response.data;
    }
    return null;
};

export function hasJsonStructure(str) {
    if (typeof str !== 'string') return false;
    try {
        const result = JSON.parse(str);
        const type = Object.prototype.toString.call(result);
        return type === '[object Object]'
            || type === '[object Array]';
    } catch (err) {
        return false;
    }
}

export function hasPermission(clinic, user, requiredRole) {
    const getClinicRole = (clinicId) => {
        const clinicRole = user.clinicRoles.find(c => c.site_id === clinicId);
        return clinicRole ? clinicRole.role.value : ROLES.NO_PERMISSION;
    };

    if (user.isCustomerAdmin || user.isSuper) {
        return true;
    }
    return getClinicRole(clinic) <= requiredRole;
}
