import {useEffect, useState} from "react";

export function useStaff(clinic, dataService) {
    const [staff, setStaff] = useState([]);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        const getStaff = async () => {
            const result = await dataService.get(`clinicadmin/clinics/${clinic}/staff`);
            const {data} = result;
            const members = {};
            if (data && data.rows) {
                data.rows.forEach((r) => {
                    const s = {};
                    data.columns.forEach((c, ix) => {
                        s[c] = r[ix];
                    });
                    members[s.staff_id] = s;
                });
                setStaff(members);
                setLoaded(true);
            }
        };
        getStaff();
    }, [clinic]);
    return [loaded, staff, setStaff];
}

export function useAreas(clinic, dataService) {
    const [areas, setAreas] = useState(null);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        const getAreas = async () => {
            const result = await dataService.get(`clinicadmin/clinics/${clinic}/areas`);
            const {data} = result;
            const members = {};
            if (data && data.rows) {
                data.rows.forEach((r) => {
                    const s = {};
                    data.columns.forEach((c, ix) => {
                        s[c] = r[ix];
                    });
                    members[s.area_id] = s;
                });
                setAreas(members);
                setLoaded(true);
            }
        };
        getAreas();
    }, [clinic]);
    return [loaded, areas, setAreas];
}


export function useClinics(clinicAdminDataService) {
    const [clinics, setClinics] = useState([]);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        const getClinics = async () => {
            const result = await clinicAdminDataService.get(`clinicadmin/clinics/`);
            setClinics(result.data);
            setLoaded(true);
        };
        getClinics();
    }, []);
    return [loaded, clinics, setClinics];
}


export function useClinic(clinicId, clinicAdminDataService) {
    const [clinic, setClinic] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [clinicsLoaded, clinics] = useClinics(clinicAdminDataService);
    useEffect(() => {
        if (clinicsLoaded && clinics) {
            setClinic(clinics.find(c => c.site_id === clinicId));
            setLoaded(true);
        }
    }, [clinicsLoaded, clinicId]);

    return [loaded, clinic];
}

export function useRooms(clinic, dataService) {
    const [rooms, setRooms] = useState([]);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        const getRooms = async () => {
            const result = await dataService.get(`clinicadmin/clinics/${clinic}/areas`);
            const {data} = result;
            const members = [];
            data.rows.forEach((r) => {
                const s = {};
                data.columns.forEach((c, ix) => {
                    s[c] = r[ix];
                });
                members.push(s);
            });
            setRooms(members);
            setLoaded(true);
        };
        getRooms();
    }, []);
    return [loaded, rooms, setRooms];
}
