import React, {useEffect, useState} from 'react';
import {DynamicRangeSlider, ReactiveBase, ReactiveList} from '@appbaseio/reactivesearch';
import {getBody} from '../../utils/Utils';
import Divider from '@material-ui/core/Divider';
import {Typography} from '@material-ui/core';
import {schedulingDataService as dataService} from '../../service/DataService';
import Button from '@material-ui/core/Button';
import {ahTheme} from '../../styles/AhMuiTheme';
import {IoIosRadioButtonOff, IoIosRadioButtonOn} from "react-icons/io"
import Slide from "@material-ui/core/Slide";
import RecommendedResults from "./RecommendedResults";
import ResultSelectionView from "./schedule/ResultSelectionView";
import {ScheduleFilterFields} from "../../model/FilterFields";
import "./ResultsFilterView.css"
const {ResultListWrapper} = ReactiveList;

let mainHost = process.env.REACT_APP_SCHEDULING_BACKEND;

const getDefaultRangeDisplayFn = (roundFn) => (min, max) => {
    return {
        start: roundFn(min) + ' Minutes',
        end: roundFn(max) === '-0' ? '0 Minutes' : roundFn(max) + ' Minutes'
    }
};



function getDefaultQuery(staffId, currentTemplate) {
    const defaultQuery = {
        query: {
            bool: {
                must: [
                    {
                        range: {
                            length_of_day_change: {
                                lte: 10,
                                gte: -10
                            }
                        }
                    },
                    {
                        terms: {staff_id: [staffId]}
                    },
                    {
                        terms: {reference_template_id: [currentTemplate]}
                    }
                ]
            }
        }
    };

    return defaultQuery;
}

function getSliderQuery(staffId, currentTemplate, value, filterField) {
    let q = getDefaultQuery(staffId, currentTemplate);
    if (value) {
        q.query.bool.must.push({
            range: {
                [filterField.dataField]: {
                    gte: value[0],
                    lte: value[1],
                    boost: 2
                }
            }
        });
    }
    return q;
}


/**
 * The dynamicrangeslider in this component allows you to specify a custom query, but that custom query isn't included
 * when pulling the range at the beginning. To make up for that, we're setting the custom query (namely setting the staff)
 * right here instead.
 */
function doCustomQueryHack(body, currentStaff, currentTemplate) {
    const bodyLns = body.split('\n');
    const bodyJson = JSON.parse(bodyLns[1]);
    bodyJson.query = getDefaultQuery(currentStaff, currentTemplate).query;
    let retval = bodyLns[0] + '\n' + JSON.stringify(bodyJson);
    return retval;
}

function ResultsFilterView({currentClinic, currentUser, record}) {

    const [currentSideView, setCurrentSlideView] = useState(null);
    const [rangeChange, setRangeChange] = useState({});
    const [show, setShow] = useState(false);
    const [selected, setSelected] = useState(null);
    const [currentStaff, setCurrentStaff] = useState(record.staffId);
    const [currentTemplate, setCurrentTemplate] = useState(record.templateId);
    const [initialResults, setInitialResults] = useState(0);

    useEffect(() => {
        if (selected != null) {
            const path = `scheduling/schedules/template?protocol=http&url=${selected.csv_path}`;
            dataService.get(path).then(response => {

                const body = getBody(response);
                if (body) {
                    setShow(true);
                    setCurrentSlideView(<ResultSelectionView style={{height: '100%', width: '50%'}}
                                                             currentClinic={currentClinic.currentClinic}
                                                             key={`schedule_view_${record.template_id}`}
                                                             item={selected}
                                                             schedule={body}
                                                             enabled={true}
                                                             record={record}
                                                            />);
                }
            });
        }

    }, [selected]);
    useEffect(() => {
        setInitialResults(null);
    }, [currentStaff, currentTemplate]);

    function makeResultListHeaders() {
        const items = ScheduleFilterFields.map(ff => {
            const k = `result_list_header_${ff.key}`;
            return <Typography key={k} variant={'subtitle1'} component={'div'}
                               color={'primary'} style={{width: '150px', textAlign: 'center'}}>{ff.title}</Typography>
        });
        return <div
            key={`${currentClinic.currentClinic}_${currentUser}_result_list_header`}
            style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '10px',
                paddingTop: '5px',
                margin: '1px',
                color: ahTheme.palette.primary.main,
                width: '90%',
                border: 'none',
                justifyContent: 'space-evenly',
            }}
        >{items}</div>
    }

    const resultListHeaders = makeResultListHeaders();

    const handleResultListSelection = (evt, entry) => {
        setSelected(entry);
    };


    function makeCardContent(entry) {

        const items = ScheduleFilterFields.map((ff, idx) => {
            let value = ff.roundFn(entry[ff.dataField]);
            return <Typography key={`${entry.template_id}_card_result_value_${idx}`} component={'span'}
                               variant={'button'} color={'primary'}>{value}</Typography>;
        });
        const data = entry;
        items.unshift(selected && selected.template_id === entry.template_id ? <IoIosRadioButtonOn key={'radio_0'}/> :
            <IoIosRadioButtonOff key={'radio_0'}/>);
        return <div
            key={`${entry.template_id}_result_list_view_card`}
            data={data}
            style={{
                display: 'flex',
                flexDirection: 'row',
                padding: 0,
                margin: '1px',
                color: '#1E436A',
                width: '90%',
                border: 'none',
                borderBottom: '.5px solid #C9C2C8',
                justifyContent: 'space-between',
            }}
            className="schedule_row"
            onClick={(evt) => {
                handleResultListSelection(evt, data);
            }}
        >{items}</div>
    }

    function handleRangeChange(value, filterField) {
        const start = filterField.roundFn(value[0]);
        const end = filterField.roundFn(value[1]);
        const newValue = start + ' to ' + end;
        setShow(false);
        setRangeChange({...rangeChange, [filterField.key]: newValue});
    }


    const sortByOptions = [];
    ScheduleFilterFields.forEach(ff => {
        return ['asc', 'desc'].forEach(key => {
            const add = key === 'asc' ? ' (ascending)' : ' (descending)';
            sortByOptions.push({
                label: "Sort by " + ff.title + add,
                dataField: ff.dataField,
                sortBy: key
            });
        })
    });

    const endpoint = `${mainHost}scheduling/schedules/clinics/`;

    function getFilterSlider(filterField) {
        return <React.Fragment>
            <div style={{
                fontSize: '10px',
                paddingTop: '20px'
            }}>{filterField.title + ': ' + rangeChange[filterField.key]}</div>
            <DynamicRangeSlider
                dataField={filterField.dataField}
                componentId={filterField.key}
                title={filterField.title}
                snap={false}
                tooltipTrigger={'hover'}
                customQuery={(value) => {return getSliderQuery(currentStaff, currentTemplate, value, filterField)}}
                onValueChange={(value) => {handleRangeChange(value, filterField)}}
                rangeLabels={filterField.rangeDisplayFn ? filterField.rangeDisplayFn : getDefaultRangeDisplayFn(filterField.roundFn)}
            />
        </React.Fragment>;
    }

    return <div style={{
        display: 'flex',
        padding: 0,
        flexDirection: 'column',
    }}>

        <React.Fragment>
            {/*<Typography variant={'h4'} style={{textAlign: 'left'}} color={'primary'}>Results</Typography>*/}
            <ReactiveBase
                app={String(currentClinic.currentClinic)}
                style={{
                    display: 'flex',
                    alignItems: 'stretch',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    width: '100%'
                }}
                type={String(currentStaff)}
                transformRequest={
                    (request) => {
                        if (request.body.includes("range__internal")) {
                            //HACK to deal with the dynamic range sliders not using custom queries on getting their ranges
                            request.body = doCustomQueryHack(request.body, currentStaff, currentTemplate);
                        }
                        return {
                            ...request,
                            headers: {
                                ...request.headers,
                                ...dataService.getHeaders()
                            },
                        };
                    }
                }
                url={endpoint}>
                <div style={{flexDirection: 'row', display: 'flex', maxWidth: 'fit-content'}}>
                    <div style={{flexDirection: 'column', display: 'flex', width: '50%'}}>
                        <main>
                            <RecommendedResults style={{height: '15px'}}
                                                currentClinic={currentClinic.currentClinic}
                                                selected={selected}
                                                setSelected={setSelected}
                                                curStaff={currentStaff}
                                                curTemplate={currentTemplate}
                                                makeHeaders={makeResultListHeaders}/>
                            <hr/>
                            <ReactiveList
                                dataField={'THISISNOTUSED'}
                                componentId='results'
                                title={'Results'}
                                pagination={true}
                                showEndPage={true}
                                sortOptions={sortByOptions}
                                pages={5}
                                stream={false}
                                defaultQuery={() => {
                                    return getDefaultQuery(currentStaff, currentTemplate);
                                }}
                                size={12}
                                style={{width: '100%', padding: '10px'}}
                                react={{
                                    and: ScheduleFilterFields.map(ff => ff.key),
                                }}
                                onData={({resultStats}) => {
                                    setInitialResults((initialResults) => {
                                        if (initialResults) {
                                            return initialResults;
                                        }
                                        return resultStats;
                                    });
                                }}
                                onError={(error) => {
                                    if (error && error.target && error.target.url && error.target.url.startsWith('wss')) {
                                        //stupid websocket error --ignore
                                    } else {
                                        console.error(error);
                                        setInitialResults((initialResults) => {
                                            if (initialResults) {
                                                return initialResults;
                                            }
                                            return {numberOfResults: 0};
                                        });
                                    }
                                }}
                                render={({data}) => {
                                    return !data ? <div>Loading...</div> :
                                        <ResultListWrapper style={{width: '100%', height: '100%'}}>
                                            {resultListHeaders}
                                            <div>
                                                {data.map(item => (
                                                    makeCardContent(item)
                                                ))}
                                            </div>
                                        </ResultListWrapper>
                                }}
                            />

                        </main>
                        <aside class="sliders-holder">
                            {ScheduleFilterFields.filter(ff => ff.dataField !== 'length_of_day_change').map((ff, idx) => {
                                return  <div class={"slider-container"}>
                                            {getFilterSlider(ff)}
                                            <Divider/>
                                        </div>;

                            })}

                        </aside>
                    </div>
                    <Slide direction="left" in={show} mountOnEnter unmountOnExit>
                        <aside style={{maxHeight: '100%', paddingLeft: '50px', width: '50%'}}>
                            {currentSideView}
                        </aside>
                    </Slide>
                </div>
            </ReactiveBase>
        </React.Fragment>
    </div>
}

export default ResultsFilterView;
