let example = [{
    "area_id": 0,
    "is_hidden": false,
    "area_name": "4102",
    "area_display_name": "4102",
    "area_type_id": 0,
    "show_hidden_as": null,
    "area_type_name": "exam_room"

}];

export default class Area {
    jsonObj;

    constructor(jsonObj) {
        this.jsonObj = jsonObj;

        if (!('deactivated' in this.jsonObj)) {
            this.jsonObj.deactivated=false
        }
    }

    get id() {
        return this.jsonObj.area_id;
    }

    get areaId() {
        return this.jsonObj.area_id;
    }

    get areaName() {
        return this.jsonObj.area_name;
    }

    set areaName(val) {
        this.jsonObj['area_name'] = val;
    }

    get assignedToSite() {
        return 'assigned_to_site' in this.jsonObj ? this.jsonObj.assigned_to_site : false;
    }

    set assignedToSite(val) {
        this.jsonObj['assigned_to_site'] = val;
    }

    get areaDisplayName() {
        return this.jsonObj.area_display_name;
    }

    get displayName() {
        return this.jsonObj.area_display_name;
    }

    set areaDisplayName(val) {
        this.jsonObj['area_display_name'] = val;
    }

    get areaTypeId() {
        return this.jsonObj.area_type_id;
    }

    set areaTypeId(val) {
        this.jsonObj.area_type_id = val;
    }

    get isClinical() {
        return this.jsonObj.is_clinical;
    }

    get areaTypeName() {
        return this.jsonObj.area_type_display_name;
    }

    get hidden() {
        return this.jsonObj.is_hidden;
    }

    set hidden(val) {
        this.jsonObj['is_hidden'] = val;
    }

    get deactivated() {
        return this.jsonObj.deactivated
    }

    set deactivated(val) {
        this.jsonObj['deactivated'] = val;
    }

    get externalName() {
        return 'external_name' in this.jsonObj ? this.jsonObj.external_name : false;
    }

    toJson() {
        return this.jsonObj;
    }
}
