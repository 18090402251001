import greenLight from "../../assets/img/green_light.png";
import redLight from "../../assets/img/redlight.png";
import yellowLight from "../../assets/img/yellow_light.png";
import React from "react";
import {getBody} from "../../utils/Utils";
import moment from "moment";

export const getDiff = (type, today, date) => {

    if (!date) return 10;
    const diffInMlls = today - date;
    const minutes = diffInMlls / 60000;

    const diffHrs = (diffInMlls % 86400000) / 3600000; // hours

    switch (type) {
        case 'hour':
            return parseFloat(Math.round(minutes / 60).toFixed(2));
        case 'day':
            return parseFloat(Math.round(minutes / 1440).toFixed(2));
        default:
            return minutes;
    }
};

export const batteryBodyRender = (visited, tableMeta) => {
    let icon = greenLight, alt = 'Green Light';
    if (visited <= 1) {
        icon = redLight;
        alt = 'Red Light'
    } else if (visited <= 3) {
        icon = yellowLight;
        alt = 'Yellow Light'
    }
    return <img key={`status_image_${tableMeta.rowIndex}`} src={icon}
                alt={alt}
                style={{width: '10px', height: '10px'}}/>

};


export const getDate = (dateString, delimeter = '-') => {
    const dateArr = dateString.split(delimeter).map(str => parseInt(str));

    return new Date(dateArr[0], dateArr[1] - 1, dateArr[2])
};

export const processComplianceData = (response, setAvailableDatesData, setCurrentDate) => {
    const list = getBody(response);

    if (list && list.length > 0) {
        const lastDate = getDate(list[list.length - 1]);
        const datesData = {};
        list.forEach(dateString => {
            const d = getDate(dateString);
            const timeString = d.getTime();
            if (moment(d).isAfter(moment(window.env.MINIMUM_DATE))) {
                datesData[timeString] = {date: d, dateString: dateString};
            }
        });
        setAvailableDatesData(datesData);
        setCurrentDate(lastDate);
    } else {
        setAvailableDatesData(null);
        setCurrentDate(null);
    }
};


