import React, {useEffect, useState} from "react";
import {clinicAdminDataService} from "../../../service/DataService";
import {convertToObj, hasPermission, humanize} from "../../../utils/Utils";
import {ROLES} from "ah-auth0";
import Button from "@material-ui/core/Button";
import EquipmentEditor from "./EquipmentEditor";
import Equipment from './EquipmentModel';
import ResourceView from "../ResourceView";

const getColumns = (filterList) => {
    return [
    {
        name: 'name',
        dataType: 'text',
        label: 'Equipment Name'
    },
    {
        name: 'displayName',
        dataType: 'text',
        label: 'Equipment Display Name'
    },
    {
        name: 'type',
        dataType: 'text',
        label: 'Equipment Type',
    },
    {
        name: 'displayType',
        dataType: 'text',
        label: 'Equipment Type Display Name',
    },
    {
        name: 'external_id',
        dataType: 'text',
        label: 'External ID',
    }
]
}

function getWrappedEquipmentEditor(areaTypes) {
    if (false) {
        return null;
    }
    return function WrappedEquipmentEditor({saving, open, inputResource, onCancel, isNew, onSubmit, currentClinic}) {
        return <EquipmentEditor open={open}
                                saving={saving}
                                inputResource={inputResource}
                                isNew={isNew}
                                currentClinic={currentClinic}
                                onCancel={() => {
                               onCancel();
                           }} onSubmit={(updatedEquip) => {
            onSubmit(updatedEquip);
        }}
                                inputAreaTypes={areaTypes}/>;
    };
}


function EquipmentView({currentClinic, auth, user}) {
      const  [refreshTrigger, setRefreshTrigger] = useState(0)


    return <div><ResourceView user={user} auth={auth} refreshTrigger={refreshTrigger} currentClinic={currentClinic}
                              itemPluralName={'equipment'}
                              itemName={'equipment'}
                              itemDisplayNamePlural={'Equipment'}
                              getColumns={getColumns}
                              conflictMessageGenerator={() => 'Equipment with this name already exists, please enter a different name.'}
                              itemDisplayName={'Equipment'}

                              resourceCtor={(...args) => new Equipment(...args)}
                              uploadHeaderList={['name',
                                  'display_name',
                                  'equipment_type',
                                'type_display_name',
                                  'external_id']}
                              additionalButtons={hasPermission(currentClinic, user, ROLES.CUSTOMER_ADMIN)}
                              Editor={getWrappedEquipmentEditor(null)}/>
                </div>;
}

export default EquipmentView;
