import Fade from "@material-ui/core/Fade";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {useEffect, useState} from "react";
import {clinicAdminDataService} from "../../../service/DataService";
import {convertToObj, deepCompare} from "../../../utils/Utils";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import LoadingOverlay from 'react-loading-overlay';
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ConfirmationDialog from "../../../utils/ConfirmationDialog";
import Tooltip from "@material-ui/core/Tooltip";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    fab: {
        margin: theme.spacing(1),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minWidth: '250px'
    },
    rowContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    menu: {
        width: 200,
    }
}));
let initialEnumValues;
export default function EnumValuesDialog({open, onSubmit, onCancel, enumName, nameField, idField, urlName, title}) {
    const [enumValues, setEnumValues] = useState(null),
        [loadingText, setLoadingText] = useState('Loading...'),
        [submitDisabled, setSubmitDisabled] = useState(true),
        [submitConfirmed, setSubmitConfirmed] = useState(false),
        [proposedChanges, setProposedChanges] = useState(null),
        [showConfirmation, setShowConfirmation] = useState(null),
        classes = useStyles();

    useEffect(() => {
        clinicAdminDataService.get(`clinicadmin/${urlName}`).then(response => {
            initialEnumValues = convertToObj(response.data.columns, response.data.rows);
            setEnumValues(JSON.parse(JSON.stringify(initialEnumValues)));
            setLoadingText(null);
        });
    }, []);

    useEffect(() => {
        if (enumValues) {
            let rowsChanged = false;
            let rowsValid = true;
            enumValues.forEach(curr => {
                if (curr.deleted || curr.new){
                    rowsChanged = true;
                }

                if (curr.new && !curr[nameField]){
                    rowsValid = false;
                }
            })

            setSubmitDisabled(!(rowsChanged && rowsValid))
        }
    }, [enumValues]);
    
    const getOnChange = (field, idx) => {
        return (event) => {
            event.persist();
            setEnumValues(at => {
                at[idx][field] = event.target.value;
                return [...at];
            })
        }
    };
    const getOnDelete = (idx, deleted) => {
        return (event) => {
            event.persist();
            setEnumValues(at => {
                if (at[idx].new) {
                    at.splice(idx, 1);
                } else {
                    at[idx]['deleted'] = !deleted;
                }
                return [...at];
            })
        }
    };
    const addEnumValue = () => {
        setEnumValues(at => {
            at.push({[nameField]: '', new: true});
            return [...at];
        });
    };
    useEffect(() => {
        if (proposedChanges && submitConfirmed) {
            const createPromises = proposedChanges.news.map(d => clinicAdminDataService.post(`clinicadmin/${urlName}`, d));
            const changePromises = proposedChanges.changes.map(d => clinicAdminDataService.put(`clinicadmin/${urlName}/${d[idField]}`, d));
            const deletePromises = proposedChanges.deletes.map(d => clinicAdminDataService.delete(`clinicadmin/${urlName}/${d[idField]}`));
            Promise.all([...createPromises, ...changePromises, ...deletePromises]).then(() => {
                clinicAdminDataService.get(`clinicadmin/${urlName}`).then(response => {
                    const newEnumValues = convertToObj(response.data.columns, response.data.rows);
                    setLoadingText(null);
                    onSubmit(newEnumValues);
                });
            });
        }
    }, [proposedChanges, submitConfirmed]);

    const saveChanges = () => {
        setLoadingText('Saving...');
        const news = [], changes = [], deletes = [];
        enumValues.filter(at => at.new).forEach((at) => {
            news.push(at);
        });
        enumValues.filter(at => !at.deleted && !at.new).forEach((at) => {
            delete at['deleted'];
            const oldIdx = initialEnumValues.findIndex((oldAt) => {
                return oldAt[idField] === at[idField];
            });
            if (oldIdx !== -1) {
                if (!deepCompare(initialEnumValues[oldIdx], at)) {
                    changes.push(at);
                }
            }
        });
        enumValues.filter(at => at.deleted).forEach((at) => {
            if (initialEnumValues.findIndex((oldAt) => oldAt[idField] === at[idField]) !== -1) {
                deletes.push(at);
            }
        });

        setSubmitConfirmed(false);
        setProposedChanges({news: news, changes: changes, deletes: deletes});
        if ([...deletes, ...changes, ...news].length === 0) {
            //submitted w/ no changes
            onCancel();
        } else if (deletes.length > 0) {
            setShowConfirmation(true);
        } else {
            setSubmitConfirmed(true);
        }
    };
    return <Dialog
        open={open}
        maxWidth={'sm'}
        TransitionComponent={Transition}
        onClose={onCancel}
        style={{zIndex: 1151}}
    >
        <LoadingOverlay
            active={loadingText !== null}
            spinner
            text={loadingText === null ? '' : loadingText}
        >
            <DialogTitle>{title}</DialogTitle>

            <DialogContent>
                <form className={classes.root} noValidate autoComplete="off">
                    {enumValues && enumValues.map((enumValue, idx) => {
                        return <div key={idx} style={{
                            padding: '10 0 0 0',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}>
                            <FormControlLabel
                                labelPlacement={'start'}
                                control={
                                    <TextField id={`${idx}_name`}
                                               style={{marginLeft: 10}}
                                               disabled={enumValue['deleted']}
                                               onChange={getOnChange(nameField, idx)}
                                               value={enumValue[nameField] ? enumValue[nameField] : ''}/>
                                }
                                label={`${enumName}`}

                            />
                            <Tooltip title={enumValue['deleted'] ? 'Undo Delete' : 'Delete'}>
                                <Fab size={'small'} color={enumValue['deleted'] ? "secondary" : "primary"}
                                     aria-label="remove" className={classes.fab}
                                     onClick={getOnDelete(idx, enumValue['deleted'])}>
                                    <RemoveIcon fontSize={"small"}/>
                                </Fab>
                            </Tooltip>
                        </div>
                    })}

                </form>
                <div className={classes.rowContainer}>
                    <Fab size={'small'} color="primary" aria-label="add" className={classes.fab}
                         onClick={addEnumValue}>
                        <AddIcon fontSize={"small"}/>
                    </Fab>
                    <Typography component='h5'>Add new row</Typography>
                </div>

            </DialogContent>
            <DialogActions>
                <Button onClick={saveChanges} disabled={submitDisabled} color="secondary">Submit</Button>
                <Button onClick={onCancel} color="primary">Cancel</Button>
            </DialogActions>
        </LoadingOverlay>
        {showConfirmation && <ConfirmationDialog title={'Confirm Deletion'}
                                                 text={
                                                     <div>Are you sure you wish to delete the
                                                         following {title.toLowerCase()}:
                                                         <ul>
                                                             {proposedChanges.deletes.map(td => {
                                                                 return <li>{td[nameField]}</li>
                                                             })}
                                                         </ul>
                                                         Any uses of these values will be unset.
                                                     </div>}
                                                 onCancel={() => {
                                                     setShowConfirmation(false);
                                                     setLoadingText(null);
                                                 }}
                                                 onConfirm={() => {
                                                     setShowConfirmation(false);
                                                     setSubmitConfirmed(true);
                                                 }}
                                                 isOpen={showConfirmation}
        />}
    </Dialog>;
}
