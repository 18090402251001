import React, {useEffect, useMemo, useState} from 'react';
import MUIDataTable from "mui-datatables";
import {clinicAdminDataService, schedulingDataService} from "../../../service/DataService";
import {useStaff} from "../../../utils/Hooks";
import {buildTemplateRecords} from "./models/TemplateRecord";
import Drawer from "@material-ui/core/Drawer";
import PreferencesSelector from "./PreferencesSelector";
import "./preferencesSelector.css"
import {getBody} from "../../../utils/Utils";
import {columns} from "./preferencesHomeColumns";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from 'react-spinners/BounceLoader'
import {palette} from "../../../styles/AhMuiTheme";
import '../preferences/fullcalendar/fullCalendar.css'
import Grid from "@material-ui/core/Grid/Grid";


function PreferencesHome(props) {


    const {currentClinic, currentUser} = props;
    const [prefs, setPrefs] = useState(null);
    const [staffLoaded, staff] = useStaff(currentClinic.currentClinic, clinicAdminDataService);
    const [tableRecords, setTableRecords] = useState(null);
    const [currentRecord, setCurrentRecord] = useState(null);
    const [showPreferencesSelector, setShowPreferencesSelector] = useState(false);
    const [slotTypes, setSlotTypes] = useState(null);
    const [statusMap, setStatusMap] = useState(null);


    const ref = React.createRef();

    useEffect(() => {

        const path = `scheduling/schedules/clinics/${currentClinic.currentClinic}/slot_types`;
        schedulingDataService.get(path).then(response => {

            const body = getBody(response);
            if (body) {
                const st = [];
                console.log("*********PreferencesHome: body=", body);
                body.forEach(entry => {
                    if (entry) {
                        st[entry.slot_type_id] = entry;
                    }

                });
                setSlotTypes(st);
            }
        })

    }, [currentClinic]);


    useEffect(() => {

        let path = `scheduling/preferences/clinics/${currentClinic.currentClinic}`;
        schedulingDataService.get(path).then(response => {
            const body = getBody(response);
            if (body) {
                console.log("*********PreferencesHome: prefs=", body);
                setPrefs(body);
            }
        });
    }, [currentClinic]);

    useEffect(() => {

        let path = `scheduling/preferences/clinics/${currentClinic.currentClinic}/status_names`;
        schedulingDataService.get(path).then(response => {
            const body = getBody(response);
            if (body) {
                console.log("*********PreferencesHome: statusmap=", body);
                setStatusMap(body);

            }
        });
    }, [currentClinic]);

    useMemo(() => {
        if (staffLoaded && prefs && slotTypes && statusMap && !tableRecords) {
            const tr = buildTemplateRecords(prefs, slotTypes, currentClinic, staff, statusMap);
            setTableRecords(tr);
        }

    }, [prefs, staffLoaded, slotTypes, statusMap]);

    const setCurrentRecordStatus = ((key, updated_status) => {
        //updates the status of the template by key, needs to rebuild the objects to get a rerender
        let new_tr = buildTemplateRecords(prefs, slotTypes, currentClinic, staff, statusMap).map((item) => {
            if (item._key === key) {
                item.status = updated_status;
            }
            return item;
        });
        setTableRecords(new_tr);
    })
    const onRowClick = (rowData) => {

        setCurrentRecord(rowData[0]);
        setShowPreferencesSelector(true);
    };

    const exit = (key, updated_status) => {
        setCurrentRecordStatus(key, updated_status);
        setShowPreferencesSelector(false);
    };


    return <Grid component={"div"}>

        <LoadingOverlay
            active={!tableRecords}
            spinner={<BounceLoader color={palette.secondary.main}/>}
            text={'Loading records...'}
        >

            {tableRecords && <MUIDataTable
                data={tableRecords}
                columns={columns}
                expandableRows={true}
                options={{
                    selectableRows: 'none',
                    onRowClick: onRowClick,
                    print: false,
                    search: {top: 0, left: 0},
                    rowsPerPageOptions: [10, 20, 50],
                    rowsPerPage: 50,
                    download: false,

                    checkboxInline: false,
                    displayRowCheckbox: false,
                    selectedRows: {
                        text: "row(s) selected",
                        delete: "Delete",
                        deleteAria: "Delete Selected Rows",
                    }
                }}
            />}

            <Drawer ref={ref} anchor="right" open={showPreferencesSelector}
                    className={'preferences-drawer'}
                    onClose={() => {
                        exit(currentRecord._key, currentRecord.status)
                    }}
            >
                {currentRecord &&
                <PreferencesSelector key={`pref-home-pref-temp-${currentClinic.currentClinic}_${currentUser.userId}`}
                                     record={currentRecord} callback={exit}
                                     currentUser={currentUser}
                                     slotTypes={slotTypes}
                                     currentClinic={currentClinic}/>}
            </Drawer>


        </LoadingOverlay>
    </Grid>

}

export default PreferencesHome;
