import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types"
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import './multiEditor.css'
import '../../../scheduling/transitions.css'
import '../fullcalendar/events.css'
import '../fullcalendar/fullCalendarOverrides.css'
import CSSTransition from "react-transition-group/CSSTransition";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

import {getNewDefaultDate, getRange, updateSlotOnMove} from "./multiEditorUtils";
import SlotEditor from "./slot-editor/SlotEditor";
import TemplateRecord from "../models/TemplateRecord";
import MultiEditorRightPanel from "./MultiEditorRightPanel";
import {SlotEvent} from "../fullcalendar/model/SlotEvent";
import {ADDITIONAL_SLOT_TYPES} from "../fullcalendar/model/ExtendedProps";
import {multiEditorEventRenderer} from "../fullcalendar/EventRendering";


function MultiEditor({record, saveCallback}) {


    const ref = React.createRef();

    const [events, setEvents] = useState(record.events);
    const [current, setCurrent] = useState({showSchedule: true, event: null, showSlotEditor: false});
    const [range, setRange] = useState(getRange(record.events[0].start, record.events[record.events.length - 1].end, 1));

    const _id = `${record.raw.template_id}-`;

    useEffect(() => {
        saveCallback();

    }, [events]);


    const reRange = (filterId, addEvent, isNew) => {
        let evts = events.map(t => t);
        if (filterId && !isNew) {
            evts = evts.filter(t => t.id !== filterId);
        }
        if (addEvent) {
            evts.push(addEvent)
        }

        evts.sort(function (a, b) {
            return a.start - b.start
        });

        setRange(getRange(evts[0].start, evts[evts.length - 1].end, 1));
        setEvents(evts);

    };


    const onSlotEdited = (dynamicInputModel, isDelete = false) => {

        const slot = dynamicInputModel[0].parent;
        const evt = new SlotEvent(slot, null, slot.slot_id, slot, null, null);

        const add = !isDelete ? evt : null;
        if (isDelete) {
            record.preferences.deletedSlots.push(slot);
        }

        reRange(slot.slot_id, add, current.isNew);
        setCurrent({showSchedule: true, event: null, showSlotEditor: false})


    };


    const moveEvent = ({event, jsEvent, view}) => {

        const {original, revised} = event.extendedProps;
        updateSlotOnMove(event.start, event.end, revised);
        const evt = new SlotEvent(original, null, event.id, revised, TemplateRecord.slotTypes);
        reRange(event.id, evt, false);
        setCurrent({showSchedule: true, event: null, showSlotEditor: false})

    };


    const showModal = (event, force) => {
        setCurrent({showSchedule: false, event: event, showSlotEditor: true})
    };


    const addNewEvent = (slot) => {

        slot.slot_type_id = ADDITIONAL_SLOT_TYPES.break;
        const {label, style} = ADDITIONAL_SLOT_TYPES[slot.slot_type_id];
        const newBreak = new SlotEvent(slot, style, slot.slot_id, null, label, TemplateRecord.slotTypes);
        if (newBreak.start.getDay() !== 1 || newBreak.end.getDay() !== 1) {
            console.error(`event should only be on day 1 when here is it ${newBreak.start.getDay()}, ${newBreak.end.getDay()}`)
        }
        setCurrent({showSchedule: false, event: newBreak, showSlotEditor: true, isNew: true});


    };


    return <div>
        <h2>Template Editor</h2>
        <div className={'multi-editor'}>

            {current.showSlotEditor && <div>
                <CSSTransition
                    key={`${_id}-${current.event.id}-multi-editor`}
                    in={current.showSlotEditor}
                    timeout={1500}
                    classNames="fade"
                >
                    <SlotEditor className={'slot-editor-modal'}
                                id={`${_id}-${current.event.id}-multi-editor-metadata-editor`}
                                key={`${_id}-${current.event.id}-metadata-editor`}
                                currentRecord={record}
                                currentEvent={current.event}
                                onSubmit={onSlotEdited}/>
                </CSSTransition>

            </div>}
            <div className={'multi-editor-left'}>
                <div>
                    <FullCalendar
                        key={`eod-selector-calendar`}
                        ref={ref}
                        height={'100%'}
                        defaultDate={getNewDefaultDate()}
                        scrollTime={range.start}
                        eventDrop={moveEvent}
                        slotEventOverlap={false}
                        slotDuration={'00:05:00'}
                        columnHeader={null}
                        allDayText={''}
                        minTime={range.sTimeString}
                        maxTime={range.eTimeString}
                        label={'Editable Schedule'}
                        defaultView="timeGridDay"
                        header={false}
                        title={null}
                        allday={false}
                        plugins={[timeGridPlugin, interactionPlugin]}
                        events={(info, successCallback, failureCallback) => {

                            successCallback(events);
                            failureCallback('failed');
                        }}
                        editable={!current.showSchedule}
                        eventRender={(props) => multiEditorEventRenderer(props, showModal)}
                    />

                </div>


            </div>


            <MultiEditorRightPanel
                currentRecord={record}
                events={events}
                addEventHook={addNewEvent}/>

        </div>
    </div>
}

export default MultiEditor;

MultiEditor.propTypes = {
    record: PropTypes.instanceOf(TemplateRecord)
};
