import {copySlot, getDate, getEndDate} from "../../multi-editor/multiEditorUtils";
import {ExtendedProps} from "./ExtendedProps";
import TemplateRecord from "../../models/TemplateRecord";


export class SlotEvent {
    id;
    groupId;
    allDay = false;
    start;
    end;
    title;
    classNames;
    editable = true;
    startEditable = true;
    durationEditable = true;
    resourceEditable = true;
    rendering;
    overlap;
    constraint;
    backgroundColor;
    borderColor = 'none';
    textColor;
    extendedProps;
    source;
    view;


    constructor(slot, classNames, id, revised, label, slotTypes) {


        if (!revised) {
            this.revised = revised = copySlot(slot)
        }
        this.id = id;
        this.start = getDate(revised.start_time);
        const slotTypeId = revised.slot_type_id;
        if (this.start.getDate() !== 1) {
            console.error("start", this.start);
            this.start.setHours(23);
            this.start.setMinutes(59);
            this.start.setDate(1);
            console.error("fixed start", this.start);
        }
        if (slotTypeId > 902) {
            this.editable = false;
        }

        this.end = getEndDate(this.start, revised ? revised.duration : slot.duration);
        if (this.end.getDate() !== 1) {
            console.error(this.end);
            this.end.setHours(23);
            this.end.setMinutes(59);
            this.end.setDate(1);
            console.error(this.end);
        }
        this.start.setDate(1);

        this.extendedProps = new ExtendedProps(slot, revised, TemplateRecord.slotTypes);
        this.classNames = this.extendedProps.getClassNames();
        this.title = this.extendedProps.getTitle(this);


    }


}


