import {Typography} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React, {useEffect, useState} from "react";
import {clinicAdminDataService} from "../../service/DataService";
import {convertToObj} from "../../utils/Utils";
import {useStaff} from "../../utils/Hooks";
import moment from "moment";
import Button from "@material-ui/core/Button";

const ALERT_MESSAGES = {
    'missing beacon': "Badge has not been seen."
};

const sharedColumns = [
    {
        label: 'Badge Code',
        name: 'beaconCode',
        key: 'beaconCode',
    },
    {
        label: 'Message',
        name: 'alertMessage',
        key: 'alertMessage',
    },
    {
        label: 'Alert Date',
        name: 'alertDate',
        key: 'alertDate',
        options: {
            customBodyRender: function (val) {
                return moment(val).format('YYYY-MM-DD');
            }
        }
    }
];

const getDismissColumn = (dismissFn, type) => {
    return {
        label: 'Dismiss Alert',
        name: 'dismiss',
        key: 'dismiss',
        options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta) => {
                return (
                    <Button onClick={() => {
                        dismissFn(type, tableMeta.rowIndex)
                    }}>
                        Dismiss Alert
                    </Button>
                );
            }
        }
    };
};


const getStaffColumns = (dismissFn) => {
    let columns = [...sharedColumns];
    columns.splice(2, 0, {
        label: 'Staff Member',
        name: 'staffName',
        key: 'staffName',
    });
    if (dismissFn) {
        columns.push(getDismissColumn(dismissFn, 'staff'));
    }
    return columns;
};
const getPatientColumns = (dismissFn) => {
    let columns = [...sharedColumns];
    columns.splice(2, 0, ...[
        {
            label: 'Appointment Time',
            name: 'apptTime',
            key: 'apptTime',
            options: {
                customBodyRender: function (val) {
                    return moment(val).format('YYYY-MM-DD h:mm A');
                }
            }
        },
        {
            label: 'Provider',
            name: 'providerName',
            key: 'providerName',
        }]);
    if (dismissFn) {
        columns.push(getDismissColumn(dismissFn, 'patient'));
    }
    return columns;
};

class BeaconAlert {
    jsonObj;
    staffMap;
    staff;
    provider;

    constructor(jsonObj, staff) {
        this.jsonObj = jsonObj;
        this.staffMap = staff;
    }

    get isStaff() {
        return this.jsonObj.staff_id !== undefined && this.jsonObj.staff_id !== null;
    }

    get apptTime() {
        return this.jsonObj.appointment_time_tz;
    }

    get staffId() {
        return this.jsonObj.staff_id;
    }

    get beaconCode() {
        return this.jsonObj.beacon_code;
    }

    get providerId() {
        return this.jsonObj.provider_staff_id;
    }

    get staffName() {
        return this.staffMap[this.staffId].last_name + ", " + this.staffMap[this.staffId].first_name;
    }

    get providerName() {
        return this.staffMap[this.providerId].last_name + ", " + this.staffMap[this.providerId].first_name;
    }

    get alertMessage() {
        return ALERT_MESSAGES[this.jsonObj.alert_type];
    }

    get alertDate() {
        return this.jsonObj.alert_date;
    }

    get alertId() {
        return this.jsonObj.beacon_alert_id;
    }

}

function AlertsView({currentClinic}) {
    const [staffRows, setStaffRows] = useState(null),
        [patientRows, setPatientRows] = useState(null),
        [staffColumns, setStaffColumns] = useState(getStaffColumns()),
        [patientColumns, setPatientColumns] = useState(getPatientColumns()),
        [dismissFn, setDismissFn] = useState(null);

    const [staffLoaded, staff] = useStaff(currentClinic.currentClinic, clinicAdminDataService);

    useEffect(() => {
        if (staffLoaded) {
            clinicAdminDataService.get(`clinicadmin/clinics/${currentClinic.currentClinic}/beacons/alerts`).then(
                (response) => {
                    if (response && response.data && response.data.columns) {
                        const objs = convertToObj(response.data.columns, response.data.rows);
                        const alerts = objs.map(d => new BeaconAlert(d, staff));
                        setStaffRows(alerts.filter(a => a.isStaff));
                        setPatientRows(alerts.filter(a => !a.isStaff));
                    }
                }
            );
        }
    }, [staffLoaded, currentClinic.currentClinic]);

    const dismissRow = (index, rows, setter, currentClinic) => {
        clinicAdminDataService.post(`clinicadmin/clinics/${currentClinic}/beacons/alerts/${rows[index].alertId}/dismiss`);
        setter((pr) => {
            pr.splice(index, 1);
            return [...pr];
        });
    };

    useEffect(() => {
        const dismisser = (type, rowIndex) => {
            if (type === 'patient') {
                dismissRow(rowIndex, patientRows, setPatientRows, currentClinic.currentClinic);
            } else {
                dismissRow(rowIndex, staffRows, setStaffRows, currentClinic.currentClinic);
            }
        };
        setDismissFn(() => dismisser);
    }, [currentClinic.currentClinic, patientRows, staffRows]);

    useEffect(() => {
        setStaffColumns(getStaffColumns(dismissFn));
        setPatientColumns(getPatientColumns(dismissFn));
    }, [dismissFn]);

    return <div style={{display: 'flex', flexDirection: 'column', overflowX: 'hidden'}}>

        <div style={{width: '100%', paddingRight: '10px', left: '20px'}}>
            <Typography style={{paddingLeft: '20px'}} variant={'h2'} color={'primary'}>Badge Alerts</Typography>
            <Typography style={{paddingLeft: '20px'}} variant={'h5'} color={'secondary'}>Staff Badge
                Alerts</Typography>
            <MUIDataTable
                style={{width: '100%'}}
                data={staffRows ? staffRows : []}
                selectable={false}
                columns={staffColumns}
                expandableRows={true}
                options={{
                    textLabels: {
                        body: {
                            noMatch: staffRows ? "No alerts" : "Loading..."
                        }
                    },
                    selectableRows: 'none',
                    print: false,
                    sort: true,
                    search: {top: 0, left: 0},
                    download: false,
                    checkboxInline: false,
                    displayRowCheckbox: false,
                }}
            />
            <Typography style={{paddingLeft: '20px'}} variant={'h5'} color={'secondary'}>Patient Badge
                Alerts</Typography>
            <MUIDataTable
                style={{width: '100%'}}
                data={patientRows ? patientRows : []}
                selectable={false}
                columns={patientColumns}
                expandableRows={true}
                options={{
                    textLabels: {
                        body: {
                            noMatch: patientRows ? "No alerts" : "Loading..."
                        }
                    },
                    selectableRows: 'none',
                    print: false,
                    sort: true,
                    search: {top: 0, left: 0},
                    download: false,
                    checkboxInline: false,
                    displayRowCheckbox: false,
                }}
            />
        </div>
    </div>
}

export default AlertsView;
