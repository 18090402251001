import {v4 as uuidv4} from 'uuid';
import {getDate} from "../multiEditorUtils";


export const BUTTON = 'button';
export const CHECKBOX = 'checkbox';
export const COLOR = 'color';
export const DATE = 'date';
export const DATETIME_LOCAL = 'datetime-local';
export const EMAIL = 'email';
export const FILE = 'file';
export const HIDDEN = 'hidden';
export const IMAGE = 'image';
export const MONTH = 'month';
export const NUMBER = 'number';
export const PASSWORD = 'password';
export const RADIO = 'radio';
export const RANGE = 'range';
export const RESET = 'reset';
export const SEARCH = 'search';
export const SUBMIT = 'submit';
export const TEL = 'tel';
export const TEXT = 'text';
export const TIME = 'time';
export const URL = 'url';
export const WEEK = 'week';


export class DynamicInputModel {
    type = TEXT;
    label;
    id = uuidv4();
    key = null;
    value = null;
    rest;
    list;

    constructor(props) {

        Object.keys(props).forEach(key => {
            this[key] = props[key];
        });

        this.name = this.key;
    }

    _date;

    get date() {
        if (!this._date && typeof this.value === 'string' && this.value.length === 8) {
            this._date = getDate(this.value);
        }
        return this._date ? this._date.toLocaleTimeString().replace(":00", "") : null;
    }

}

export const getDynamicInputModel = (type, label, key, value, ...rest) => {
    const model = {
        type: type,
        label: label,
        key: key,
        value: value,
        ...rest
    };
    return new DynamicInputModel(model);
};

