import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React, {useEffect, useState} from "react";
import {Fade} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Staff from "./StaffModel";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {EMAIL_REGEX} from "../../users/UserEditor";
import LoadingOverlay from "react-loading-overlay";
import Area from "../areas/AreaModel";
import DeleteIcon from "@material-ui/icons/Delete";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade direction="down" ref={ref} {...props} />;
});
const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1)
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }
}));

const isComplete = (staff) => {
    return staff.staffLastName && staff.staffRoleId && (!staff.email || staff.email === '' || EMAIL_REGEX.test(staff.email));
};


export default function StaffEditor({saving, isNew, open, onCancel, onSubmit, onDelete, inputStaff, staffRoles, specialties}) {
    const [staff, setStaff] = useState(inputStaff),
        [emailIsError, setEmailIsError] = useState(false),
        [submitDisabled, setSubmitDisabled] = useState(!isComplete(staff)),
        classes = useStyles();

    const getOnChange = field => event => {
        event.persist();
        setStaff(staff => {
            if (event.target.id === 'schedule-template-checkbox' || event.target.id === 'deactivatedCheckbox') {
                staff[field] = event.target.checked;
            } else {
                staff[field] = event.target.value;
            }
            return new Staff(staff.toJson());
        });
    };

    useEffect(() => {
        setSubmitDisabled(!isComplete(staff));
        setEmailIsError(staff.email && staff.email !== '' && !EMAIL_REGEX.test(staff.email));
    }, [staff]);

    return <Dialog fullWidth={true}
                   open={open}
                   maxWidth={'sm'}
                   TransitionComponent={Transition}
                   onClose={onCancel}
                   style={{zIndex: 1150}}
    ><LoadingOverlay
        active={saving}
        spinner
        text={'Saving...'}>
        <DialogTitle>{!staff.staffName && isNew ? 'New Staff Member' : staff.staffName}</DialogTitle>
        <DialogContent>
            <form className={classes.root} noValidate autoComplete="off">
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <TextField id="staff-id" label="ID"
                               disabled
                               value={isNew ? 'New Staff Member' : staff.staffId}/>
                    <div style={{display: 'flex', flexDirection: "row"}}>
                        <TextField id="staff-name" label="First Name"
                                   onChange={getOnChange('staffFirstName')}
                                   value={staff.staffFirstName ? staff.staffFirstName : ''}/>
                        <TextField id="staff-display-name" label="Last Name (required)"
                                   onChange={getOnChange('staffLastName')}
                                   value={staff.staffLastName ? staff.staffLastName : ''}/>
                    </div>
                    <TextField id="staff-external-id" label="Email Address"
                               onChange={getOnChange('email')}
                               error={emailIsError}
                               value={staff.email ? staff.email : ''}/>
                    <TextField id="staff-provider-id" label="Provider ID"
                               onChange={getOnChange('externalId')}
                               value={staff.externalId ? staff.externalId : ''}/>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="schedule-template-checkbox"
                                checked={staff.hasScheduleTemplate}
                                onChange={getOnChange('hasScheduleTemplate')}
                                value={staff.hasScheduleTemplate}
                                color="primary"
                            />
                        }
                        label="Has Schedule Template"
                    />

                    <FormControl className={classes.formControl}>
                        <InputLabel id="staff-role-label">Role (required)</InputLabel>
                        <Select
                            labelId="staff-role-label"
                            id="staff-role"
                            required
                            value={staff.staffRoleId}
                            onChange={getOnChange('staffRoleId')}
                        >
                            {staffRoles.map(at => {
                                return <MenuItem value={at.role_id}>{at.role_name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel id="staff-specialty-label">Specialty</InputLabel>
                        <Select
                            labelId="staff-specialty-label"
                            id="staff-specialty"
                            required
                            value={staff.specialtyId}
                            onChange={getOnChange('specialtyId')}
                        >
                            {specialties.map(at => {
                                return <MenuItem value={at.specialty_id}>{at.specialty}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        control={<Checkbox checked={staff.deactivated}
                                           onChange={getOnChange('deactivated')}
                                           value={staff.deactivated}

                                           id="deactivatedCheckbox" name="deactivatedToggle"/>}
                        label="Deactivated"
                    />
                </div>
            </form>

        </DialogContent>
        <DialogActions>
            <Tooltip title={submitDisabled ? 'Please enter at least a last name and staff role' : null}>
                <span>
                    <Button onClick={() => {
                        onSubmit(staff)
                    }} disabled={submitDisabled} color="secondary">Submit</Button>
                </span>
            </Tooltip>
            <Button onClick={onCancel} color="primary">Cancel</Button>
            <Button onClick={() => {
                onDelete(staff)
            }} style={{color: "#F86041"}} startIcon={<DeleteIcon/>}>Delete</Button>
        </DialogActions>
    </LoadingOverlay>
    </Dialog>;

}
