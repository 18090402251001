import React, {useEffect, useState} from "react";
import {clinicAdminDataService} from "../../../service/DataService";
import {convertToObj, hasPermission, humanize} from "../../../utils/Utils";
import {ROLES} from "ah-auth0";
import Button from "@material-ui/core/Button";
import StaffEditor from "./StaffEditor";
import Staff from './StaffModel';
import ResourceView from "../ResourceView";
import EnumValuesDialog from "./EnumValuesDialog";

const conflictFieldMap = {
    'email': 'email',
    'external_id': 'provider ID'
};


export const defaultStaffFilterList = [[], [], [], [], [], [], ['No']];

// REFERENCE IMPLEMENTATION OF custom filter chip
const setFilterChipProps =  (colIndex, colName, data) => {
    if (colName == 'deactivated') {
        if (data === 'No') {
            return {
                className: 'positive_chip'
            };
        } else if (data==='Yes') {
            return {
                className: 'negative_chip'
            };
        }

    }

}

const getColumns = (filterList) => {
    return [
        {
            name: 'staffName',
            dataType: 'text',
            label: 'Staff Name',
            options: {
                filterList: filterList[0],
            }
        },
        {
            name: 'role',
            dataType: 'text',
            label: 'Role Name',
            options: {
                filterList: filterList[1],
            }
        },
        {
            name: 'externalId',
            dataType: 'text',
            label: 'Provider ID',
            options: {
                filterList: filterList[2],
            }
        },
        {
            name: 'hasScheduleTemplate',
            dataType: 'enum',
            options: {
                filterList: filterList[3],
                customBodyRender: (value) => humanize(value === undefined || value === null ? "" : value ? 'Yes' : 'No')
            },
            enumData: ['Yes', 'No'],
            label: 'Has Schedule Template?'
        },
        {
            name: 'specialty',
            dataType: 'text',
            label: 'Specialty',
            options: {
                filterList: filterList[4],
            }
        },
        {
            name: 'email',
            dataType: 'text',
            label: 'Email',
            options: {
                filterList: filterList[5],
            }
        },
        {
            name: 'deactivated',
            label: 'Deactivated',
            options: {
                display: 'false',
                filterList: filterList[6],
                filter: true,
                customFilterListOptions: {
                    render: v => v==='Yes' ? 'Deactivated Only' : 'Active Only'
                },
                customBodyRender: v=>((v === undefined || v === null) ? "Yes" : v ? 'Yes' : 'No')
                }


        }
    ];
}

function getWrappedStaffEditor(staffRoles, specialties) {
    if (!staffRoles || !specialties) {
        return null;
    }
    return function getWrappedStaffEditor({saving, open, inputResource, onCancel, isNew, onSubmit, onDelete, currentClinic}) {
        return <StaffEditor open={open}
                            saving={saving}
                            specialties={specialties}
                            currentClinic={currentClinic}
                            inputStaff={inputResource}
                            isNew={isNew}
                            onCancel={() => {
                                onCancel();
                            }}
                            onSubmit={(updatedStaff) => {
                                onSubmit(updatedStaff);
                            }}
                            onDelete={() => {
                                onDelete()}
                            }
                            staffRoles={staffRoles}/>;
    };
}

const toDict = (objs, idField) => {
    return objs.reduce((prev, curr) => {
        prev[curr[idField]] = curr;
        return prev;
    }, {})
};

function StaffView({currentClinic, auth, user}) {
    const [staffRoles, setStaffRoles] = useState(null),
        [specialties, setSpecialties] = useState(null),
        [refreshTrigger, setRefreshTrigger] = useState(0),
        [showSpecialtiesDialog, setShowSpecialtiesDialog] = useState(false),
        [showStaffRolesDialog, setShowStaffRolesDialog] = useState(false);

    let getStaffRoles = () => {
        setStaffRoles(null);
        setShowStaffRolesDialog(false);
        setRefreshTrigger(refreshTrigger => refreshTrigger + 1);
        clinicAdminDataService.get(`clinicadmin/staff/roles`).then(response => {
            if (response.data) {
                setStaffRoles(convertToObj(response.data.columns, response.data.rows));
            }
        });
    };
    let getSpecialties = () => {
        setSpecialties(null);
        setShowSpecialtiesDialog(false);
        setRefreshTrigger(refreshTrigger => refreshTrigger + 1);
        clinicAdminDataService.get(`clinicadmin/staff/specialties`).then(response => {
            if (response.data) {
                setSpecialties(convertToObj(response.data.columns, response.data.rows));
            }
        })
    };
    useEffect(() => {
        getStaffRoles();
        getSpecialties();
    }, []);

    const updateStaffRoles = () => {
        setShowStaffRolesDialog(false);
        getStaffRoles();
    };

    const getConflictMessage = (data) => {
        return `A staff member with this ${conflictFieldMap[data.field]} already exists. Please enter a different value.`;
    };

    const updateSpecialties = () => {
        setShowSpecialtiesDialog(false);
        getSpecialties();
    };

    return <div>{<ResourceView user={user} auth={auth} refreshTrigger={refreshTrigger} currentClinic={currentClinic}
                               getColumns={getColumns}
                               setFilterChipProps={setFilterChipProps}
                               defaultFilterList={defaultStaffFilterList}
                               itemPluralName={'staff'}
                               itemName={'staff'}
                               itemDisplayName={'Staff'}
                               resourceCtor={(obj) => new Staff(obj)}
                               conflictMessageGenerator={getConflictMessage}
                               uploadHeaderList={['first_name',
                                   'last_name',
                                   'role_name',
                                   'has_schedule_template']}
                               uploadOptionalList={['permission',
                                   'provider_id',
                                   'email',
                                   'specialty']}
                               additionalButtons={hasPermission(currentClinic, user, ROLES.CUSTOMER_ADMIN) &&
                               <span><Button
                                   disabled={!staffRoles}
                                   variant="contained" onClick={() => setShowStaffRolesDialog(true)}>Edit Staff
                                  Roles</Button>
                                  <Button disabled={!specialties} variant="contained"
                                          onClick={() => setShowSpecialtiesDialog(true)}>Edit Staff
                                  Specialties</Button></span>}
                               additionalUploadNotes={<p>Any values for role or specialty must match exactly those
                                   values
                                   set up for this deployment.</p>}
                               Editor={getWrappedStaffEditor(staffRoles, specialties)}/>}
        {hasPermission(currentClinic, user, ROLES.CUSTOMER_ADMIN) && showStaffRolesDialog &&
        <EnumValuesDialog open={showStaffRolesDialog} onSubmit={updateStaffRoles}
                          nameField={'role_name'}
                          idField={'role_id'}
                          title={'Staff Roles'}
                          urlName={'staff/roles'}
                          enumName={'Role Name'}
                          onCancel={() => setShowStaffRolesDialog(false)}/>}
        {hasPermission(currentClinic, user, ROLES.CUSTOMER_ADMIN) && showSpecialtiesDialog &&
        <EnumValuesDialog open={showSpecialtiesDialog} onSubmit={updateSpecialties}
                          nameField={'specialty'}
                          idField={'specialty_id'}
                          title={'Specialties'}
                          urlName={'staff/specialties'}
                          enumName={'Specialty'}
                          onCancel={() => setShowSpecialtiesDialog(false)}/>}
    </div>;
}

export default StaffView;
