import React, {useEffect, useState} from "react";
import {clinicAdminDataService} from "../../../service/DataService";
import {convertToObj, hasPermission, humanize} from "../../../utils/Utils";
import {ROLES} from "ah-auth0";
import Button from "@material-ui/core/Button";
import AreaEditor from "./AreaEditor";
import Area from './AreaModel';
import AreaTypesDialog from "./AreaTypesDialog";
import ResourceView from "../ResourceView";
import {ADMIN_MODES} from "../../../utils/GlobalProps";

export const areaTypeColumns = ["area_type_id",
    "area_type_name",
    "area_type_display_name",
    "area_type_description",
    "is_clinical", "editable"];

export const defaultAreaFilterList = [[], [], [], [], [], ['No']];

const getColumns = (filterList) => {
    return [
    {
        name: 'areaName',
        dataType: 'text',
        label: 'Space Name',
        options: {
            filterList: filterList[0],
        }
    },
    {
        name: 'areaDisplayName',
        dataType: 'text',
        label: 'Space Display Name',
        options: {
            filterList: filterList[1],
        }
    },
    {
        name: 'areaTypeName',
        dataType: 'text',
        label: 'Space Type Name',
        options: {
            filterList: filterList[2],
        }
    },
    {
        name: 'isClinical',
        dataType: 'enum',
        options: {
            filterList: filterList[3],
            customBodyRender: (value) => humanize(value === null ? "" : value.toString())
        },
        enumData: ['true', 'false'],
        label: 'Is Clinically Relevant?',
    },
    {
        name: 'deactivated',
        label: 'Deactivated',
        options: {
            display: 'false',
            filterList: filterList[5],
            filter: true,
            filterOptions:{
                names: ['Yes', 'No']
            },
            customFilterListOptions: {
                render: v => v==='Yes' ? 'Deactivated Only' : 'Active Only'
            },
            customBodyRender: v=>((v === undefined || v === null) ? "Yes" : v ? 'Yes' : 'No')
        }

    },
];
}

function getWrappedAreaEditor(areaTypes) {
    if (!areaTypes) {
        return null;
    }
    return function WrappedAreaEditor({saving, open, inputResource, onCancel, isNew, onSubmit, onDelete, currentClinic}) {
        return <AreaEditor open={open}
                           saving={saving}
                           inputArea={inputResource}
                           isNew={isNew}
                           currentClinic={currentClinic}
                           onCancel={() => {
                               onCancel();
                           }}
                           onSubmit={(updatedArea) => {
                                onSubmit(updatedArea);
                            }}
                           onDelete={() => {
                                onDelete()}
                           }
                           inputAreaTypes={areaTypes}/>;
    };
}


function AreasView({currentClinic, auth, user}) {
    const [areaTypes, setAreaTypes] = useState(null),
        [refreshTrigger, setRefreshTrigger] = useState(0),
        [showAreaTypesDialog, setShowAreaTypesDialog] = useState(false);

    let getAreaTypes = () => {
        setAreaTypes(null);
        setRefreshTrigger(refreshTrigger => refreshTrigger + 1);
        clinicAdminDataService.get('clinicadmin/areas/types').then(response => {
            if (response.data) {
                setAreaTypes(convertToObj(response.data.columns, response.data.rows));
            }
        })
    };

    useEffect(() => {
        getAreaTypes();
    }, [currentClinic]);

    const updateAreaTypes = () => {
        setShowAreaTypesDialog(false);
        getAreaTypes();
    };

    return <div>
        <ResourceView user={user} auth={auth} refreshTrigger={refreshTrigger}
                            currentClinic={currentClinic}
                              mode={ADMIN_MODES.REALM}
                              getColumns={getColumns}
                              defaultFilterList={defaultAreaFilterList}
                              itemDisplayNamePlural={'Spaces'}
                              conflictMessageGenerator={() => 'A space with this name already exists, please enter a different name.'}
                              itemDisplayName={'Space'}
                              itemName={'area'}
                              resourceCtor={(...args) => new Area(...args)}
                              uploadHeaderList={['space_name',
                                  'space_display_name',
                                  'space_type_name']}
                              additionalButtons={hasPermission(currentClinic, user, ROLES.CUSTOMER_ADMIN) &&
                              <Button disabled={!areaTypes} variant="contained"
                                      onClick={() => setShowAreaTypesDialog(true)}>Edit Space Types</Button>}
                              additionalUploadNotes={<p>Any values for space_type_name must match exactly the Space Type
                                  Names
                                  set up for this site.</p>}
                              Editor={getWrappedAreaEditor(areaTypes)}/>
        {hasPermission(currentClinic, user, ROLES.CUSTOMER_ADMIN) && showAreaTypesDialog &&
        <AreaTypesDialog open={showAreaTypesDialog} onSubmit={updateAreaTypes}
                         onCancel={() => setShowAreaTypesDialog(false)}/>}</div>;

}

export default AreasView;
