import React, {Fragment, useEffect, useState} from "react";
import {clinicAdminDataService} from "../../service/DataService";
import LoadingOverlay from "react-loading-overlay";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import {Button, TextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TIMEZONES from "../../utils/timezones";
import './clinicview.css';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
    },
    inputLabelFormControl: {
        top: -10
    },
    timePicker: {
        margin: theme.spacing(1)
    },
    section: {
        margin: theme.spacing(1)
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row'
    }

}));

const timezoneDict = TIMEZONES.reduce((prev, curr) => {
    prev[curr.name] = curr;
    return prev;
}, {});

class Clinic {
    constructor(jsonObj) {
        this.jsonObj = jsonObj;
    }

    get workflowPatientTimeout() {
        if (this.jsonObj.options && this.jsonObj.options.workflow) {
            return this.jsonObj.options.workflow.idle_patient_limit;
        }
        return null;
    }

    set workflowPatientTimeout(val) {
        if (!this.jsonObj.options) {
            this.jsonObj.options = {workflow: {idle_patient_limit: val}};
        } else if (!this.jsonObj.options.workflow) {
            this.jsonObj.options.workflow = {idle_patient_limit: val};
        } else {
            this.jsonObj.options.workflow.idle_patient_limit = val;
        }
    }

    get specialty() {
        return this.jsonObj.specialty;
    }

    set specialty(val) {
        this.jsonObj.specialty = val;
    }

    get name() {
        return this.jsonObj.site_name;
    }

    set name(val) {
        this.jsonObj.site_name = val;
    }

    get description() {
        return this.jsonObj.site_description;
    }

    set description(val) {
        this.jsonObj.site_description = val;
    }

    get startTime() {
        const timeArr = this.jsonObj.site_start ? this.jsonObj.site_start.split(':') : [0, 0];
        const myTime = new Date();
        myTime.setHours(Number.parseInt(timeArr[0]));
        myTime.setMinutes(Number.parseInt(timeArr[1]));
        return myTime;
    }

    set startTime(val) {
        this.jsonObj.site_start = this.toTime(val)
    }

    get endTime() {
        const timeArr = this.jsonObj.site_end ? this.jsonObj.site_end.split(':') : [23, 59];
        const myTime = new Date();
        myTime.setHours(Number.parseInt(timeArr[0]));
        myTime.setMinutes(Number.parseInt(timeArr[1]));
        return myTime;
    }

    set endTime(val) {
        this.jsonObj.site_end = this.toTime(val);
    }

    get customerId() {
        return this.jsonObj.external_clinic_id;
    }

    set customerId(val) {
        this.jsonObj.external_clinic_id = val;
    }

    get timezone() {
        return timezoneDict[this.jsonObj.timezone];
    }

    set timezone(val) {
        this.jsonObj.timezone = val;
    }

    toTime(val) {
        if (val) {
            const minutes = String(val.getMinutes()).padStart(2, '0');
            const hours = String(val.getHours()).padStart(2, '0');
            return hours + ':' + minutes;
        }
        return null;
    }

    toJson = () => {
        return this.jsonObj;
    }
}

export default function ClinicView({currentClinic}) {
    const [clinic, setClinic] = useState(null),
        classes = useStyles(),
        [submitDisabled, setSubmitDisabled] = useState(true),
        [loadingText, setLoadingText] = useState('Loading...'),
        [demoStatus, setDemoStatus] = useState(null);

    const getClinic = () => {
        setLoadingText('Loading...');
        setClinic(null);
        clinicAdminDataService.get(`clinicadmin/clinics/${currentClinic}`).then((response) => {
            setClinic(new Clinic(response.data));
            setLoadingText(null);
        })
    };
    useEffect(() => {
        getClinic();

        if (process.env.REACT_APP_IS_DEMO) {
            getDemoSimulationStatus();
        }
    }, [currentClinic]);

    const saveClinic = () => {
        setLoadingText('Saving...');
        setClinic(null);
        clinicAdminDataService.patch(`clinicadmin/clinics/${currentClinic}`, clinic.toJson()).then((response) => {
            setClinic(new Clinic(response.data));
            setLoadingText(null);
        })
    };

    const getDemoSimulationStatus = () => {
        clinicAdminDataService.get(`clinicadmin/clinics/demo_simulation/status`)
            .then((response) => {
                setDemoStatus(response.data)
            });
    };

    const manageDemoSimulation = () => {
        let action = demoStatus === 'active' ? 'stop' : 'start';

        clinicAdminDataService.post(`clinicadmin/clinics/demo_simulation/${action}`)
            .then(() => {
                setDemoStatus((oldStatus) => {
                    return oldStatus == 'active' ? 'inactive' : 'active'
                });
            });
    };

    const revertClinic = () => {
        getClinic();
    };

    const getOnChange = field => event => {
        setSubmitDisabled(false);
        event.persist();
        setClinic(clinic => {
            clinic[field] = event.target.id.includes('checkbox') ? event.target.checked : event.target.value;
            return new Clinic(clinic.toJson());
        });
    };

    const getOnChangeDateTime = field => newTime => {
        setSubmitDisabled(false);
        setClinic(clinic => {
            clinic[field] = newTime;
            return new Clinic(clinic.toJson());
        });
    };

    const changeTz = (event, value) => {
        setSubmitDisabled(false);
        event.persist();
        setClinic(clinic => {
            clinic['timezone'] = value.name;
            return new Clinic(clinic.toJson());
        });
    };

    const getTextField = (clinic) => (fieldName) => {
        return <TextField id={`clinic-${fieldName}-id`}
                          className={classes.formControl}
                          label={`Clinic ${fieldName[0].toUpperCase() + fieldName.substring(1)}`}
                          onChange={getOnChange(fieldName)}
                          value={clinic ? clinic[fieldName] ? clinic[fieldName] : '' : ''}/>;
    };

    return <LoadingOverlay
        active={loadingText !== null}
        spinner
        text={loadingText === null ? '' : loadingText}
    >
        <div style={{minHeight: 575}}>
            <Fragment>
                <Typography component="p" variant="h1" color={"primary"} gutterBottom>
                    {clinic ? clinic.name : 'Loading...'}
                </Typography>
                <Typography component="p" variant="h5" color={"secondary"} gutterBottom>
                    General Settings
                </Typography>
                <Paper className={classes.section} elevation={3}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {['specialty', 'description'].map(getTextField(clinic, classes))}
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardTimePicker
                                    margin="normal"
                                    className={classes.timePicker}
                                    InputLabelProps={{classes: {formControl: classes.inputLabelFormControl}}}
                                    id="start-time-picker"
                                    label="Clinic Opening Time (first appointment time)"
                                    value={clinic ? clinic.startTime : null}
                                    onChange={getOnChangeDateTime('startTime')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="end-time-picker"
                                    className={classes.timePicker}
                                    InputLabelProps={{classes: {formControl: classes.inputLabelFormControl}}}
                                    label="Clinic Closing Time (final appointment time)"
                                    value={clinic ? clinic.endTime : null}
                                    onChange={getOnChangeDateTime('endTime')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <Autocomplete
                            id="combo-box-timezone"
                            options={TIMEZONES}
                            className={classes.formControl}
                            disableClearable={true}
                            getOptionLabel={t => t.name.replace(/_/g, ' ')}
                            style={{width: 300}}
                            value={clinic ? clinic.timezone : null}
                            onChange={changeTz}
                            renderInput={params => (
                                <TextField {...params} label="Clinic Timezone" variant="outlined" fullWidth/>
                            )}
                        />
                    </div>
                </Paper>
                <Typography component="p" variant="h5" color={"secondary"} gutterBottom>
                    Workflow Options
                </Typography>
                <Paper className={classes.section} elevation={3}>
                    <TextField
                        id="lonely-patient-option"
                        className={classes.formControl}
                        label="Idle Patient Timeout"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={getOnChange('workflowPatientTimeout')}
                        value={clinic ? clinic.workflowPatientTimeout : null}
                        helperText={'The time in minutes a patient can be alone in a room before showing an alert in the Workflow view.'}
                    />
                    {demoStatus ?
                        <div>
                            <Button onClick={manageDemoSimulation}>
                                {demoStatus === 'inactive' ? 'Start Demo Simulation' : 'Stop Demo Simulation'}
                            </Button>
                        </div>
                        :
                        null
                    }

                </Paper>
            </Fragment>
        </div>
        <div className={classes.buttonContainer}>
            <Button variant="contained" disabled={submitDisabled} onClick={saveClinic}>
                Submit Changes
            </Button>
            <Button variant="contained" disabled={submitDisabled} onClick={revertClinic}>
                Revert Changes
            </Button>
        </div>
    </LoadingOverlay>
}
