import {Typography} from "@material-ui/core";
import {DoubleArrow} from "@material-ui/icons";
import React from "react";
import {batteryBodyRender} from "../complianceUtils";

const columns = [
    {
        label: 'Beacon',
        name: 'beacon',
        key: 'beacon',
        options: {
            //todo implement battery status redirect to beacons page
            customBodyRender: (label) => {
                return <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'baseline'}}>
                    <Typography>{label}</Typography>
                </div>

            }
        }

    },
    {
        label: 'Staff Member',
        name: 'staff',
        key: 'staff'
    },
    {
        label: 'Beacon Status',
        name: 'status',
        key: 'status',
        options: {
            customBodyRender: batteryBodyRender
        }
    },
    {
        label: 'Scheduled',
        name: 'scheduled',
        key: 'scheduled',
        options: {customBodyRender: (value) => value ? 'Yes' : 'No'},
    },
    {
        label: 'Events',
        name: 'events',
        key: 'events',
        options: {
            display: 'excluded',
            filter: false,
            customBodyRender: (events, tableMeta) => {
                return <div style={{
                    paddingBottom: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    maxWidth: '800px'
                }}>
                    {events.map((entry, idx) => {
                        return <div key={`${tableMeta.rowIndex}_event_${idx}`} style={{
                            padding: '2px', display: 'flex', flexDirection: 'row',
                            flexWrap: 'nowrap', lineHeight: '.5', fontSize: '10px'
                        }}>
                            <div><DoubleArrow style={{opacity: '.5'}} color="primary"/></div>
                            <div style={{display: 'flex', flexDirection: 'column',}}>
                                <div>{entry.event}</div>
                                {entry.area &&
                                <Typography color={'primary'} variant={'subtitle2'}>{entry.area}</Typography>}
                            </div>
                        </div>
                    })}
                </div>

            }
        }
    },
];

export default columns;
