import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const navBarHeight = '3em';

export const palette = {
    primary: {main: '#224668'},
    secondary: {main: '#00C3FF'},
    error: {main: '#F86041'},
    accent: {main: '#6264AF'},
    other: {main: '#e9eaff'},
    contrastThreshold: 3,
    tonalOffset: 0.2,
    standard: {main: '#ffcc00'}
};


export const ahTheme = createMuiTheme({


    palette: palette,

    typography: {
        fontFamily: [
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),


    },
    overrides: {

        MuiCssBaseline: {
            '@global': {
                '@font-face': 'Arial !important',
            },
        },

        MuiBox: {
            root: {
                display: 'flex',
                flexDirection: 'column'
            }
        },

        MuiGrid: {
            container: {
                width: null
            }
        },


        MuiAppBar: {
            root: {
                flexGrow: 2,
                background: '#224668',
                height: navBarHeight,
                display: 'list-item'
            }
        },

        MuiTab: {
            root: {
                padding: '6px 12px !important',
                minHeight: '40px', 
                maxHeight: '40px'
            }
        },

        MuiButtonBase: {
            root: {
                height: 'inherit',

            }
        },

        MuiTabs: {
            root: {
                minHeight: '40px',
                maxHeight: '40px'
            }
        },


        MuiTable: {
            root: {
                width: '100%',
                top: '10px',
            }
        },
        MuiTableBody: {
            root: {
                verticalAlign: 'left'
            }
        },
        MUIDataTable: {
            root: {
                width: 'inherit',
            },
            paper: {
                boxShadow: 'none',
                padding: '5px'
            },
        },
        MuiToolbar: {
            regular: {
                maxHeight: '30px',
                minHeight: '24px',
                paddingBottom: 0,
                '@media (min-width: 600px)': {
                    minHeight: '30px',
                },
            }
        },
        MuiTableRow: {
            root: {
                verticalAlign: 'left',
                justifyContent: 'left',
            },
        },
        MuiSvgIcon: {
            root: {
                textShadow: '10px',
                boxShadow: '10px',


            }
        },
        MuiTableCell: {
            root: {
                padding: '5px',
                textAlign: 'center'
            },
            body: {
                color: '#ADABA1'
            },
            head: {
                fontSize: '15px',
                paddingBottom: '20px',
                paddingTop: '0px'
            }
        },

        MuiTypography: {
            root: {
                fontSize: '20px',
                fontFamily: 'sans-serif',
            },

            h2: {
                fontSize: '30px',
                fontFamily: 'sans-serif',
                color: '#828D9C',
                textTransform: 'uppercase'
            },
            h1: {
                fontSize: '40px',
                fontFamily: 'sans-serif',
                color: palette.primary.main
            },
            h4: {
                color: '#336699'
            }
        },
        MuiDrawer: {
            root: {
                transition: 'ease-in-out',
                overflow: 'scroll',
                maxHeight: 'calc(90vh - 100px)'


            },


            paper: {
                padding: '50px',
                paddingTop: '50px',
                height: '95%',
                width: '45em',
                boxShadow: ' -15px 31px 19px 0px rgba(0,0,0,0.10);\n' +
                    '-moz-box-shadow: -15px 31px 19px 0px rgba(0,0,0,0.10);\n' +
                    'box-shadow: -15px 31px 19px 0px rgba(0,0,0,0.10);'
            },
            modal: {

                visibility: false,
                width: 10,
                zIndex: 0,
                left: 3000,
                maskMode: false,
                maxHeight: 'calc(100vh - 1000px)'
            }
        },
        MuiFab: {
            root: {
                scale: '.75',
            }
        },

        MuiSelect: {
            selectMenu: {
                top: 0
            },
            root: {
                top: 0,
                left: 0,
                fontSize: '.85em'

            },

        }
    },

    transitions: {
        duration: {
            standard: 'opacity .25s linear, visibility .1s linear .5s;'
        }
    },

});


export const ahHexColorArr = [
    '#4575B4',
    '#74ADD1',
    '#ABD9E9',
    '#E0F3F8',
    '#FFFFBF',
    '#FEE090',
    '#FEE090',
    '#FDAE61',
    '#F46D43',
    '#D73027',
];
