import React from 'react';
import Tooltip from "@material-ui/core/Tooltip";
import ProgressStatusView from "../../components/ProgressStatusView";
import ProgressStatusViewText from "../../components/ProgressStatusViewText";
import withStyles from "@material-ui/core/styles/withStyles";


export const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#FEFAEF',
        color: '#224668',
        maxWidth: 220,
        border: '1px solid #dadde9',
    },
}))(Tooltip);

function ProgressIndicator({record}) {
    return <HtmlTooltip
        title={
            <React.Fragment>
                <ProgressStatusViewText key={record.key} id={record.key} record={record}/>
            </React.Fragment>
        }
    >
        <div>
            <ProgressStatusView record={record}/>
        </div>

    </HtmlTooltip>


}

export default ProgressIndicator;
