import React, {useMemo, useState} from 'react';
import TextField from "@material-ui/core/TextField";


function SearchBar({entries, filterField, callback}) {

    const [textValue, setTextVaue] = useState('');

    const onSearchTextchange = (event) => {
        setTextVaue(event.target.value);
    };

    useMemo(() => {
        let list = entries;

        if (!textValue || textValue.length === 0) {
            callback(entries);
        } else {
            list = list.filter((item) => {
                const field = item[filterField].toLowerCase();
                return field.search(textValue.toLowerCase()) !== -1;
            });
            callback(list)
        }
    }, [textValue]);

    return (
        <div style={{paddingLeft: '50px'}}>
            <form>
                <TextField value={textValue} type="text" placeholder="Search" onChange={onSearchTextchange}/>
            </form>
        </div>
    );
}

export default SearchBar;
