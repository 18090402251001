import axios from 'axios';
import {globals, history} from '../utils/SharedResources';
import {getAuth} from "ah-auth0";

import AsyncLock from 'async-lock';

let mainHost = process.env.REACT_APP_BACKEND_URL;

class DataService {

    static logoutAlerted = false;
    static logoutLock = new AsyncLock();
    static cancelTokenSource = axios.CancelToken.source();

    constructor(history, auth, endpoint) {
        this.history = history;
        this.auth = auth;
        this.axios = axios.create({
            baseURL: endpoint && endpoint.endsWith('/') ? endpoint : endpoint + '/',
            timeout: 50000,
            withCredentials: true
        });

        const runLogout = (error) => {
            this.logoutIfError(error);
        }
        this.axios.interceptors.response.use(null, function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            runLogout(error);

            return Promise.reject(error);
          });
    }

    logoutIfError(error) {
        const logout = () =>{
            if (!DataService.logoutAlerted && error && error.response && (error.response.status === 401 || error.response.status === 403)) {
                DataService.logoutAlerted = true;

                DataService.cancelTokenSource.cancel('cancelling request due to previous 401 or 403 error')
                
                this.history.replace('/logout');
                alert('Your session has expired, please re-authenticate.')
            }
        }

        DataService.logoutLock.acquire('key', logout)
    }

    get baseUrl() {
        return this.axios.defaults.baseURL;
    }

    async delete(url, headers) {
        const options = {
            headers: this.getHeaders(headers),
            withCredentials: true,
            cancelToken: DataService.cancelTokenSource.token
        };

        return this.axios.delete(url, options).then(response => {
            return response;
        }).catch((error) => {
            globals.setError(url + '\n' + error.message);
            return error;
        });
    }

    async patch(url, item, headers) {

        const options = {
            headers: this.getHeaders(headers),
            withCredentials: true,
            cancelToken: DataService.cancelTokenSource.token
        };

        return this.axios.patch(url, item, options).then(response => {
            return response;
        }).catch((error) => {
            globals.setError(url + '\n' + JSON.stringify(error.message));
            return error;
        });

    }

    async put(url, item, headers) {

        const options = {
            headers: this.getHeaders(headers),
            withCredentials: true,
            cancelToken: DataService.cancelTokenSource.token
        };

        return this.axios.put(url, item, options).then(response => {
            return response;
        }).catch((error) => {
            globals.setError(url + ':  ' + error.message);
            return error;
        });

    }


    async post(url, item, headers) {

        const options = {
            headers: this.getHeaders(headers),
            withCredentials: true,
            cancelToken: DataService.cancelTokenSource.token
        };

        return this.axios.post(url, item, options).then(response => {
            return response;
        }).catch((error) => {
            globals.setError(url + '\n' + JSON.stringify(error.message));
            return error;
        });

    }

    async get(url, headers, params) {
        headers = this.getHeaders(headers);
        const options = {
            method: 'GET',
            headers: headers,
            withCredentials: true,
            cancelToken: DataService.cancelTokenSource.token

        };
        if (params) {
            options.params = params;
        }

        return this.axios.get(url, options).then(response => {
            return response;
        }).catch((error) => {
            globals.setError(url + '\n' + error.message);
            return error;
            // throw error;
        });


    }

    getHeaders = (incomingHeaders) => {

        let headers = incomingHeaders ? incomingHeaders : {};
        if (!headers.hasOwnProperty('Authorization')) {
            headers = {
                ...headers,
                ...this.auth.getHeaders()
            };
        }
        return headers;

    };

}

export default DataService;

export const dataService = new DataService(history, getAuth(history, process.env), mainHost);

export const userAdminDataService = new DataService(history, getAuth(history, process.env), process.env.REACT_APP_BACKEND_USERADMIN_URL);
export const clinicAdminDataService = new DataService(history, getAuth(history, process.env), process.env.REACT_APP_BACKEND_CLINICADMIN_URL);

export const realmAdminDataService = new DataService(history, getAuth(history, process.env), process.env.REACT_APP_BACKEND_CLINICADMIN_URL); // realm!

export const schedulingDataService = new DataService(history, getAuth(history, process.env), process.env.REACT_APP_SCHEDULING_BACKEND);
export const analyticsDataService = new DataService(history, getAuth(history, process.env), process.env.REACT_APP_ANALYTICS_BACKEND_URL);
export const gatewayManagerDataService = new DataService(history, getAuth(history, process.env), process.env.REACT_APP_BACKEND_GATEWAYMANAGER_URL);

