import PropTypes from 'prop-types';
import {rawBeaconDataShape} from "../../../model/dataModels";
import {getDiff} from "../complianceUtils";
import greenLight from '../../../assets/img/green_light.png'
import redLight from '../../../assets/img/redlight.png'
import yellowLight from '../../../assets/img/yellow_light.png'
import * as moment from "moment";


export const STATUS_RANGES = {
    RED: 3,
    YELLOW: 2,
    GREEN: 1,
    TYPE: 'day'
};

export class BeaconsRecord {

    rawBeaconData;
    areas;

    constructor(entry, currentClinic, unassigned, areas, type='staff') {
        this.areas = areas;
        this.rawBeaconData = entry;
        this.currentClinic = currentClinic;

        this.unAssignedSet = new Set(unassigned.map(t => t.beacon_code));

        this.calculateStatusItems(entry.timestamp);

        if (type === 'equipment') {
            this.calculateEquipmentAssignments(entry);
        }
        else {
            this.calculateStaffAssignments(entry)
        }


        this.unassigned = unassigned;
    }

    get key() {
        return `beacon_view_${this.currentClinic.currentClinic}_${this.beaconCode}`
    }

    get status() {
        return this.statusInterval;
    }

    get beaconCode() {
        return this.rawBeaconData.beacon_code;
    }

    set beaconCode(value) {
        this.rawBeaconData.beacon_code = value;
    }

    get beaconId() {
        return this.rawBeaconData.beacon_id;
    }

    get curItem() {
        return this;
    }

    get staffId() {
        return this.rawBeaconData.staff_id;
    }

    get beaconMac() {
        return this.rawBeaconData.beacon_mac;
    }

    get voltage() {
        return this.rawBeaconData.voltage;
    }

    get deactivated() {
        return this.rawBeaconData.deactivated
    }

    set deactivated(val) {
        this.rawBeaconData['deactivated'] = val;
    }

    get area() {
        if (this.areas && this.areas[this.rawBeaconData.area_id]) {
            const thisArea = this.areas[this.rawBeaconData.area_id];
            return thisArea.area_display_name ? thisArea.area_display_name : thisArea.area_name;
        }
        return '';
    }

    get lastUpdateTimestamp() {
        return this.rawBeaconData.end_timestamp_tz;
    }

    getCompareValue = (key) => {
        if (this.hasOwnProperty(key)) {
            return this[key];
        } else if (this.rawBeaconData.hasOwnProperty(key)) {
            return this.rawBeaconData[key];
        } else {
            return null;
            // return this[key];
        }
    };

    calculateStaffAssignments = (entry) => {
        const staffId = entry.staff_id = parseInt(entry.staff_id);
        const beaconCode = entry.beacon_code;
        if (staffId && staffId > 0) {
            this.assignment = staffId;
            this.assignmentString = `${entry.last_name}, ${entry.first_name}`
        } else if (this.unAssignedSet.has(beaconCode)) {
            this.assignmentString = 'UNASSIGNED'
        } else {
            this.assignmentString = 'NOT AVAILABLE'
        }
    };

    calculateEquipmentAssignments = (entry) => {
        const equipmentId = entry.equipment_id = parseInt(entry.equipment_id);
        const beaconCode = entry.beacon_code;
        if (equipmentId && equipmentId > 0) {
            this.assignment = equipmentId;
            this.assignmentString = `${entry.equipment_type}, ${entry.name}`
        } else if (this.unAssignedSet.has(beaconCode)) {
            this.assignmentString = 'UNASSIGNED'
        } else {
            this.assignmentString = 'NOT AVAILABLE'
        }
    };

    calculateStatusItems(timestamp) {
        if (timestamp > 0) {
            const d = moment.unix(timestamp)._d;
            const yr = String(d.getFullYear());
            const end = yr[2] + yr[3];
            this.statusInterval = getDiff(STATUS_RANGES.TYPE, new Date(), d);

            let icon;

            if (this.statusInterval <= STATUS_RANGES.GREEN) {
                icon = greenLight;
            } else if (this.statusInterval >= STATUS_RANGES.YELLOW) {
                icon = yellowLight;
            } else {
                icon = redLight
            }
            this.statusIcon = icon;

            let datestring = (d.getMonth() + 1) + "/" + d.getDate() + "/" + end;
            const hour = d.getHours();
            const mins = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
            if (hour === 12) {
                this.lastSeenDateString = `${datestring} ${hour}:${mins} PM`
            } else if (hour > 12) {
                this.lastSeenDateString = `${datestring} ${hour - 12}:${mins} PM`
            } else {
                this.lastSeenDateString = `${datestring} ${hour}:${mins} AM`
            }
        } else {
            this.statusIcon = redLight;
        }
    }

    toJson = () => {
        return this.rawBeaconData;
    }

}

export function buildComplianceRecords(rawBeaconsDataList, currentClinic, unassigned, areas, type='staff') {
    if (!rawBeaconsDataList){
        return []
    }
    return rawBeaconsDataList.map(r => new BeaconsRecord(r, currentClinic, unassigned, areas, type));
}

BeaconsRecord.propTypes = {
    entry: rawBeaconDataShape,
    currentClinic: PropTypes.any,
    unassigned: PropTypes.array
};

export const beaconsRecordPropTypes = BeaconsRecord.propTypes;

export const getCustomSort = (columns) => (data, colIndex, order) => {
    const key = columns[colIndex].key;
    return data.sort((a, b) => {
        a = a.data[colIndex];
        b = b.data[colIndex];
        if (a && a.hasOwnProperty('getCompareValue')) {
            a = a.getCompareValue(key);
            b = b.getCompareValue(key);
        }

        if (a === b) return 0;
        return order === 'desc' ? a > b ? -1 : 1 : a < b ? -1 : 1;
    });
};

export const customSearch = (columns) => (searchQuery, currentRow, cols) => {
    let isFound = false;
    currentRow.forEach((col, index) => {
        if (col instanceof BeaconsRecord) {
            const column = columns[index];
            const value = col.getCompareValue(column.key);
            if (value && value.toString().toLowerCase().includes(searchQuery.toString().toLowerCase())) {
                isFound = true;
            }
        } else if (col && col.toString().toLowerCase().includes(searchQuery.toLowerCase())) {
            isFound = true;
        }
    });
    return isFound;

};

