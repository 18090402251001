import React, {useState} from "react";
import {CHECKBOX, DATE, DynamicInputModel, NUMBER, TEXT, TIME} from "./DynamicInputModel";
import {v4 as uuidv4} from 'uuid';
import Input from "@material-ui/core/Input";
import {FiCheckSquare, FiSquare} from "react-icons/fi";


export const types = [TEXT, NUMBER, DATE, TIME];

export const isEmpty = (item) => {
    if (!item) return true;
    const str = String(item);
    return !str || str.length === 0;
};

export const menuForInputTypes = types.map(type => {
    return {
        label: type.toUpperCase(),
        value: type
    }
});

const getIsMultiline = (item) => {
    if (item.type === TEXT) {
        return !!(item.value && item.value.length > 20);
    }
    return false;
};

export const getKey = (entry) => {
    if (!entry.key) {
        entry.key = uuidv4();
        entry.id = entry.key;
    }
    return entry.key;

};
const updateValue = (entry, newValue, setter, callback) => {
    entry.parent[entry.key] = entry.value = newValue;
    setter(newValue);
    if (callback) {
        callback(newValue, entry)
    }
};


export const InputWrapper = ({id, label, input}) => {
    return <div key={`${id}-'text-input`} className={'slot-editor-row'}>
        <div><label>{label}</label></div>
        <div>
            {input}
        </div>
    </div>
};


export const DefaultInput = ({entry, id, callback}) => {
    const [value, setValue] = useState(entry.value ? entry.value : '');
    const onDefaultInputChange = (evt) => {
        updateValue(entry, evt.target.value, setValue, callback);

    };

    const input = <input key={`${id}-tf`}
                         type={entry.type}
                         value={value}
                         id={`${id}-tf`}
                         onChange={onDefaultInputChange}/>;

    return <InputWrapper id={id} input={input} label={entry.label}/>


};


export const TypedTextInput = ({entry, id, callback}) => {
    const [value, setValue] = useState(entry.value ? entry.value : '');
    const [multiline, setMultiline] = useState(getIsMultiline(entry));
    const [type, setType] = useState(entry.type ? entry.type : TEXT);

    const onTextChange = (evt) => {

        updateValue(entry, evt.target.value, setValue, callback)

    };
    const input = <Input key={`${id}-tf`}
                         type={entry.type}
                         value={entry.value}
                         multiline={multiline}
                         id={`${id}-tf`}
                         onChange={onTextChange}/>;

    return <InputWrapper id={id} input={input} label={entry.label}/>

};
export const TypedCheckBox = ({entry, id, callback}) => {

    const [value, setValue] = useState(entry.value === true);
    const [element, setElement] = useState(entry.value === true ? <FiCheckSquare style={{color: '#224668'}}/> :
        <FiSquare style={{color: '#224668'}}/>);
    const onCheckboxChange = () => {

        const newValue = !value;
        const icon = newValue === true ? <FiCheckSquare style={{color: '#224668'}}/> :
            <FiSquare style={{color: '#224668'}}/>;
        setElement(icon);
        updateValue(entry, newValue, setValue, callback);


    };


    return <InputWrapper id={id} label={entry.label} input={<div onClick={onCheckboxChange}>{element}</div>}/>
};


export const getSlotEditorData = (currentSlot, currentRecord) => {
    if (!currentSlot) return [];
    currentSlot.locked = Boolean(currentSlot.locked);

    const id = `${currentRecord.templateId}-${currentSlot.slot_id}`;

    const metadata = currentSlot['meta_data'];
    return [
        new DynamicInputModel({
            type: NUMBER,
            label: 'Capacity',
            key: 'capacity',
            id: `${id}-capacity`,
            value: currentSlot.capacity,
            parent: currentSlot
        }),
        new DynamicInputModel({
            type: TIME,
            label: 'Start Time',
            key: 'start_time',
            id: `${id}-start_time`,
            value: currentSlot.start_time,
            parent: currentSlot
        }),

        new DynamicInputModel({
            type: NUMBER,
            label: 'Duration(min)',
            key: 'duration',
            id: `${id}-duration`,
            value: currentSlot.duration,
            parent: currentSlot
        }),
        new DynamicInputModel({
            type: NUMBER,
            label: 'Overbook Capacity',
            key: `overbook_capactiy`,
            id: `${id}-overbook_capacity`,
            value: currentSlot.overbook_capacity ? currentSlot.overbook_capacity : 0,
            parent: currentSlot
        }),
        new DynamicInputModel({
            type: CHECKBOX,
            label: 'Locked',
            key: 'locked',
            id: `${id}-locked`,
            value: currentSlot.locked,
            parent: currentSlot
        }),
        new DynamicInputModel({
            type: CHECKBOX,
            label: 'Private',
            key: 'private',
            id: `${id}-private`,
            value: currentSlot.private === true,
            parent: currentSlot
        }),
        new DynamicInputModel({
            type: 'list',
            label: 'Slot Type',
            key: 'slot_type_id',
            id: `${id}-${currentSlot.slot_type_id}-slot_type`,
            value: currentSlot.slot_type_id,
            parent: currentSlot,
            list: currentRecord.slotTypeMenu
        }),
        new DynamicInputModel({
            type: 'JSON',
            label: 'Metadata',
            key: 'meta_data',
            id: `${id}-meta-data`,
            value: !currentSlot.meta_data || !(Array.isArray(currentSlot.meta_data)) ? [] : currentSlot.meta_data,
            parent: currentSlot
        })
    ]
};
