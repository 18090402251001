let example = [{
    "area_id": 0,
    "is_hidden": false,
    "deactivated" : false,
    "area_name": "4102",
    "area_display_name": "4102",
    "area_type_id": 0,
    "show_hidden_as": null,
    "area_type_name": "exam_room"
}];

export default class Staff {
    jsonObj;

    constructor(jsonObj) {
        this.jsonObj = jsonObj;

        if (!('deactivated' in this.jsonObj)) {
            this.jsonObj.deactivated=false
        }
    }

    get id() {
        return this.jsonObj.staff_id;
    }

    get staffId() {
        return this.jsonObj.staff_id;
    }

    get staffName() {
        if (!this.staffFirstName && !this.staffLastName)
            return null;
        if (this.staffLastName && this.staffFirstName)
            return this.staffLastName + ', ' + this.staffFirstName;
        return this.staffLastName || this.staffFirstName

    }

    get displayName() {
        return this.staffName
    }
    get specialtyId() {
        return this.jsonObj.specialty_id;
    }

    set specialtyId(val) {
        this.jsonObj.specialty_id = val;
    }

    get specialty() {
        return this.jsonObj.specialty;
    }

    get role() {
        return this.jsonObj.role_name;
    }

    get email() {
        return this.jsonObj.email;
    }

    set email(val) {
        this.jsonObj.email = val;
    }

    get hasScheduleTemplate() {
        return this.jsonObj.can_schedule;
    }

    set hasScheduleTemplate(val) {
        this.jsonObj.can_schedule = val;
    }

    get staffFirstName() {
        return this.jsonObj.first_name;
    }

    set staffFirstName(val) {
        this.jsonObj.first_name = val;
    }

    get staffLastName() {
        return this.jsonObj.last_name;
    }

    set staffLastName(val) {
        this.jsonObj.last_name = val;
    }

    get staffRoleId() {
        return this.jsonObj.role_id;
    }

    set staffRoleId(val) {
        this.jsonObj['role_id'] = val;
    }

    get externalId() {
        return this.jsonObj.external_id;
    }

    set externalId(val) {
        this.jsonObj.external_id = val;
    }

    get deactivated() {
        return this.jsonObj.deactivated
    }

    set deactivated(val) {
        this.jsonObj['deactivated'] = val;
    }

    toJson() {
        return this.jsonObj;
    }
}
