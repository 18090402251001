import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React from "react";
import DialogContentText from "@material-ui/core/DialogContentText";

export default function ConfirmDelete({model, open, close, deleteCallback, additionalButtons, label}) {
    function getAdditionalButtons() {
        return additionalButtons ? additionalButtons.map(item => {
            return item;
        }) : null;
    }
    return (<Dialog
                    open={open}
                    maxWidth={'sm'}
                    onClose={close}
    >
        <DialogTitle>{"Confirm Delete"}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {`Delete ${label} -- ${model.displayName} ?`}
            </DialogContentText>

        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                close();
                deleteCallback(model)
            }} style={{color: "#F86041"}}>Delete</Button>
            <Button onClick={() => close()} color="primary">Cancel</Button>
            {getAdditionalButtons()}
        </DialogActions>
    </Dialog>);

}