import {humanize} from "../utils/Utils";


export default function GlobalProps() {
    // Initial values are obtained from the props

    const listenerMethods = [];
    const setError = error => {
        state.error = error;
        broadcastChange("error")
    };
    const setSelectedClinic = clinic => {
        state.selectedClinic = clinic;
        broadcastChange("selectedClinic")
    };
    const setUser = user => {
        state.user = user;
        broadcastChange("user")
    };
    const addClinics = clinicList => {
        state.clinics.concat(clinicList);
        broadcastChange("clinics")
    };

    const addNewClinic = clinicName => {
        const newClinic = {id: clinicName, name: humanize(clinicName)};
        state.clinics.push(newClinic);
        broadcastChange("addClinics")
    };

    const broadcastChange = value => {
        listenerMethods.forEach(method => {
            method(value);
        })
    };

    const registerListener = method => {
        listenerMethods.push(method);
    };

    const state = {
        error: null,
        selectedClinic: null,
        clinics: [],
        user: null,
        setError: setError,
        setSelectedClinic: setSelectedClinic,
        setUser: setUser,
        addNewClinic: addNewClinic,
        registerListener: registerListener,
        addClinics: addClinics
    };



    // Use State to keep the values

    // Make the context object:
    // pass the value in provider and return
    return state;
}

export const ADMIN_MODES = {

    CLINIC: 1,
    REALM: 10,
    CUSTOMER: 100
};





