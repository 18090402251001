import React, {useEffect, useState} from 'react';
import {clinicAdminDataService} from "../../../service/DataService";
import {getBody} from "../../../utils/Utils";
import {Typography} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import {globals} from "../../../utils/SharedResources";
import {buildComplianceRecords, customSearch, getCustomSort} from "./BeaconsRecord";
import {defaultBadgeAssignmentFilterList, getColumns} from "./BadgeAssignmentCols";

function StaffBadgeAssignment({currentClinic,}) {

    const [rows, setRows] = useState(null),
        [unassigned, setUnassigned] = useState([]),
        [suggestionData, setSuggestionData] = useState(null),
        [badgeAssignments, setBadgeAssignments] = useState(null),
        [assignmentDisabled, setAssignmentDisabled] = useState(false),
        [columns, setColumns] = useState(null),
        [stateFilterList, setStateFilterList] = useState(defaultBadgeAssignmentFilterList);

    useEffect(() => {
        clinicAdminDataService.get(`clinicadmin/clinics/${currentClinic.currentClinic}/beacons/users`).then(response => {
            const rawData = getBody(response);
            const map = {};
            map['UNASSIGNED'] = {
                last_name: null,
                first_name: null,
                staff_id: null,
            };
            if (rawData) {
                rawData.forEach(item => {
                    if (!item.deactivated) { //don't include deactivated staff
                        const str = `${item.last_name}, ${item.first_name}`;
                        map[str] = item;
                    }

                });
            }
            const s = Object.keys(map).sort((a,b) => { //sort unassigned first
                return a === 'UNASSIGNED' ? -1 : ( b === 'UNASSIGNED' ?  1 : a < b)
            });
            setSuggestionData({
                "data": map,
                "display_values": s,
                "disabled": [] //disable staff that already are assigned
            });
        });
    }, [currentClinic.currentClinic]);

    const handleResponse = (response) => {
        const bod = getBody(response);
        if (response && response.error && response.error.toLowerCase().contains('duplicate')) {
            globals.setError("This badge is already assigned. Changes will not be applied");
        }
        if (bod) {
            setUnassigned(bod.unassigned);
            const r = bod['compliance_data']['rows'];
            const rws = buildComplianceRecords(r, currentClinic, bod.unassigned);
            const key='beaconCode';
            rws.sort((a, b) => {
                a = a.getCompareValue(key);
                b = b.getCompareValue(key);
                if (a === b) return 0;
                return a < b ? -1 : 1;
            });
            const badgeAssignments = {};
            rws.map((item) => {
                if (item.staffId) {
                    badgeAssignments[item.staffId] = item.beaconId;
                }
            })
            setRows(rws)
            setBadgeAssignments(badgeAssignments)
        } else {
            setRows(null)
            setBadgeAssignments(null)
        }
    };

    const handleBeaconChangeRequest = async (dataObj) => {
        setAssignmentDisabled(true);
        const url = `clinicadmin/clinics/${currentClinic.currentClinic}/staff/${dataObj.staff_id}/beacons/${dataObj.beacon_id}`;
        const response = await clinicAdminDataService.put(url, dataObj);
        const body = getBody(response);
        if (body && body.hasOwnProperty('error') && body.error !== null) {
            setAssignmentDisabled(false);
            globals.setError(body.error);
            return null;
        } else if (body) {
            setAssignmentDisabled(false);
            handleResponse(response);
            return response;
        }
    };


    useEffect(() => {
        if (suggestionData){
            setColumns(getColumns(stateFilterList, handleBeaconChangeRequest, suggestionData, unassigned, currentClinic, assignmentDisabled));
        }
    }, [stateFilterList, suggestionData, unassigned, currentClinic.currentClinic, assignmentDisabled]);

    const getData = () => {
        if (currentClinic) {
            const cc = currentClinic.currentClinic;
            const beaconUrl = `clinicadmin/clinics/${cc}/beacons/stats`;

            clinicAdminDataService.get(beaconUrl).then(response => {
                handleResponse(response);
            });
        }
    };

    useEffect(() => {
        getData();
    }, [currentClinic]);

    useEffect(() => {
        // annotate the suggestion data to see if it has a beacon assigned
        if (badgeAssignments && suggestionData && suggestionData.data) {
            let disabled = []
            Object.keys(suggestionData.data).forEach((key) => {
                    let item = suggestionData.data[key]
                    if (badgeAssignments[item.staff_id]) {
                        disabled.push(key)
                    }
                }
            )
            suggestionData.disabled = disabled
        }

    }, [badgeAssignments, suggestionData] )

    if (!currentClinic || !currentClinic.currentClinic) {
        return <Typography variant={'body1'}>Please select a clinic</Typography>
    }
    return <div style={{display: 'flex', flexDirection: 'column', overflowX: 'hidden'}}>

        <div style={{width: '100%', paddingRight: '10px', left: '20px'}}>
            <Typography style={{paddingLeft: '20px'}} variant={'h2'} color={'primary'}>Badge Assignments</Typography>
            {columns ?
                <MUIDataTable
                    style={{width: '100%'}}
                    data={rows !== null ? rows : []}
                    selectable={false}
                    columns={columns}
                    expandableRows={true}
                    options={{
                        textLabels: {
                            body: {
                                noMatch: rows !== null ? "No Staff assigned to clinic" : "Loading..."
                            }
                        },
                        selectableRows: 'none',
                        print: false,
                        sort: true,
                        search: {top: 0, left: 0},
                        customSort: getCustomSort(columns),
                        customSearch: customSearch(columns),
                        download: false,
                        checkboxInline: false,
                        displayRowCheckbox: false,
                        onFilterChange: (changedColumn, filterList) => {
                            setStateFilterList(filterList)
                        },
                    }}
                /> : <div>Loading...</div>}
        </div>
    </div>
}

export default StaffBadgeAssignment;
