import Fade from "@material-ui/core/Fade";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, {useEffect, useState} from "react";
import {clinicAdminDataService} from "../../../service/DataService";
import {convertToObj, deepCompare} from "../../../utils/Utils";
import {areaTypeColumns} from "./AreasView";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import LoadingOverlay from 'react-loading-overlay';
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import DialogContent from "@material-ui/core/DialogContent";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade direction="down" ref={ref} {...props} />;
});
const AREA_NAME_REGEX = /^[A-Z|a-z|_|-|0-9]*$/.compile();
const useStyles = makeStyles(theme => ({
    fab: {
        margin: theme.spacing(1),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minWidth: '500px'
    },
    rowContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    menu: {
        width: 200,
    }
}));
let initialAreaTypes;
export default function AreaTypesDialog({open, onSubmit, onCancel}) {
    const [areaTypes, setAreaTypes] = useState(null),
        [loadingText, setLoadingText] = useState('Loading...'),
        [submitDisabled, setSubmitDisabled] = useState(true),
        classes = useStyles();

    const areaTypeNameValid = (name) => {
        return name && name !== '' && AREA_NAME_REGEX.test(name);
    };
    
    useEffect(() => {
        clinicAdminDataService.get('clinicadmin/areas/types').then(response => {
            initialAreaTypes = convertToObj(response.data.columns, response.data.rows);
            setAreaTypes(JSON.parse(JSON.stringify(initialAreaTypes)));
            setLoadingText(null);
        });
    }, []);

    useEffect(() => {
        if (areaTypes) {
            setSubmitDisabled(!areaTypes.reduce((prev, curr) => {
                return prev && curr.area_type_name && areaTypeNameValid(curr.area_type_name);
            }, true) || new Set(areaTypes.map(at => at.area_type_name)).size !== areaTypes.length);
        }
    }, [areaTypes]);
    const getOnChange = (field, idx) => {
        return (event) => {
            event.persist();
            setAreaTypes(at => {
                at[idx][field] = event.target.id === 'area-clinical-checkbox' ? event.target.checked : event.target.value;
                return [...at];
            })
        }
    };
    const removeAreaType = (idx) => {
        setAreaTypes(at => {
            at.splice(idx, 1);
            return [...at];
        });
    };
    const addAreaType = () => {
        setAreaTypes(at => {
            at.push({editable: true, area_type_name: '', area_display_name: '', is_clinical: false});
            return [...at];
        });
    };
    const saveChanges = () => {
        setLoadingText('Saving...');
        const news = [], changes = [], deletes = [];
        areaTypes.forEach((at) => {
            const oldIdx = initialAreaTypes.findIndex((oldAt) => {
                return oldAt.area_type_name === at.area_type_name;
            });
            if (oldIdx !== -1) {
                if (!deepCompare(initialAreaTypes[oldIdx], at)) {
                    changes.push(at);
                }
            } else {
                //new
                news.push(at);
            }
        });
        initialAreaTypes.forEach((oldAt, idx) => {
            const oldIdx = areaTypes.findIndex((at) => {
                return oldAt.area_type_name === at.area_type_name;
            });
            if (oldIdx === -1) {
                //deletes
                deletes.push(oldAt);
            }
        });

        const deletePromises = deletes.map(d => clinicAdminDataService.delete(`clinicadmin/areas/types/${d.area_type_id}`));
        const createPromises = news.map(d => clinicAdminDataService.post(`clinicadmin/areas/types/`, d));
        const changePromises = changes.map(d => clinicAdminDataService.put(`clinicadmin/areas/types/${d.area_type_id}`, d));
        Promise.all([...deletePromises, ...changePromises, ...createPromises]).then(() => {
            clinicAdminDataService.get('clinicadmin/areas/types').then(response => {
                const newAreaTypes = convertToObj(response.data.columns, response.data.rows);
                setLoadingText(null);
                onSubmit(newAreaTypes);
            });
        })
    };
    return <Dialog fullWidth={true}
                   open={open}
                   maxWidth={'sm'}
                   TransitionComponent={Transition}
                   onClose={onCancel}
                   style={{zIndex: 1151}}
    >
        <LoadingOverlay
            active={loadingText !== null}
            spinner
            text={loadingText === null ? '' : loadingText}
        >
            <DialogTitle>Space Types</DialogTitle>

            <DialogContent>
                <form className={classes.root} noValidate autoComplete="off">
                    {areaTypes && areaTypes.map((areaType, idx) => {
                        return <div key={idx} style={{
                            padding: '10 0 0 0',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}>
                            <TextField id="area-type-name" label="Space Type Name"
                                       disabled={!areaType.editable}
                                       error={!areaTypeNameValid(areaType.area_type_name)}
                                       onChange={getOnChange('area_type_name', idx)}
                                       value={areaType.area_type_name ? areaType.area_type_name : ''}/>
                            <TextField id="area-name" label="Display Name"
                                       onChange={getOnChange('area_type_display_name', idx)}
                                       value={areaType.area_type_display_name ? areaType.area_type_display_name : ''}/>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="area-clinical-checkbox"
                                        checked={areaType.is_clinical}
                                        onChange={getOnChange('is_clinical', idx)}
                                        value={areaType.is_clinical}
                                        color="primary"
                                    />
                                }
                                label="Is Clinical"
                            />
                            <Tooltip style={{zIndex: 2050}}
                                     title={areaType.editable ? 'Remove row' : 'Cannot remove default area types'}>
                                <span>
                                <Fab size={'small'} color="primary" aria-label="remove" className={classes.fab}
                                     onClick={() => removeAreaType(idx)} disabled={!areaType.editable}>
                                    <RemoveIcon fontSize={"small"}/>
                                </Fab>
                                </span>
                            </Tooltip>
                        </div>
                    })}

                </form>
                <div className={classes.rowContainer}>
                    <Fab size={'small'} color="primary" aria-label="add" className={classes.fab}
                         onClick={addAreaType}>
                        <AddIcon fontSize={"small"}/>
                    </Fab>
                    <Typography component='h5'>Add new row</Typography>
                </div>

            </DialogContent>
            <DialogActions>
                <Button onClick={saveChanges} disabled={submitDisabled} color="secondary">Submit</Button>
                <Button onClick={onCancel} color="primary">Cancel</Button>
            </DialogActions>
        </LoadingOverlay>
    </Dialog>;
}
