import React from "react";
import {formatUnixTime} from "../../../utils/Utils";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";

import { BatteryAlert, BatteryUnknown,BatteryFull } from '@material-ui/icons';

export const defaultBeaconFilterList = [[], [], [], [], [], ['No']];

export const BATTERY_LEVELS_MV = {
    LOW: 2700, // voltage < LOW , show red alert
    HIGH: 2800,// voltage < HIGH , show yellow alert
};

export const BATTERY_LEVELS_COLOR = {
    LOW: '#E50000',
    MID: '#FEC053',
    HIGH: '#388e3c',
    NONE: '#ADABA1'
};

export const getColumns = (filterList, toggleEditMode, isRowEditable, saveChanges, toggleDeactivateBeacon, beaconCodeSet = new Set()) => {
    return [

        {
            label: 'Badge Code',
            name: 'beaconCode',
            key: 'beaconCode',
            options: {
                filterList: filterList[0],
                customBodyRender: (value, tableMeta, updateValue) => {
                    const upd = (value) => {
                        updateValue(value);
                    };
                    return <div>
                        {isRowEditable(tableMeta.rowIndex) ?
                            <div>
                                <TextField style={{width: 75}} value={value}
                                           onChange={(event) => upd(event.target.value)}/>
                                <Tooltip disableHoverListener={!beaconCodeSet.has(value)}
                                         title={'Beacon Code Already In Use'}><span>

                                    <Button onClick={() => {saveChanges(value, tableMeta.rowData[1])}} // [1] is index of beacon_mac in columns. Is this a problem here?

                                                disabled={value === null || value === undefined || value === '' || beaconCodeSet.has(value)}>Save</Button>
                                    </span></Tooltip>
                                <Button onClick={() => {
                                    toggleEditMode(tableMeta.rowIndex, false)
                                }}>Cancel</Button>
                                <Button onClick={() => {
                                    toggleDeactivateBeacon(tableMeta.rowIndex)
                                }}>Toggle Deactivated</Button>
                            </div> : <Tooltip title={'Edit Badge Code'}>
                                <Link underline={'always'} style={{
                                    fontSize: '.875rem',
                                    cursor: 'default'
                                }}
                                      onClick={() => !isRowEditable(tableMeta.rowIndex) && toggleEditMode(tableMeta.rowIndex, true)}>{value}</Link>
                            </Tooltip>}
                    </div>;
                }
            }
        },
        {
            label: 'MAC Address',
            name: 'beaconMac',
            key: 'beaconMac',
            options: {
                filterList: filterList[1],
                customBodyRender: (value) => {
                    return value ? value.toUpperCase() : '';
                }
            }
        },
        {
            label: 'Voltage',
            name: 'voltage',
            key: 'voltage',
            options: {
                filterList: filterList[2],
                filter: false,
                customBodyRender: (value) => {

                   let batteryIcon = <BatteryUnknown/>
                   if (!value) {
                       batteryIcon =  <BatteryUnknown/>
                   } else if (value < BATTERY_LEVELS_MV.LOW) {
                       batteryIcon = <div style={{display: 'flex',alignItems:'center'}}> <BatteryAlert style={{ color:BATTERY_LEVELS_COLOR.LOW }}/>{(value/1000).toFixed(2)}V</div>
                   } else if (value < BATTERY_LEVELS_MV.HIGH) {
                       batteryIcon = <div style={{display: 'flex',alignItems:'center'}}><BatteryFull style={{ color:BATTERY_LEVELS_COLOR.MID }}/>{(value/1000).toFixed(2)}V</div>
                   } else {
                       batteryIcon = <div style={{display: 'flex',alignItems:'center'}} ><BatteryFull style={{ color:BATTERY_LEVELS_COLOR.HIGH }}/>{(value/1000).toFixed(2)}V</div>

                   }

                   return value ? batteryIcon :  <BatteryUnknown/>;
                }
            }
        },
        {
            label: 'Last Space',
            name: 'area',
            key: 'area',
            options: {
                filterList: filterList[3]
            }

        },
        {
            label: 'Last Location Update',
            name: 'lastUpdateTimestamp',
            key: 'lastUpdateTimestamp',
            options: {
                filterList: filterList[4],
                customBodyRender: (value) => {
                    return value ? formatUnixTime(value) : '';
                }
            }
        },
        {
            name: 'deactivated',
            label: 'Deactivated',
            key: 'deactivated',
            options: {
                display: 'false',
                filterList: filterList[5],
                filter: true,
                filterOptions:{
                    names: ['Yes', 'No']
                },
                customFilterListOptions: {
                    render: v => v==='Yes' ? 'Deactivated Only' : 'Active Only'
                },
                customBodyRender: v=>((v === undefined || v === null) ? "Yes" : v ? 'Yes' : 'No')
        }

        }
    ]
};
