import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React, {useEffect, useState} from "react";
import {Fade} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ClinicRolesDialog from "./ClinicRolesDialog";
import User from "./UserModel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";

export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade direction="down" ref={ref} {...props} />;
});
export const EMAIL_REGEX = /(.+)@(.+){2,}\.(.+){2,}/;
const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1)
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }
}));

const isComplete = (user) => {
    return EMAIL_REGEX.test(user.email) && user.defaultClinicId;
};


export default function UserEditor({isNew, open, onCancel, onSubmit, inputUser, clinics, roles}) {
    const [user, setUser] = useState(inputUser);
    const [emailIsError, setEmailIsError] = useState(!EMAIL_REGEX.test(user.email));
    const [showPermissions, setShowPermissions] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(!isComplete(user));
    const classes = useStyles();

    const getOnChange = field => event => {
        event.persist();
        setUser(user => {
            user[field] = event.target.id === 'user-admin-checkbox' ? event.target.checked : event.target.value;
            return new User(user.toJson());
        });
    };

    useEffect(() => {
        setEmailIsError(!EMAIL_REGEX.test(user.email));
        setSubmitDisabled(!isComplete(user));
    }, [user]);

    function updateClinicRoles(clinicRoles) {
        setShowPermissions(false);
        if (clinicRoles) {
            setUser(user => {
                user['clinicRoles'] = clinicRoles;
                return new User(user.toJson());
            });
        }
    }

    return <Dialog fullWidth={true}
                   open={open}
                   maxWidth={'sm'}
                   TransitionComponent={Transition}
                   onClose={onCancel}
                   style={{zIndex: 1000}}
    >
        <DialogTitle>{user.displayName === '' && isNew ? 'New User' : user.displayName}</DialogTitle>
        <DialogContent>
            <form className={classes.root} noValidate autoComplete="off">
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    {<TextField id="user-email" label="Email" disabled={!isNew} onChange={getOnChange('email')}
                                helperText={isNew && "This will be used to identify the user on first login."}
                                required
                                error={emailIsError}
                                value={user.email ? user.email : ''}/>}
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <TextField id="first-name" label="First Name" onChange={getOnChange('firstName')}
                                   value={user.firstName ? user.firstName : ''}/>
                        <TextField id="last-name" label="Last Name" onChange={getOnChange('lastName')}
                                   value={user.lastName ? user.lastName : ''}/>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="clinic-chooser-label">Default Clinic</InputLabel>
                            <Select
                                labelId="clinic-chooser-label"
                                id="clinic-chooser"
                                required
                                error={!user.defaultClinicId}
                                value={user.defaultClinicId}
                                onChange={getOnChange('defaultClinicId')}
                            >
                                {clinics && clinics.map(c => {
                                    return <MenuItem value={c.site_id}>{c.site_name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="user-admin-checkbox"
                                    checked={user.isCustomerAdmin}
                                    onChange={getOnChange('isCustomerAdmin')}
                                    value={user.isCustomerAdmin}
                                    color="primary"
                                />
                            }
                            label="Is Customer Admin"
                        />
                    </div>
                </div>
            </form>

        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                onSubmit(user)
            }} disabled={submitDisabled} color="secondary">Submit</Button>
            <Tooltip title={user.isCustomerAdmin ? 'Customer Admins have full permissions.' : null}><span><Button
                disabled={user.isCustomerAdmin} onClick={() => {
                setShowPermissions(true)
            }} color="secondary">Edit Permissions</Button></span></Tooltip>
            <Button onClick={onCancel} color="primary">Cancel</Button>
        </DialogActions>
        {showPermissions &&
        <ClinicRolesDialog open={showPermissions} clinicRoles={user.clinicRoles} callback={updateClinicRoles}
                           clinicArray={clinics} roleArray={roles} userId={user.userId}/>}
    </Dialog>;

}
