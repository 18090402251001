import {rolePrettyName} from "../../utils/Utils";
import {ROLES} from "ah-auth0";
import React, {useEffect, useState} from "react";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import makeStyles from "@material-ui/styles/makeStyles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {clinicAdminDataService} from "../../service/DataService";
import * as PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {Transition} from "../../components/RowEditor";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import LoadingOverlay from 'react-loading-overlay';

const useStyles = makeStyles(theme => ({
    fab: {
        margin: theme.spacing(1),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minWidth: '500px'
    },
    rowContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    menu: {
        width: 200,
    }
}));

function useStaff(clinicArray, dataService) {
    const [staff, setStaff] = useState([]);
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        Promise.all(clinicArray.map(clinic => {
            return dataService.get(`clinicadmin/clinics/${clinic.site_id}/staff`)
                .then((result) => {
                    const {data} = result, members = [];
                    data.rows.forEach((r) => {
                        let s = {};
                        data.columns.forEach((c, ix) => {
                            s[c] = r[ix];
                        });
                        members.push(s);
                    });
                    return (handler) => {
                        handler(clinic.site_id, members);
                    };
                })
        })).then((results) => {
            let s = {};
            results.forEach((callable) => {
                callable((site_id, members) => {
                    s[site_id] = members;
                })
            });
            setStaff(s);
            setLoaded(true);
        })
    }, []);
    return [loaded, staff, setStaff];
}

/**
 * @return {null}
 */
function ClinicRolesDialog(props) {
    let {userId, clinicArray, open} = props;
    let {roleArray} = props;
    roleArray = roleArray.filter(r => r.value !== 0 && r.value !== 1);
    const [clinicRoles, setClinicRoles] = useState(props.clinicRoles),
        [submitDisabled, setSubmitDisabled] = useState(true);
    let roles = {}, clinics = {};

    roleArray.forEach(role => {
        roles[role.value] = role;
    });
    const [staffLoaded, staff] = useStaff(clinicArray, clinicAdminDataService);

    const classes = useStyles();

    const handleChange = (index, type) => (event) => {
        let val;
        switch (type) {
            case 'role':
                val = {
                    ...clinicRoles[index],
                    role: roles[event.target.value]
                };
                break;
            case 'clinic':
                val = {
                    ...clinicRoles[index],
                    site_id: event.target.value,
                    clinic: clinics[event.target.value]
                };
                break;
            case 'staff':
                val = {
                    ...clinicRoles[index],
                    staffId: event.target.value
                };
                break;
            default:
                return null;
        }
        setClinicRoles(clinicRoles => {
            clinicRoles[index] = val;
            return [...clinicRoles];
        });
    };

    const addClinicRole = () => {
        let cr = {
            userId: userId,
            editable: true
        };
        setClinicRoles(clinicRoles => clinicRoles.concat([cr]));
    };

    const removeClinicRole = (index) => {
        setClinicRoles(clinicRoles => {
            clinicRoles.splice(index, 1);
            return [...clinicRoles];
        });
    };

    useEffect(() => {
        setSubmitDisabled(clinicRoles.reduce((prev, curr) => {
            prev = prev || curr.site_id === undefined || curr.site_id === null
                || curr.role === undefined || curr.role === null
                || (curr.role.value === ROLES.STAFF && (curr.staffId === undefined || curr.staffId === null));
            return prev;
        }, false));
    }, [clinicRoles]);


    const getClinicRole = (cr, index) => {
        let el = <form key={index} className={classes.container} noValidate autoComplete="off">
            <TextField
                disabled={!cr.editable}
                id="select-clinic"
                select
                required
                error={!cr.site_id}
                label="Select Clinic"
                className={classes.textField}
                value={cr.site_id ? cr.site_id : ''}
                onChange={handleChange(index, 'clinic')}
                SelectProps={{
                    MenuProps: {
                        className: classes.menu,
                    },
                }}
                helperText="Select a clinic"
                margin="normal"
            >
                {clinicArray.map(clinic => {
                    if (!cr.editable || clinic.unused || clinic.site_id === cr.site_id) {
                        return <MenuItem key={clinic.site_id} value={clinic.site_id}>
                            {clinic.site_name}
                        </MenuItem>
                    }
                    return null;
                }).filter(t => t !== null)}
            </TextField>
            <TextField
                id="select-role"
                disabled={!cr.editable}
                select
                required
                error={!cr.role}
                label="Select Role"
                className={classes.textField}
                value={cr.role ? cr.role.value : ''}
                onChange={handleChange(index, 'role')}
                SelectProps={{
                    MenuProps: {
                        className: classes.menu,
                    },
                }}
                helperText="Select a Role"
                margin="normal"
            >
                {roleArray.filter(r => rolePrettyName.hasOwnProperty(r.name)).map(role => (
                    <MenuItem key={role.value} value={role.value}>
                        {rolePrettyName[role.name]}
                    </MenuItem>
                ))}
            </TextField>
            {cr.role && cr.role.value === ROLES.STAFF && <TextField
                id="select-staff-member"
                disabled={!cr.editable}
                select
                error={!cr.staffId}
                required
                label="Select Staff Member"
                className={classes.textField}
                value={cr.staffId ? cr.staffId : ''}
                onChange={handleChange(index, 'staff')}
                SelectProps={{
                    MenuProps: {
                        className: classes.menu,
                    },
                }}
                helperText="Select a Staff Member"
                margin="normal"
            >
                {staff[cr.clinic.site_id].map(staff => (
                    <MenuItem key={staff.staff_id} value={staff.staff_id}>
                        {staff.first_name + ' ' + staff.last_name}
                    </MenuItem>
                ))}
            </TextField>}
        </form>;
        return <div key={index} className={classes.rowContainer}>
            {el}
            <Fab size={'small'} color="primary" aria-label="add" className={classes.fab}
                 onClick={() => removeClinicRole(index)}>
                <Tooltip title={'Remove row'}>
                    <RemoveIcon fontSize={"small"}/>
                </Tooltip>
            </Fab>
        </div>
    };
    clinicArray.forEach((clinic, idx) => {
        clinics[clinic.site_id] = clinic;
        let unused = clinicRoles.find(cr => cr.site_id === clinic.site_id) === undefined;
        clinics[clinic.site_id].unused = unused;
        clinicArray[idx].unused = unused;
    });

    return (
        <LoadingOverlay
            active={!staffLoaded}
            spinner
            text={'Loading permissions...'}
        >
            <Dialog TransitionComponent={Transition}
                    open={open}
                    keepMounted={false}
                    onBackdropClick={() => props.callback()}
                    maxWidth={false}>
                {staffLoaded && <DialogContent>
                    <div className={classes.mainContainer}>
                        <Typography component='h1'>Permissions</Typography>
                        {clinicRoles.map((cr, index) => {
                            return getClinicRole(cr, index);
                        })}
                        {(clinicArray.filter(c => c.unused).length > 0 && clinicRoles.length < clinicArray.length) &&
                        <div className={classes.rowContainer}>
                            <Fab size={'small'} color="primary" aria-label="add" className={classes.fab}
                                 onClick={addClinicRole}>
                                <AddIcon fontSize={"small"}/>
                            </Fab>
                            <Typography component='h5'>Add new row</Typography>
                        </div>}
                        <div className={classes.mainContainer} style={{alignItems: 'center'}}>
                            <div className={classes.rowContainer}
                                 style={{justifyContent: 'space-between', width: '50%'}}>
                                <Button disabled={submitDisabled}
                                        onClick={() => props.callback(clinicRoles)}>Confirm</Button>
                                <Button onClick={() => props.callback()}>Cancel</Button>
                            </div>
                        </div>
                    </div>
                </DialogContent>}
            </Dialog>
        </LoadingOverlay>
    );
}

ClinicRolesDialog.propTypes = {
    userId: PropTypes.number.isRequired,
    clinicRoles: PropTypes.array.isRequired,
    clinicArray: PropTypes.array.isRequired,
    roleArray: PropTypes.array.isRequired,
    callback: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired
};

export default ClinicRolesDialog;
