import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import '../styles.compliance.css'
import {beaconsRecordPropTypes} from "./BeaconsRecord";
import {TextField} from "@material-ui/core";
import {Button} from "semantic-ui-react";
import Autocomplete from "@material-ui/lab/Autocomplete";


function BeaconSelector({beaconRecord, callback, suggestionData, assignmentDisabled}) {

    const [value, setValue] = useState(beaconRecord.assignmentString);
    const {data, display_values, disabled} = suggestionData
    const [updating, setUpdating] = useState(false);
    const escFunction = (event) => {
        if (event.keyCode === 27) {
            setUpdating(false);
        }
    };
    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        // Specify how to clean up after this effect:
        return function cleanup() {
            document.removeEventListener("keydown", escFunction, false);
        };
    });


    const toggleUpdating = () => {
        setUpdating(true);
    };

    useEffect(() => {
        setValue(beaconRecord.assignmentString);
    }, [beaconRecord.assignmentString]);


    const inputChange = (event, value, reason) => {
        if (reason === 'clear') {
            onSuggestionSelected(event, 'UNASSIGNED');
        }
    };
    const onSuggestionSelected = (event, suggestion) => {
        if (event && suggestion) {
            setUpdating(false);
            setValue(suggestion || 'UNASSIGNED');
            const dataItem = data[suggestion ? suggestion : 'UNASSIGNED'];
            dataItem.beacon_id = beaconRecord.rawBeaconData.beacon_id;
            callback(dataItem);
        }
    };

    const id = `beacon_selector_${beaconRecord.key}`;
    return <div>
        {
            updating ?
                <Autocomplete
                    id={`${id}_autosuggest-combobox`}
                    options={display_values}
                    disabled={assignmentDisabled}
                    getOptionDisabled={(option) => disabled.includes(option)}
                    disableClearable={false}
                    clearText={'Set Unassigned'}
                    style={{width: 300}}
                    value={value ? value : 'UNASSIGNED'}
                    onInputChange={inputChange}
                    onChange={onSuggestionSelected}
                    renderInput={params => (
                        <TextField {...params} variant="outlined" fullWidth/>
                    )}
                />
                : <Button size={'small'} primary disabled={assignmentDisabled} onClick={toggleUpdating}>
                    {value}
                </Button>
        }

    </div>
}

export default BeaconSelector;


BeaconSelector.propTypes = {
    unassigned: PropTypes.arrayOf(PropTypes.shape({
        beacon_code: PropTypes.string.isRequired
    })).isRequired,
    id: PropTypes.string.isRequired,
    beaconRecord: PropTypes.shape(beaconsRecordPropTypes),
    callback: PropTypes.func,
};
